import React, { useState } from 'react'
// import {Modal} from 'semantic-ui-react'
import { Modal, Button, Row, Col, Form } from 'react-bootstrap'
import { Formik } from 'formik'
import * as yup from 'yup' // for everything

const schema = yup.object({
  credit: yup.number().required('Required'),
})

const CreditCartModal = ({ show, toggle, creditCart, cart }) => {
  const [roundUp, setRoundUp] = useState(true)

  const calculateRoundUp = (items, credit) => {
    let cartTotal = Number(credit)
    items.forEach((item) => {
      cartTotal += item.quantity * item.price
    })
    const rounded = Math.ceil(cartTotal / 5) * 5
    const diff = rounded - cartTotal
    return { diff, rounded, cartTotal }
  }

  return (
    <Modal show={show} centered onHide={toggle}>
      <Modal.Header closeButton>
        <Modal.Title>Credit Cart</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            credit: cart.credit,
          }}
          // enableReinitialize={true}
          validationSchema={schema}
          onSubmit={async (values, formikBag) => {}}
        >
          {({
            handleSubmit,
            submitCount,
            handleChange,
            handleBlur,
            setFieldValue,
            values,
            touched,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Row className="mt-3">
                <Col xs="11">
                  <Form.Label style={{ fontWeight: '600' }}>Set Credit Amount</Form.Label>
                  <Form.Control
                    type="text"
                    name="credit"
                    value={values.credit}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    isValid={touched.credit && !errors.credit}
                    isInvalid={(submitCount || touched.credit) && !!errors.credit}
                  />
                  <Form.Control.Feedback type="invalid">{errors.credit}</Form.Control.Feedback>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>{`Current Cart Value: ${calculateRoundUp(cart.items, 0).cartTotal}`}</Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  {`Cart Value With Credit: ${
                    roundUp
                      ? new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        }).format(calculateRoundUp(cart.items, values.credit).rounded)
                      : new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        }).format(calculateRoundUp(cart.items, values.credit).cartTotal)
                  } 
                `}
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  {' '}
                  <Form.Check
                    style={{ margin: 0 }}
                    type="checkbox"
                    defaultValue={roundUp}
                    defaultChecked={roundUp}
                    label={`Round total cart value to ${new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(
                      calculateRoundUp(cart.items, values.credit).rounded
                    )} by adding ${new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(calculateRoundUp(cart.items, values.credit).diff.toFixed(2))}
                 `}
                    value={roundUp}
                    onChange={() => setRoundUp(!roundUp)}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs="12">
                  <Button
                    block
                    disabled={errors.credit}
                    onClick={() => {
                      setRoundUp(true)
                      creditCart({
                        cartId: cart._id,
                        credit: roundUp
                          ? calculateRoundUp(cart.items, values.credit).diff + Number(values.credit)
                          : Number(values.credit),
                      })
                    }}
                    variant="success"
                  >
                    Add Credit
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default CreditCartModal
