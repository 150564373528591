import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { IUser } from '../../../types/user'

interface ReenableUserModalProps {
  showReeneableUserModal: boolean
  reenableSelectedUser: (body: { userId: string; userPermission: boolean }) => void
  selectedUser: IUser
  setShowReenableModal: (arg: boolean) => void
  userPermission: boolean
}

const ReenableUserModal = ({
  showReeneableUserModal,
  reenableSelectedUser,
  selectedUser,
  setShowReenableModal,
  userPermission,
}: ReenableUserModalProps) => {
  const handleClick = () => {
    reenableSelectedUser({ userId: selectedUser._id, userPermission })
    setShowReenableModal(false)
  }
  return (
    <Modal show={showReeneableUserModal} centered>
      <Modal.Header>Confirm Re-Enable User</Modal.Header>
      <Modal.Body>Are you sure you want to re-enable this user?</Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setShowReenableModal(false)} variant="danger">
          Close
        </Button>
        <Button onClick={handleClick} variant="success">
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ReenableUserModal
