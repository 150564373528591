export function calculateLogins(logins) {
  let count = 0
  logins.forEach((attempt) => {
    if (attempt.route.includes('login')) {
      count++
    }
  })
  return count
}

export function lastLogin(logins) {
  if (logins && logins.length) {
    logins = logins.filter((login) => {
      if (!login.route.includes('signup')) {
        return login
      }
    })
  }

  let dateAdded
  if (logins.length === 0) {
    return 'N/A'
  }
  for (let i = logins.length - 1; i >= 0; i--) {
    if (logins[i].message.includes('login')) {
      dateAdded = logins[i].dateAdded
      break
    }
  }

  if (dateAdded && dateAdded.length) {
    dateAdded = new Date(dateAdded)
    dateAdded = dateAdded.toLocaleDateString()

    return dateAdded
  } else {
    return 'N/A'
  }
}
