export function validateSerialNumber(serialNumber, converters) {
  serialNumber = serialNumber.toLowerCase()
  let exists
  converters.forEach(converter => {
    const converterSerialNumber = converter.serialNumber.toLowerCase()
    if (converterSerialNumber === serialNumber) {
      exists = true
    }
  })

  if (exists === true) {
    return true
  } else {
    return false
  }
}

export function validateWeight(weight) {
  if (weight.length === 0) {
    return true
  }

  weight = Number(weight)

  if (weight) {
    return true
  } else {
    return false
  }
}

export function validatePPM(weight) {
  if (weight.length === 0) {
    return true
  }
  if (weight.startsWith('0')) {
    return true
  }

  weight = Number(weight)

  if (weight) {
    return true
  } else {
    return false
  }
}
