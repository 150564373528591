import axios from 'axios'
import history from '../history'
import { apiCaller } from '../utils/apiCaller'
import { toast } from 'react-toastify'
import { fetchAllConverters } from './converter'

/**
 * ACTION TYPES
 */
const GET_HEDGE = 'GET_HEDGE'
const GET_ALL_HEDGES = 'GET_ALL_HEDGES'

/**
 * INITIAL STATE
 */

const initialState = {
  data: null,
  allHedges: [],
}

/**
 * ACTION CREATORS
 */
const getHedge = (hedge) => ({
  type: GET_HEDGE,
  hedge,
})

const getAllHedges = (hedges) => ({
  type: GET_ALL_HEDGES,
  hedges,
})

/**
 * THUNK CREATORS
 */
export const fetchHedge = () => async (dispatch) => {
  try {
    let { data } = await apiCaller(`api/hedge`)
    let { hedge, hedges } = data
    dispatch(getHedge(hedge))
    dispatch(getAllHedges(hedges))
  } catch (err) {
    console.error(err)
  }
}

export const fetchAllHedges = () => async (dispatch) => {
  try {
    let { data } = await apiCaller(`api/hedge/all`)
    let { hedges } = data
    dispatch(getAllHedges(hedges))
  } catch (err) {
    console.error(err)
  }
}

export const togglePause = () => async (dispatch) => {
  try {
    let { data } = await apiCaller(`api/hedge/pause`, 'put')
    let { hedges, hedge } = data
    dispatch(getHedge(hedge))
    dispatch(getAllHedges(hedges))
    dispatch(fetchAllConverters({ priceList: false }))
  } catch (err) {
    console.error(err)
  }
}

export const createHedge = (body) => async (dispatch) => {
  try {
    let { data } = await apiCaller(`api/hedge`, 'post', body)
    let { hedge, hedges } = data
    dispatch(getHedge(hedge))
    dispatch(getAllHedges(hedges))
  } catch (err) {
    console.error(err)
  }
}

export const deleteHedge = () => async (dispatch) => {
  try {
    let { data } = await apiCaller(`api/hedge`, 'delete')
    let { hedge, hedges } = data
    dispatch(getHedge(hedge))
    dispatch(getAllHedges(hedges))
  } catch (err) {
    toast.error(`There was an error removing hedge`)
    console.error(err)
  }
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_HEDGE:
      return { ...state, data: action.hedge }
    case GET_ALL_HEDGES:
      return { ...state, allHedges: action.hedges }
    default:
      return state
  }
}
