import React from 'react'
import {Link} from 'react-router-dom'

const Loading = props => {
  return (
    <div className="site-container">
      {props.isAdmin ? (
        <Link
          to="/admin/view/users"
          className="width ui button dashboard-add-converter-btn"
        >
          Back to All Users
        </Link>
      ) : (
        <Link
          to="/dashboard"
          className="width ui button dashboard-add-converter-btn"
        >
          Back to Dashboard
        </Link>
      )}
      <div className="selected-info-container">
        <div className="selected-info-top-row">Loading</div>
        <div className="edit-info-body">
          <div className="edit-info-body-left">
            <div className="ui fluid segment">
              <div className="ui active fluid transition visible inverted dimmer">
                <div className="content">
                  <div className="ui large fluid text loader">Loading</div>
                </div>
              </div>
              <img
                src="https://react.semantic-ui.com/images/wireframe/paragraph.png"
                className="ui image"
              />
            </div>
          </div>
          <div className="selected-info-body-right">
            <div className="ui fluid segment">
              <div className="ui active fluid transition visible inverted dimmer">
                <div className="content">
                  <div className="ui large fluid text loader">Loading</div>
                </div>
              </div>
              <img
                src="https://react.semantic-ui.com/images/wireframe/paragraph.png"
                className="ui image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Loading
