import React from 'react'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import { toast } from 'react-toastify'
import { removeInvoiceThunk } from '../../../store'

class InvoiceViewer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}

    this.notifyLoad = this.notifyLoad.bind(this)
    this.notifyDownload = this.notifyDownload.bind(this)
    //this.generatePriceList = this.generatePriceList.bind(this)
  }

  componentDidMount() {
    this.notifyLoad()
  }

  componentDidUpdate(prevProps) {
    // if (prevProps.pdf.data !== this.props.pdf.data) {
    //   this.notifyDownload()
    // }
  }

  notifyLoad() {
    toast.info('We are preparing your invoice, hang tight!')
  }

  notifyDownload() {
    toast.success('Your invoice has been generated and is ready to be printed or downloaded!')
  }

  render() {
    const { error } = this.props.invoice

    if (error) {
      toast.error(`${error.response.data}, please try again in a moment!`)
      return <Redirect to="/admin/create-invoice/" />
    } else {
      // else if (this.props.pdf.data === undefined) {
      //   return (
      //     <div className="price-list-page-container">
      //       <Link
      //         to={'/admin/create-price-list'}
      //         className="ui fluid button orange margin"
      //         onClick={this.props.removeInvoice}
      //       >
      //         Back
      //       </Link>
      //       <div className="price-list-page ">
      //         <div className="ui fluid segment full">
      //           <div className="ui active transition visible inverted dimmer">
      //             <div className="content">
      //               <div className="ui large text loader">Preparing Files</div>
      //             </div>
      //           </div>
      //           <img
      //             src="https://react.semantic-ui.com/images/wireframe/paragraph.png"
      //             className="ui image"
      //           />
      //         </div>

      //         <div className="ui fluid segment full">
      //           <div className="ui active transition visible inverted dimmer">
      //             <div className="content">
      //               <div className="ui large text loader">Preparing Files</div>
      //             </div>
      //           </div>
      //           <img
      //             src="https://react.semantic-ui.com/images/wireframe/paragraph.png"
      //             className="ui image"
      //           />
      //         </div>
      //         <div className="ui fluid segment full">
      //           <div className="ui active transition visible inverted dimmer">
      //             <div className="content">
      //               <div className="ui large text loader">Preparing Files</div>
      //             </div>
      //           </div>
      //           <img
      //             src="https://react.semantic-ui.com/images/wireframe/paragraph.png"
      //             className="ui image"
      //           />
      //         </div>
      //         <div className="ui fluid segment full">
      //           <div className="ui active transition visible inverted dimmer">
      //             <div className="content">
      //               <div className="ui large text loader">Preparing Files</div>
      //             </div>
      //           </div>
      //           <img
      //             src="https://react.semantic-ui.com/images/wireframe/paragraph.png"
      //             className="ui image"
      //           />
      //         </div>
      //       </div>
      //     </div>
      //   )
      // }
      return (
        <div className="price-list-page-container">
          <Link
            to={'/admin/create-price-list'}
            className="ui fluid button orange margin"
            onClick={this.props.removeInvoice}
          >
            Back
          </Link>
          <div className="price-list-page margin">
            <embed src={'/invoice.pdf'} width="100%" height="99%" type="application/pdf" />
          </div>
        </div>
      )
    }
  }
}

const mapState = (state) => {
  return {
    invoice: state.invoices.invoice,
  }
}

const mapDispatch = (dispatch) => {
  return {
    removeInvoice: () => dispatch(removeInvoiceThunk()),
  }
}

const connected = connect(mapState, mapDispatch)(InvoiceViewer)

export default connected
