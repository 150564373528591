import React, { useState, useEffect } from 'react'
import { Modal, Button, Form, Image } from 'react-bootstrap'
import { isMobile } from '../util/utils'
import { ICart } from '../../types/cart'

interface FloridaFormModalProps {
  cart: ICart
  show: boolean
  onHide: () => void
  onUpload: ({ floridaForm, format }: { floridaForm: string; format: string }) => void
  setFormUpdated: (updated: boolean) => void
}

const FloridaFormModal: React.FC<FloridaFormModalProps> = ({
  cart,
  show,
  onHide,
  onUpload,
  setFormUpdated,
}) => {
  const [floridaForm, setFloridaForm] = useState<string | null>(null)
  const [previewUrl, setPreviewUrl] = useState<string | null>(null)
  const [isMobileDevice, setIsMobileDevice] = useState<boolean>(isMobile())
  const [format, setFormat] = useState<string>('')

  useEffect(() => {
    setIsMobileDevice(isMobile())
  }, [])

  useEffect(() => {
    if (cart.floridaForm) {
      setPreviewUrl(cart.floridaForm)
      setFloridaForm(cart.floridaForm)
    }
  }, [])

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null

    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setPreviewUrl(reader.result as string)
        setFloridaForm(reader.result as string)
      }
      reader.readAsDataURL(file)
      setFormUpdated(true)
      setFormat(file.name.split('.').pop() || '') // Get the file extension
    }
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    if (floridaForm) {
      onUpload({ floridaForm, format: 'jpg' })
    }
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Upload Florida-Specific Form</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formFile">
            <Form.Label>Upload a picture</Form.Label>
            <Form.Control
              type="file"
              accept="image/*"
              onChange={handleFormChange}
              capture={isMobileDevice ? undefined : undefined} // On mobile, camera is one option by default
            />
          </Form.Group>

          {isMobileDevice && (
            <Form.Group className="mt-3" controlId="formFile">
              <Form.Label>Take a picture</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                onChange={handleFormChange}
                capture={'environment'} // Use camera on mobile devices
              />
            </Form.Group>
          )}

          {previewUrl && (
            <div className="my-3 text-center">
              <div style={{ fontSize: '12px', marginRight: '16px' }}>Existing form:</div>
              <Image src={previewUrl} alt="PDF preview" thumbnail width={300} />
            </div>
          )}

          <Button style={{ marginTop: '12px' }} variant="primary" type="submit">
            Upload
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default FloridaFormModal
