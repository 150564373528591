import React from 'react'
import {Link} from 'react-router-dom'

const IndividualLog = (props) => {
  let {status, type, method, route, dateAdded, message, serialNumber} = props
  dateAdded = String(new Date(dateAdded))
  dateAdded = dateAdded.split('GMT')
  dateAdded = dateAdded[0]
  let icon
  if (status === '201') {
    icon = 'add circle large icon middle aligned green'
  } else if (status === '200' || status === '202') {
    icon = 'check circle large icon middle aligned green'
  } else if (status !== undefined && type === 'ERROR') {
    icon = 'exclamation circle large icon middle aligned red'
  }
  return (
    <div role="list" className="ui divided relaxed list">
      <div role="listitem" className="item">
        <i aria-hidden="true" className={icon} />
        <div className="content">
          <div className="header">{`${method}: ${route}`}</div>

          <div className="description">{`${message}`}</div>
          <div className="description">{`${type}`}</div>
          <div className="description">{dateAdded}</div>
        </div>
      </div>
    </div>
  )
}

export default IndividualLog
