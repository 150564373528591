import React, { useState } from 'react'
import { Card, Row, Col, Button } from 'react-bootstrap'
import SizesModal from './SizesModal'
import { ICart, ICartRequest } from '../../types/cart'
import { IConverter } from '../../types/converter'
import { Link } from 'react-router-dom'

interface IndividualConverterProps {
  converter: IConverter
  cart: ICart
  isAdmin: boolean
  isBuyer: boolean
  isPartner: boolean
  addToCart: (body: ICartRequest) => void
  reAddKilledConverter: (body: any) => void
  setSearchTerm: (term: string) => void
  setMakeTerm: (term: string) => void
}

const IndividualConverter = ({
  converter,
  cart,
  isAdmin,
  isBuyer,
  isPartner,
  addToCart,
  reAddKilledConverter,
  setSearchTerm,
  setMakeTerm,
}: IndividualConverterProps) => {
  const [showSizesModal, setShowSizesModal] = useState(false)
  const enableCart = isAdmin || isBuyer || isPartner

  const renderPrice = (converter: IConverter) => {
    return <div className="mt-4">${Number(converter.currentPrice).toFixed(2)}</div>
  }

  const addCustomSizeToCart = (sizeModifier: number) => {
    addToCart({ cartId: cart._id, converter, sizeModifier })
    setSearchTerm('')
    setMakeTerm('all converters')
  }

  const alreadyInCart = () => {
    let sum = 0

    if (!cart || !cart.items.length) {
      return sum
    }

    cart.items.forEach((item) => {
      if (item.serialNumber === converter.serialNumber) {
        sum += item.quantity
      }
    })
    return sum
  }

  return (
    <>
      <Col sm={12} md={12} lg={6} className="mt-1 mb-1">
        <Card>
          {cart && alreadyInCart() > 0 && (
            <Card.Header
              style={{
                background: '#0d6efd',
                justifyContent: 'center',
                display: 'flex',
                alignItems: 'center',
                color: 'white',
                fontWeight: 'bolder',
              }}
            >
              {alreadyInCart()} in cart
            </Card.Header>
          )}
          <Card.Body>
            <Row
              style={{
                width: '100%',
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <Col xs={4}>
                <img
                  src={
                    converter.make === 'Common Grade'
                      ? 'https://i.imgur.com/695Ibmm.png'
                      : converter.photo
                  }
                  width="100%"
                  height="80%"
                />
              </Col>
              <Col>
                <div
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  {converter.serialNumber}
                </div>
                <div>{converter.make}</div>
              </Col>
              <Col
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                  fontSize: 24,
                }}
              >
                {cart && enableCart && (
                  <div>
                    <Button
                      variant="outline-warning"
                      className="mt-4 me-2"
                      onClick={() => setShowSizesModal(true)}
                    >
                      Other Sizes
                    </Button>
                    <Button
                      style={{ width: '75px' }}
                      className="mt-4"
                      onClick={() => {
                        addToCart({ cartId: cart._id, converter })
                        setSearchTerm('')
                        setMakeTerm('all converters')
                      }}
                    >
                      +
                    </Button>
                  </div>
                )}

                {/**
                 * DO NOT ALLOW isBuyer to edit pieces or see PPMs!
                 */}
                {!cart && isAdmin && !converter.killed && (
                  <>
                    <Link
                      to={`admin/edit/converter/${converter._id}`}
                      className="ui button"
                      style={{ backgroundColor: '#0d6efd', color: 'white' }}
                    >
                      Edit
                    </Link>
                  </>
                )}

                {!cart && isAdmin && converter.killed && (
                  <>
                    <Button onClick={() => reAddKilledConverter(converter)}>Re-Add</Button>
                  </>
                )}

                {!converter.killed && renderPrice(converter)}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>

      {showSizesModal && (
        <SizesModal
          converter={converter}
          showSizesModal={showSizesModal}
          setShowSizesModal={setShowSizesModal}
          addCustomSizeToCart={addCustomSizeToCart}
        />
      )}
    </>
  )
}

export default IndividualConverter
