import history from '../history'
import { toast } from 'react-toastify'
import { apiCaller } from '../utils/apiCaller'

/**
 * ACTION TYPES
 */
const GET_ALL_CONVERTERS = 'GET_ALL_CONVERTERS'
const GET_SINGLE_CONVERTER = 'GET_SINGLE_CONVERTER'
const REMOVE_SELECTED_CONVERTER = 'REMOVE_SELECTED_CONVERTER'
const ADD_CONVERTER = 'ADD_CONVERTER'
const SET_CONVERTERS_LOADING_TRUE = 'SET_CONVERTERS_LOADING_TRUE'
const SET_CONVERTERS_LOADING_FALSE = 'SET_CONVERTERS_LOADING_FALSE'

const emptyConverter = {
  id: 0,
  serialNumber: '',
  make: '',
  weight: '',
  currentPrice: 0.0,
  photo: '',
  error: '',
  secondarySerialNumber: '',
}

/**
 * INITIAL STATE
 */
const initialState = {
  allConverters: [],
  selectedConverter: emptyConverter,
  convertersLoading: false,
}

/**
 * ACTION CREATORS
 */

const setConvertersLoadingTrue = () => ({ type: SET_CONVERTERS_LOADING_TRUE })
const setConvertersLoadingFalse = () => ({ type: SET_CONVERTERS_LOADING_FALSE })

const getAllConverters = (allConverters) => ({
  type: GET_ALL_CONVERTERS,
  allConverters,
})

const getSingleConverter = (selectedConverter) => ({
  type: GET_SINGLE_CONVERTER,
  selectedConverter,
})

const updateConverter = (selectedConverter) => ({
  type: GET_SINGLE_CONVERTER,
  selectedConverter,
})

export const removeSingleConverter = (selectedConverter) => ({
  type: REMOVE_SELECTED_CONVERTER,
  selectedConverter,
})

export const addConverter = (converter) => ({
  type: ADD_CONVERTER,
  converter,
})

/**
 * THUNK CREATORS
 */
export const fetchAllConverters = (data) => async (dispatch) => {
  dispatch(setConvertersLoadingTrue())
  try {
    let priceListRequest
    if (data.priceList) {
      priceListRequest = true
    } else {
      priceListRequest = false
    }
    let res = await apiCaller(`api/converters/?priceList=${priceListRequest}`)
    const { converters } = res.data
    dispatch(getAllConverters(converters))
  } catch (err) {
    console.error(err)
  }
  dispatch(setConvertersLoadingFalse())
}

export const fetchSingleConverter = (id) => async (dispatch) => {
  try {
    let res = await apiCaller(`api/converters/${id}`)
    const { converter } = res.data

    dispatch(getSingleConverter(converter))
  } catch (err) {
    return dispatch(getSingleConverter({ error: err }))
  }
}

export const putConverter = (body) => async (dispatch) => {
  try {
    const res = await apiCaller(`api/converters/${body.id}`, 'put', body)
    const { converter } = res.data
    dispatch(updateConverter(converter))
  } catch (error) {
    dispatch(updateConverter({ error }))
  }
}

export const deleteConverter = (id) => async (dispatch) => {
  try {
    const res = await apiCaller(`api/converters/${id}`, 'delete')
    history.push('/')
    toast.success(`Success: Converter deleted`)
    dispatch(removeSingleConverter(emptyConverter))
  } catch (error) {
    dispatch(updateConverter({ error }))
  }
}

export const postConverter = (body) => async (dispatch) => {
  try {
    let res = await apiCaller(`api/converters/`, 'post', body)
    const { converter } = res.data

    /**
     * This is a result of Postgres ID and Mongo ID being stored differently
     */
    let id
    if (converter._id) {
      id = converter._id
    } else {
      id = converter.id
    }
    dispatch(addConverter(converter))
    history.push(`/converter/${id}`)
  } catch (err) {
    toast.error('Error creating converter')
  }
}

/**
 * REDUCER
 */
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_CONVERTERS:
      return { ...state, allConverters: action.allConverters }
    case GET_SINGLE_CONVERTER:
      return { ...state, selectedConverter: action.selectedConverter }
    case REMOVE_SELECTED_CONVERTER:
      return { ...state, selectedConverter: action.selectedConverter }
    case ADD_CONVERTER:
      return {
        ...state,
        allConverters: [...state.allConverters, action.converter],
      }
    case SET_CONVERTERS_LOADING_TRUE:
      return { ...state, convertersLoading: true }
    case SET_CONVERTERS_LOADING_FALSE:
      return { ...state, convertersLoading: false }
    default:
      return state
  }
}
