import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { RootState, AppDispatch, fetchAllBusinessesThunk } from '../../../store'
import { Link } from 'react-router-dom'
import IndividualBusiness from './IndividualBusiness'
import { Row, Col, Container, Card } from 'react-bootstrap'
import { IBusiness } from 'client/types/business'

interface AllBusinessesPageProps {
  fetchAllBusinesses: () => void
  allBusinesses: IBusiness[]
}

const AllBusinessesPage = ({ fetchAllBusinesses, allBusinesses }: AllBusinessesPageProps) => {
  const [businesses, setBusinesses] = useState<IBusiness[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    if (!allBusinesses.length) {
      fetchAllBusinesses()
    }

    setBusinesses(allBusinesses)
    setLoading(false)
  }, [allBusinesses])

  const filterBusinesses = (value: string) => {
    const filtered = allBusinesses.filter((business) => {
      return business.name.includes(value)
    })

    setBusinesses(filtered)
  }

  const handleChange = (evt: React.ChangeEvent) => {
    evt.preventDefault()
    const { value } = evt.target as HTMLInputElement

    filterBusinesses(value.toLowerCase())
  }

  if (loading) {
    return (
      <div className="site-container">
        {[1, 2, 3].map((index) => (
          <div key={index} className="ui fluid site-container segment">
            <div className="ui active fluid transition visible inverted dimmer">
              <div className="content">
                <div className="ui large fluid text loader">Loading</div>
              </div>
            </div>
            <img
              src="https://react.semantic-ui.com/images/wireframe/paragraph.png"
              className="ui image"
              alt={`Placeholder ${index}`}
            />
          </div>
        ))}
      </div>
    )
  }

  return (
    <Container fluid>
      <div className="converter-search-container">
        <div className="ui action input converter-search-input">
          <input type="text" placeholder="Search businesses by name..." onChange={handleChange} />
          <button className="ui disabled button">Search</button>
        </div>
      </div>
      <Link to="/admin/add/business" className="ui button dashboard-add-converter-btn orange">
        Add Business
      </Link>

      <Row className="mt-3">
        <Col>
          <Card>
            <Card.Header>
              <h3>Businesses</h3>
            </Card.Header>
            <Row>
              {!loading && businesses.length === 0 ? (
                <Card.Body>No businesses found. Please add a business.</Card.Body>
              ) : (
                businesses.map((business) => (
                  <IndividualBusiness key={business._id} business={business} />
                ))
              )}
            </Row>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

const mapState = (state: RootState) => {
  return {
    allBusinesses: state.business.allBusinesses,
  }
}

const mapDispatch = (dispatch: AppDispatch) => {
  return {
    fetchAllBusinesses: () => dispatch(fetchAllBusinessesThunk()),
  }
}

export default connect(mapState, mapDispatch)(AllBusinessesPage)
