import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { connect, ConnectedProps } from 'react-redux'
import {
  RootState,
  AppDispatch,
  updateBusinessThunk,
  fetchSelectedBusinessThunk,
  fetchAllUsersThunk,
  useAppDispatch,
} from '../../../store'
import { Container, Row, Col, Form, Card, Button, FloatingLabel } from 'react-bootstrap'
import { startCase } from 'lodash'
import ScaleLoader from 'react-spinners/ScaleLoader'
import { editBusinessSchema, states, EditBusinessBodyProps } from './AddBusiness.util'
import AddUsersToBusiness from './AddUsersToBusiness'
import { IBusiness } from 'client/types/business'
import { Link, useParams } from 'react-router-dom'
import { IUser } from 'client/types/user'

export type userToAdd = {
  readonly value: string
  readonly label: string
}

interface EditBusinessProps {
  selectedBusiness: IBusiness
  fetchSelectedBusiness: (id: string) => void
  fetchAllUsers: () => void
  allUsers: IUser[]
  newBusinessLoading: boolean
}

const EditBusiness: React.FC<PropsFromRedux> = ({
  fetchSelectedBusiness,
  selectedBusiness,
  fetchAllUsers,
  allUsers,
  newBusinessLoading,
}: EditBusinessProps) => {
  const dispatch: any = useAppDispatch()

  const { id: selectedBusinessId } = useParams<{ id: string }>()
  const [loading, setLoading] = useState(true)
  const [businessUsersLoading, setBusinessUsersLoading] = useState(true)

  const [usersToAdd, setUsersToAdd] = useState<userToAdd[]>([])
  const [existingUsersOfBusiness, setExistingUsersOfBusiness] = useState<readonly userToAdd[]>([])
  const [selectedOption, setSelectedOption] = useState<readonly userToAdd[]>([])

  useEffect(() => {
    setLoading(true)
    if (selectedBusinessId) {
      fetchSelectedBusiness(selectedBusinessId)
      fetchAllUsers()
    }
    setLoading(false)
  }, [])

  const handleExistingUsers = (business: IBusiness) => {
    setExistingUsersOfBusiness(
      business.users.map((user) => {
        const label = `${user.firstName} ${user.lastName} - ${business.name}`

        return {
          value: user._id,
          label,
        }
      })
    )
    setBusinessUsersLoading(false)
  }

  useEffect(() => {
    setBusinessUsersLoading(true)
    if (selectedBusiness?.users?.length) {
      handleExistingUsers(selectedBusiness)
    } else if (selectedBusiness._id) {
      setExistingUsersOfBusiness([])
      setBusinessUsersLoading(false)
    }
  }, [selectedBusiness])

  useEffect(() => {
    setLoading(true)
    if (!usersToAdd.length) {
      setUsersToAdd(
        allUsers
          .map((user) => {
            const label = user.business
              ? `${user.firstName} ${user.lastName} - ${user.business.name}`
              : `${user.firstName} ${user.lastName}`
            return {
              value: user._id,
              label,
            }
          })
          .sort((a, b) => a.label.localeCompare(b.label))
      )
    }
    setLoading(false)
  }, [usersToAdd, allUsers])

  const formik = useFormik({
    initialValues: {
      name: selectedBusiness.name || '',
      streetAddress: selectedBusiness.streetAddress || '',
      streetAddress2: selectedBusiness.streetAddress2 || '',
      city: selectedBusiness.city || '',
      state: selectedBusiness.state || '',
      postalCode: selectedBusiness.postalCode || '',
      rhodiumOffset: (selectedBusiness.rhodiumOffset * 100).toFixed(0) || '',
      platinumOffset: (selectedBusiness.platinumOffset * 100).toFixed(0) || '',
      palladiumOffset: (selectedBusiness.palladiumOffset * 100).toFixed(0) || '',
      users: selectedBusiness.users || [],
    },
    enableReinitialize: true,
    validationSchema: editBusinessSchema,
    onSubmit: (values) => {
      const {
        name,
        streetAddress,
        streetAddress2,
        city,
        state,
        postalCode,
        rhodiumOffset,
        platinumOffset,
        palladiumOffset,
      } = values

      const finalUsers = selectedOption || existingUsersOfBusiness

      const body = {
        businessId: selectedBusiness._id,
        name,
        streetAddress: startCase(streetAddress.trim()),
        streetAddress2: startCase(streetAddress2.trim()),
        city: startCase(city.trim()),
        state,
        postalCode: postalCode.trim(),
        rhodiumOffset: Number(rhodiumOffset),
        platinumOffset: Number(platinumOffset),
        palladiumOffset: Number(palladiumOffset),
        users: finalUsers.map((user) => user.value),
      }

      dispatch(updateBusinessThunk(body)).then(() => {
        fetchSelectedBusiness(selectedBusinessId)
        fetchAllUsers()
      })
    },
  })

  const disableSubmit =
    Object.keys(formik.errors).length > 0 || Object.keys(formik.touched).length === 0

  const showLoader = loading || businessUsersLoading || newBusinessLoading

  return (
    <Container fluid>
      {showLoader ? (
        <Row className="mt-3">
          <Col className="d-flex justify-content-center">
            <ScaleLoader color="#36D7B7" height={50} width={10} radius={4} margin={4} />
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            <Col>
              <Link to="/admin/view/businesses" className="ui button dashboard-add-converter-btn">
                Back To All Businesses
              </Link>
            </Col>
          </Row>
          <Card className="mt-3">
            <Card.Header>
              <h3>Edit Business</h3>
            </Card.Header>
            <Card.Body>
              <Form noValidate onSubmit={formik.handleSubmit}>
                <Row>
                  <Col xs={12} sm={6}>
                    <Form.Group>
                      <FloatingLabel label="Name" className="mt-3">
                        <Form.Control
                          type="text"
                          name="name"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.name}
                          placeholder="Name"
                          isValid={formik.touched.name && !formik.errors.name}
                        />
                      </FloatingLabel>
                      {formik.touched.name && (
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.name}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group>
                      <FloatingLabel label="Platinum Offset %" className="mt-3">
                        <Form.Control
                          type="number"
                          name="platinumOffset"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.platinumOffset}
                          placeholder="platinumOffset"
                          isValid={formik.touched.platinumOffset && !formik.errors.platinumOffset}
                        />
                      </FloatingLabel>
                      <Form.Text id="platinumOffsetHelpBlock" muted>
                        Must be a number between 1 and 100
                      </Form.Text>
                      {formik.touched.platinumOffset && (
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.platinumOffset}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                    <Form.Group>
                      <FloatingLabel label="Palladium Offset %" className="mt-3">
                        <Form.Control
                          type="number"
                          name="palladiumOffset"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.palladiumOffset}
                          placeholder="palladiumOffset"
                          isValid={formik.touched.palladiumOffset && !formik.errors.palladiumOffset}
                        />
                      </FloatingLabel>
                      <Form.Text id="palladiumOffsetHelpBlock" muted>
                        Must be a number between 1 and 100
                      </Form.Text>
                      {formik.touched.palladiumOffset && (
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.palladiumOffset}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                    <Form.Group>
                      <FloatingLabel label="Rhodium Offset %" className="mt-3">
                        <Form.Control
                          type="number"
                          name="rhodiumOffset"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.rhodiumOffset}
                          placeholder="rhodiumOffset"
                          isValid={formik.touched.rhodiumOffset && !formik.errors.rhodiumOffset}
                        />
                      </FloatingLabel>
                      <Form.Text id="rhodiumOffsetHelpBlock" muted>
                        Must be a number between 1 and 100
                      </Form.Text>
                      {formik.touched.rhodiumOffset && (
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.rhodiumOffset}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                    <AddUsersToBusiness
                      usersToAdd={usersToAdd}
                      setSelectedOption={setSelectedOption}
                      existingUsersOfBusiness={existingUsersOfBusiness}
                      setFieldTouched={formik.setFieldTouched}
                    />
                  </Col>
                  <Col xs={12} sm={6}>
                    <Form.Group>
                      <FloatingLabel label="Address Line 1" className="mt-3">
                        <Form.Control
                          id="streetAddress"
                          name="streetAddress"
                          type="text"
                          placeholder="streetAddress"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.streetAddress}
                          isValid={formik.touched.streetAddress && !formik.errors.streetAddress}
                        />
                      </FloatingLabel>
                      {formik.touched.streetAddress && (
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.streetAddress}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group>
                      <FloatingLabel label="Address Line 2" className="mt-3">
                        <Form.Control
                          id="streetAddress2"
                          name="streetAddress2"
                          placeholder="streetAddress2"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.streetAddress2}
                          isValid={formik.touched.streetAddress2 && !formik.errors.streetAddress2}
                        />
                      </FloatingLabel>
                      {formik.touched.streetAddress2 && (
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.streetAddress2}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group>
                      <FloatingLabel label="City" className="mt-3">
                        <Form.Control
                          id="city"
                          name="city"
                          type="text"
                          placeholder="City"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.city}
                          isValid={formik.touched.city && !formik.errors.city}
                        />
                      </FloatingLabel>
                      {formik.touched.city && (
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.city}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group>
                      <FloatingLabel label="State" className="mt-3">
                        <Form.Control
                          id="state"
                          name="state"
                          as="select"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.state}
                          isValid={formik.touched.state && !formik.errors.state}
                        >
                          {Object.entries(states).map(([key, value]) => {
                            return <option key={key}>{value}</option>
                          })}
                        </Form.Control>
                      </FloatingLabel>

                      {formik.touched.state && (
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.state}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group>
                      <FloatingLabel label="Postal Code" className="mt-3">
                        <Form.Control
                          id="postalCode"
                          name="postalCode"
                          type="text"
                          placeholder="Postal Code"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.postalCode}
                          isValid={formik.touched.postalCode && !formik.errors.postalCode}
                        />
                      </FloatingLabel>

                      {formik.touched.postalCode && (
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.postalCode}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
              <Row className="mt-5 d-flex justify-content-around">
                <Col xs={6} sm={4}>
                  <Button
                    disabled={disableSubmit}
                    onClick={() => formik.submitForm()}
                    style={{ width: `100%` }}
                  >
                    Update Business
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </>
      )}
    </Container>
  )
}

const mapStateToProps = (state: RootState) => ({
  selectedBusiness: state.business.selectedBusiness,
  allUsers: state.admin.allUsers,
  newBusinessLoading: state.business.newBusinessLoading,
})

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    fetchSelectedBusiness: (id: string) => dispatch(fetchSelectedBusinessThunk(id)),
    fetchAllUsers: () => dispatch(fetchAllUsersThunk()),
  }
}

// Merge the Redux props
const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(EditBusiness)
