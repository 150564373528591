import React, { useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import ScaleLoader from 'react-spinners/ScaleLoader'

import { connect, useDispatch } from 'react-redux'
import { withRouter, Route, Switch, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Login, UserHome } from './components'
import SelectedConverter from './components/authorized-user/SelectedConverter'
import { me } from './store'
import PriceList from './components/admin-dashboard/PriceList'
import PdfViewer from './components/admin-dashboard/pdf-viewer'
import Cart from './components/Cart/Cart'
import { InvoiceViewer } from './components/admin-dashboard/invoice'
import {
  AllUsersPage,
  EditUser,
  AddUser,
  EditUser3,
  AddUser3,
} from './components/admin-dashboard/users'
import { UpdateConverter, AddConverter } from './components/admin-dashboard/converters/'
import Checkout from './components/Checkout/Checkout'
import Invoices from './components/admin-dashboard/Invoices'
import SelectedInvoice from './components/SelectedInvoice'
import SelectedUser from './components/SelectedUser'
import SelectedUserInvoices from './components/SelectedUserInvoices'
import Hedging from './components/Hedging'
import AdminControls from './components/AdminControls'
import MetalsData from './components/admin-dashboard/MetalsData'
import InvoicesData from './components/admin-dashboard/InvoicesData'
import BuyerProfitabilityData from './components/admin-dashboard/BuyerProfitabilityData'
import KilledConverters from './components/KilledConverters'
import {
  AddBusiness,
  AllBusinessesPage,
  EditBusiness,
} from './components/admin-dashboard/businesses'
import { toast } from 'react-toastify'

toast.configure({
  autoClose: 2000,
  draggable: false,
  //same as ToastContainer props
})

const Routes = ({ isLoggedIn, isAdmin, isBuyer, isPartner, canSeeInvoiceData }) => {
  const [loading, setLoading] = React.useState(true)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(me()).then(() => setLoading(false))
  }, [])

  return (
    <Switch>
      {/* Routes placed here are available to all visitors */}
      {!isLoggedIn && !loading && <Route component={Login} />}

      {isLoggedIn && isAdmin && (
        <Switch>
          {/* Routes placed here are only available after logging in */}
          <Route path="/dashboard" component={UserHome} />
          <Route exact path="/admin/edit/converter/:id" component={UpdateConverter} />
          <Route path="/converter/:id" component={SelectedConverter} />
          <Route exact path="/admin/create-price-list" component={PriceList} />
          {/* <Route exact path="/admin/view/analytics" component={AnalyticsDashboard} /> */}
          <Route exact path="/admin/add/converter" component={AddConverter} />
          <Route exact path="/admin/add/user" component={AddUser} />
          <Route exact path="/admin/view/users" component={AllUsersPage} />
          <Route exact path="/admin/add/business" component={AddBusiness} />
          <Route exact path="/admin/view/businesses" component={AllBusinessesPage} />
          <Route exact path="/admin/activity/:id" component={SelectedUser} />
          <Route exact path="/admin/edit/user/:id" component={EditUser} />
          <Route exact path="/admin/edit/business/:id" component={EditBusiness} />
          <Route exact path="/account/:id" component={EditUser} />
          <Route path="/admin/generated-price-list" component={PdfViewer} />
          <Route path="/admin/generated-invoice" component={InvoiceViewer} />
          <Route path="/admin/cart" component={Cart} />
          <Route path="/admin/checkout/:cartId" component={Checkout} />
          <Route path="/admin/invoices/:id" component={SelectedInvoice} />
          <Route path="/invoices/:id" component={SelectedInvoice} />
          <Route path="/invoices" component={Invoices} />
          <Route path="/hedging" component={Hedging} />
          <Route path="/admin/controls" component={AdminControls} />
          <Route path="/admin/view/metals-data" component={MetalsData} />
          <Route path="/admin/killed-converters" component={KilledConverters} />

          <Route path="/admin/view/invoices" component={InvoicesData} />
          <Route path="/admin/view/profitability" component={BuyerProfitabilityData} />

          <Redirect to="/dashboard" />
        </Switch>
      )}

      {isLoggedIn && isPartner && (
        <Switch>
          <Route path="/dashboard" component={UserHome} />
          <Route path="/admin/cart" component={Cart} />
          <Route path="/admin/checkout/:cartId" component={Checkout} />
          <Route path="/invoices/:id" component={SelectedInvoice} />
          <Route path="/admin/invoices/:id" component={SelectedInvoice} />
          <Route path="/invoices" component={SelectedUserInvoices} />
          <Route exact path="/admin/add/user" component={AddUser} />
          <Route exact path="/admin/view/users" component={AllUsersPage} />
          <Route exact path="/admin/activity/:id" component={SelectedUser} />
          <Route exact path="/admin/edit/user/:id" component={EditUser} />
          <Route exact path="/account/:id" component={EditUser} />
          <Route path="/hedging" component={Hedging} />

          <Route exact path="/admin/create-price-list" component={PriceList} />
          {canSeeInvoiceData && (
            <Switch>
              <Route path="/admin/view/invoices" component={InvoicesData} />
              <Route path="/admin/view/profitability" component={BuyerProfitabilityData} />
            </Switch>
          )}
          <Redirect to="/dashboard" />
        </Switch>
      )}

      {isLoggedIn && isBuyer && (
        <Switch>
          <Route path="/dashboard" component={UserHome} />
          <Route path="/admin/cart" component={Cart} />
          <Route path="/admin/checkout/:cartId" component={Checkout} />
          <Route path="/invoices/:id" component={SelectedInvoice} />
          <Route path="/admin/invoices/:id" component={SelectedInvoice} />
          <Route path="/invoices" component={SelectedUserInvoices} />
          <Route exact path="/admin/add/user" component={AddUser} />
          <Route exact path="/admin/view/users" component={AllUsersPage} />
          <Route exact path="/admin/activity/:id" component={SelectedUser} />
          <Route exact path="/admin/edit/user/:id" component={EditUser} />
          <Route exact path="/account/:id" component={EditUser} />
          <Route path="/hedging" component={Hedging} />

          <Route exact path="/admin/create-price-list" component={PriceList} />
          {canSeeInvoiceData && (
            <Switch>
              <Route path="/admin/view/invoices" component={InvoicesData} />
              <Route path="/admin/view/profitability" component={BuyerProfitabilityData} />
            </Switch>
          )}
          <Redirect to="/dashboard" />
        </Switch>
      )}
      {isLoggedIn && (
        <Switch>
          {/* Routes placed here are only available after logging in */}
          <Route path="/dashboard" component={UserHome} />
          <Route path="/converter/:serialNumber" component={SelectedConverter} />
          <Route path="/account/:id" component={EditUser} />
          <Route path="/invoices/:id" component={SelectedInvoice} />
          <Route path="/invoices" component={SelectedUserInvoices} />
          <Route path="/hedging" component={Hedging} />

          <Redirect to="/dashboard" />
        </Switch>
      )}
      <Route
        path="/"
        component={() => (
          <Row className="mt-3">
            <Col className="d-flex justify-content-center">
              <ScaleLoader color="#36D7B7" height={50} width={10} radius={4} margin={4} />
            </Col>
          </Row>
        )}
      />
    </Switch>
  )
}

/**
 * CONTAINER
 */
const mapState = (state) => {
  return {
    // Being 'logged in' for our purposes will be defined has having a state.user that has a truthy id.
    // Otherwise, state.user will be an empty object, and state.user.id will be falsey
    isLoggedIn: !!state.user._id || !!state.user.id,
    isAdmin: state.user.isAdmin,
    isBuyer: state.user.isBuyer,
    isPartner: state.user.isPartner,
    canSeeInvoiceData: state.user.canSeeInvoiceData,
    user: state.user,
  }
}

const mapDispatch = (dispatch) => {
  return {
    loadInitialData() {
      dispatch(fetchPrices())
    },
  }
}

// The `withRouter` wrapper makes sure that updates are not blocked
// when the url changes
export default withRouter(connect(mapState, mapDispatch)(Routes))

/**
 * PROP TYPES
 */
Routes.propTypes = {
  loadInitialData: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
}
