import React, { useState } from 'react'
import { useFormik, FormikProps } from 'formik'
import { connect, ConnectedProps } from 'react-redux'
import { RootState, AppDispatch, postSignUp } from '../../../store'
import { Container, Row, Col, Form, Card, Button, FloatingLabel } from 'react-bootstrap'
import { startCase } from 'lodash'
import { locations, permissions, buyerRoles, IUser } from '../../../types/user'
import {
  newUserSchema,
  states,
  AddUserBodyProps,
  formikLicenseErrors,
  handleFileUpload,
} from './util'
import ScaleLoader from 'react-spinners/ScaleLoader'
import sanitizeHtml from 'sanitize-html'
import AddLicenseDetailsModal from './AddLicenseDetailsModal'

interface AddUserProps {
  newUserLoading: boolean
  isAdmin: boolean
  isBuyer: boolean
  isPartner: boolean
  addUser: (body: AddUserBodyProps, resetForm: () => void) => void
}

const AddUser: React.FC<PropsFromRedux> = ({
  newUserLoading,
  isAdmin,
  isBuyer,
  isPartner,
  addUser,
}: AddUserProps) => {
  const [showLicenseDetailsModal, setShowLicenseDetailsModal] = useState<boolean>(false)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      margin: '',
      location: '',
      permissionLevel: isBuyer ? 'guest' : '', //hardcode guest for buyer accounts since only admins can set permission level
      streetAddress: '',
      streetAddress2: '',
      city: '',
      state: '',
      postalCode: '',
      fingerprint: '',
      licensePhoto: '',
      licenseNumber: '',
      licenseIssueDate: '',
      licenseExpirationDate: '',
      licenseState: '',
      gender: '',
      dob: '',
      heightFeet: '',
      heightInches: '',
      eyeColor: '',
    },
    validationSchema: newUserSchema,
    onSubmit: (values, { resetForm }) => {
      const {
        firstName,
        lastName,
        email,
        password,
        margin,
        location,
        permissionLevel,
        streetAddress,
        streetAddress2,
        city,
        state,
        postalCode,
        licensePhoto,
        fingerprint,
        licenseNumber,
        licenseIssueDate,
        licenseExpirationDate,
        licenseState,
        gender,
        dob,
        heightFeet,
        heightInches,
        eyeColor,
      } = values

      const body = {
        firstName: startCase(firstName),
        lastName: startCase(lastName),
        email: sanitizeHtml(email).toLowerCase(),
        password,
        margin: Number(margin),
        location,
        isBuyer: permissionLevel === 'buyer',
        isAdmin: permissionLevel === 'admin',
        isGuest: permissionLevel === 'guest',
        isCustomer: permissionLevel === 'customer',
        isPartner: permissionLevel === 'partner',
        streetAddress: startCase(streetAddress.trim()),
        streetAddress2: startCase(streetAddress2.trim()),
        city: startCase(city.trim()),
        state,
        postalCode: postalCode.trim(),
        licensePhoto,
        fingerprint,
        licenseNumber,
        licenseIssueDate,
        licenseExpirationDate,
        licenseState,
        gender,
        dob,
        height: `${heightFeet}'-${heightInches}"`,
        eyeColor,
      }

      const handleResetForm = () => {
        resetForm()
      }
      addUser(body, handleResetForm)
    },
  })

  const renderFormGroup = (label: string, name: string, type = 'text', formik, options = {}) => (
    <Form.Group>
      <FloatingLabel label={label} className="mt-3">
        <Form.Control
          type={type}
          name={name}
          as={options.as || 'input'}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values[name]}
          placeholder={label}
          isValid={formik.touched[name] && !formik.errors[name]}
          {...options}
        >
          {options.as === 'select' &&
            options.items?.map((item, idx) => (
              <option key={item.value || idx} value={item.value}>
                {item.text || item}
              </option>
            ))}
        </Form.Control>
        {formik.touched[name] && (
          <Form.Control.Feedback type="invalid">{formik.errors[name]}</Form.Control.Feedback>
        )}
      </FloatingLabel>
    </Form.Group>
  )

  return (
    <Container fluid>
      <Card className="mt-3">
        <Card.Header>
          <h3>Create User</h3>
        </Card.Header>
        <Card.Body>
          {newUserLoading ? (
            <Row className="mt-3">
              <Col className="d-flex justify-content-center">
                <ScaleLoader color="#36D7B7" height={50} width={10} radius={4} margin={4} />
              </Col>
            </Row>
          ) : (
            <>
              <Form noValidate onSubmit={formik.handleSubmit}>
                <Row>
                  <Col xs={12}>
                    <Form.Group
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                      <div className="edit-img-container">
                        <img
                          className="edit-img"
                          src={
                            formik.values.licensePhoto
                              ? String(formik.values.licensePhoto)
                              : '/placeholder.png'
                          }
                        />
                      </div>

                      <input
                        type="file"
                        name="licensePhoto"
                        className="inputfile"
                        id="embedpollfileinput"
                        accept="image/png, image/jpeg"
                        onChange={(e) => handleFileUpload(formik.setFieldValue, e, 'licensePhoto')}
                      />
                      <div style={{ height: '40px' }}>
                        <label htmlFor="embedpollfileinput" className="ui blue button">
                          <i className="ui upload icon" />
                          Upload license
                        </label>
                      </div>
                    </Form.Group>
                  </Col>
                  {formik.touched.licensePhoto && (
                    <Form.Control.Feedback
                      type="invalid"
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      {formik.errors.licensePhoto}
                    </Form.Control.Feedback>
                  )}
                  <Col xs={12} sm={6}>
                    <Form.Group>
                      <FloatingLabel label="First name" className="mt-3">
                        <Form.Control
                          type="text"
                          name="firstName"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.firstName}
                          placeholder="First name"
                          isValid={formik.touched.firstName && !formik.errors.firstName}
                        />
                      </FloatingLabel>

                      {formik.touched.firstName && (
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.firstName}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group>
                      <FloatingLabel label="Last name" className="mt-3">
                        <Form.Control
                          type="text"
                          name="lastName"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.lastName}
                          placeholder="Last name"
                          isValid={formik.touched.lastName && !formik.errors.lastName}
                        />
                      </FloatingLabel>
                      {formik.touched.lastName && (
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.lastName}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group>
                      <FloatingLabel label="Email" className="mt-3">
                        <Form.Control
                          type="text"
                          name="email"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.email}
                          placeholder="Email"
                          isValid={formik.touched.email && !formik.errors.email}
                        />
                      </FloatingLabel>
                      {formik.touched.email && (
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.email}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group>
                      <FloatingLabel label="Password" className="mt-3">
                        <Form.Control
                          type="password"
                          name="password"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.password}
                          placeholder="password"
                          isValid={formik.touched.password && !formik.errors.password}
                        />
                      </FloatingLabel>
                      <Form.Text id="passwordHelpBlock" muted>
                        Must be 6+ characters & contain 1 upper case, 1 lower case, and 1 number
                      </Form.Text>
                      {formik.touched.password && (
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.password}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group>
                      <FloatingLabel label="Margin" className="mt-3">
                        <Form.Control
                          type="number"
                          name="margin"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.margin}
                          placeholder="margin"
                          isValid={formik.touched.margin && !formik.errors.margin}
                        />
                      </FloatingLabel>
                      <Form.Text id="marginHelpBlock" muted>
                        Must be a number at least 1 and under 100
                      </Form.Text>
                      {formik.touched.margin && (
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.margin}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>

                    {isAdmin && (
                      <Form.Group>
                        <FloatingLabel label="Set Permission Level" className="mt-3">
                          <Form.Control
                            id="permissionLevel"
                            name="permissionLevel"
                            as="select"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.permissionLevel}
                            isValid={
                              formik.touched.permissionLevel && !formik.errors.permissionLevel
                            }
                          >
                            <option key="placeholder" value="">
                              -
                            </option>
                            {permissions.map((p, idx) => {
                              return (
                                <option key={p.value} value={p.value}>
                                  {p.text}
                                </option>
                              )
                            })}
                          </Form.Control>
                        </FloatingLabel>

                        {formik.touched.permissionLevel && (
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.permissionLevel}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    )}
                  </Col>
                  <Col xs={12} sm={6}>
                    <Form.Group>
                      <FloatingLabel label="Address Line 1" className="mt-3">
                        <Form.Control
                          id="streetAddress"
                          name="streetAddress"
                          type="text"
                          placeholder="streetAddress"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.streetAddress}
                          isValid={formik.touched.streetAddress && !formik.errors.streetAddress}
                        />
                      </FloatingLabel>
                      {formik.touched.streetAddress && (
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.streetAddress}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group>
                      <FloatingLabel label="Address Line 2" className="mt-3">
                        <Form.Control
                          id="streetAddress2"
                          name="streetAddress2"
                          placeholder="streetAddress2"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.streetAddress2}
                          isValid={formik.touched.streetAddress2 && !formik.errors.streetAddress2}
                        />
                      </FloatingLabel>
                      {formik.touched.streetAddress2 && (
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.streetAddress2}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group>
                      <FloatingLabel label="City" className="mt-3">
                        <Form.Control
                          id="city"
                          name="city"
                          type="text"
                          placeholder="City"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.city}
                          isValid={formik.touched.city && !formik.errors.city}
                        />
                      </FloatingLabel>
                      {formik.touched.city && (
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.city}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group>
                      <FloatingLabel label="State" className="mt-3">
                        <Form.Control
                          id="state"
                          name="state"
                          as="select"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.state}
                          isValid={formik.touched.state && !formik.errors.state}
                        >
                          {/* {Object.entries(states).map(([key, value]) => {
                            return <option key={key}>{value}</option>
                          })} */}

                          <option key="placeholder" value="">
                            -
                          </option>
                          {states.map((p, idx) => {
                            return (
                              <option key={p.value} value={p.value}>
                                {p.text}
                              </option>
                            )
                          })}
                        </Form.Control>
                      </FloatingLabel>

                      {formik.touched.state && (
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.state}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group>
                      <FloatingLabel label="Postal Code" className="mt-3">
                        <Form.Control
                          id="postalCode"
                          name="postalCode"
                          type="text"
                          placeholder="Postal Code"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.postalCode}
                          isValid={formik.touched.postalCode && !formik.errors.postalCode}
                        />
                      </FloatingLabel>

                      {formik.touched.postalCode && (
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.postalCode}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>

                    {(isAdmin || isBuyer || isPartner) && (
                      <Form.Group>
                        <FloatingLabel label="Set Primary Location" className="mt-3">
                          <Form.Control
                            id="location"
                            name="location"
                            as="select"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.location}
                            isValid={formik.touched.location && !formik.errors.location}
                          >
                            <option key="placeholder" value={''}>
                              -
                            </option>
                            {locations.map((location) => {
                              return (
                                <option key={location.value} value={location.value}>
                                  {location.text}
                                </option>
                              )
                            })}
                          </Form.Control>
                        </FloatingLabel>

                        {formik.touched.location && (
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.location}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    )}
                    {formik.values.location === 'florida' &&
                      !buyerRoles.includes(formik.values.permissionLevel) && (
                        <>
                          <Form.Group style={{ display: 'flex', justifyContent: 'center' }}>
                            <Row
                              style={{
                                display: 'flex',
                                justifyContent: 'space-evenly',
                                alignItems: 'center',
                                width: '100%',
                              }}
                            >
                              <Col
                                style={{
                                  marginTop: '12px',
                                  border: '1px solid #ced4da',
                                  borderRadius: '.25rem',
                                  padding: '0.5rem .75rem',
                                  flex: '1', // Ensures both columns are evenly spaced
                                  marginRight: '8px', // Adds spacing between columns
                                }}
                              >
                                <label
                                  style={{ marginBottom: '4px', opacity: '.65', fontSize: '12px' }}
                                  htmlFor="fingerprint"
                                >
                                  Upload Fingerprint:
                                </label>
                                <input
                                  type="file"
                                  id="fingerprint"
                                  name="fingerprint"
                                  onChange={(e) =>
                                    handleFileUpload(formik.setFieldValue, e, 'fingerprint')
                                  }
                                />
                                {formik.touched.fingerprint && (
                                  <Form.Control.Feedback type="invalid">
                                    {formik.errors.fingerprint}
                                  </Form.Control.Feedback>
                                )}
                              </Col>
                            </Row>
                          </Form.Group>
                        </>
                      )}
                  </Col>
                </Row>
              </Form>
              {isSubmitted && formikLicenseErrors(formik) && (
                <Form.Control.Feedback type="invalid">
                  Missing license details. Please add license details.
                </Form.Control.Feedback>
              )}
              <Row className="mt-5 d-flex justify-content-around">
                <Col xs={6} sm={4}>
                  <Button
                    onClick={() => {
                      formik.submitForm()
                      setIsSubmitted(true)
                    }}
                    style={{ width: `100%` }}
                  >
                    Create User
                  </Button>
                </Col>
                <Col xs={6} sm={4}>
                  <Button
                    variant="success"
                    onClick={() => setShowLicenseDetailsModal(true)}
                    style={{ width: `100%` }}
                  >
                    Add License Details
                  </Button>
                </Col>
              </Row>
            </>
          )}

          {showLicenseDetailsModal && (
            <AddLicenseDetailsModal
              show={showLicenseDetailsModal}
              setShowLicenseDetailsModal={setShowLicenseDetailsModal}
              setFieldValue={formik.setFieldValue}
              initialValues={{
                licenseNumber: formik.values.licenseNumber,
                licenseExpirationDate: formik.values.licenseExpirationDate,
                licenseIssueDate: formik.values.licenseIssueDate,
                licenseState: formik.values.licenseState,
                heightFeet: formik.values.heightFeet,
                heightInches: formik.values.heightInches,
                eyeColor: formik.values.eyeColor,
                gender: formik.values.gender,
                dob: formik.values.dob,
              }}
              parentFormik={formik}
            />
          )}
        </Card.Body>
      </Card>
    </Container>
  )
}

const mapStateToProps = (state: RootState) => ({
  isAdmin: state.user.isAdmin,
  isBuyer: state.user.isBuyer,
  isPartner: state.user.isPartner,
  newUserLoading: state.admin.newUserLoading,
})

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    addUser: (body, resetForm) => dispatch(postSignUp(body, resetForm)),
  }
}

// Merge the Redux props
const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(AddUser)
