import React from 'react'
import { Navigation } from './components'
import Routes from './routes'
import PriceFeed from './components/PriceFeed'
import Footer from './components/footer'

const App = () => {
  return (
    <div className="app">
      <Navigation />
      <div className="app-body-right">
        <PriceFeed />
        <Routes />
      </div>
      <Footer />
    </div>
  )
}

export default App
