import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { RootState, useAppDispatch } from '../../store'
import { IConverter } from '../../types/converter'
import { createMakes, updatePrices } from './utils'
import { toast } from 'react-toastify'
import MarginUpdate from './MarginUpdate'
import PriceListPage from './PriceListPage'
import { fetchAllConverters } from '../../store'
import { Row, Col } from 'react-bootstrap'
import ScaleLoader from 'react-spinners/ScaleLoader'

interface PriceListProps {
  allConverters: IConverter[]
}

const PriceList = ({ allConverters }: PriceListProps) => {
  const [makes, setMakes] = useState<object[]>([])
  const [marginError, setMarginError] = useState(false)
  const [margin, setMargin] = useState('')
  const [edits, setEdits] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const dispatch: any = useAppDispatch()

  useEffect(() => {
    dispatch(fetchAllConverters({ priceList: true })).then(() => setLoading(false))
    const makes = createMakes(allConverters)
    setMakes(makes)
  }, [])

  useEffect(() => {
    const makes = createMakes(allConverters)
    setMakes(makes)
  }, [allConverters])

  const handleMarginUpdate = (margin) => {
    setLoading(true)
    const updatedPrices = updatePrices(allConverters, margin)
    const updatedMakes = createMakes(updatedPrices)
    setMarginError(false)
    setMakes(updatedMakes)
    setLoading(false)
  }

  const resetDefault = () => {
    const makes = createMakes(allConverters)
    setMakes(makes)
    setMargin('')
    toast.info('Margin has been reset to default!')
  }

  const handleChange = (evt: React.ChangeEvent) => {
    const { value } = evt.target as HTMLInputElement

    let newVal = value
    if (value.endsWith('%')) {
      newVal = value.slice(0, value.length - 1)
    }

    const parsedVal = parseInt(newVal)
    if (parsedVal >= 100 || parsedVal < 0 || isNaN(parsedVal)) {
      setMarginError(true)
    } else {
      setMarginError(false)
      setMargin(value)
    }
  }

  const handleSubmit = (evt: React.ChangeEvent) => {
    if (marginError) {
      toast.error('Invalid margin entered.')
    }
    handleMarginUpdate(margin)
  }

  const handleEditInput = (evt: React.ChangeEvent) => {
    const _id = parseInt((evt.target as HTMLInputElement).name)

    const { value } = evt.target as HTMLInputElement

    const current = edits
    if (current[_id] === undefined) {
      current[_id] = value
    } else if (value.length === 0) {
      delete current[_id]
    } else {
      current[_id] = value
    }

    setEdits(current)
  }

  let count = 0

  const marginErrorStr = marginError ? 'error' : ''
  const widthError = window.innerWidth < 500

  return (
    <div className="price-list-page-container">
      <MarginUpdate
        resetDefault={resetDefault}
        marginError={marginErrorStr}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        makes={makes}
      />
      {widthError && (
        <button className="ui disabled button red" disabled tabIndex={-1}>
          Not Optimized For Mobile
        </button>
      )}
      {loading ? (
        <Row className="mt-5">
          <Col className="d-flex justify-content-center">
            <ScaleLoader color="#36D7B7" height={50} width={10} radius={4} margin={4} />
          </Col>
        </Row>
      ) : (
        makes.map((make, idx) => {
          const name = Object.keys(make)
          const converterArr = make[name[0]]
          /**
           * Sort common grades by IDX
           * Sort all others alphabetically
           */
          if (idx === 0) {
            console.log('dont sort')
          } else {
            converterArr.sort((a, b) => {
              const serialNumberA = a.serialNumber.toLowerCase()
              const serialNumberB = b.serialNumber.toLowerCase()
              if (serialNumberA < serialNumberB)
                //sort string ascending
                return -1
              if (serialNumberA > serialNumberB) return 1
              return 0 //default return value (no sorting)
            })
          }

          const pageCount = Math.ceil(converterArr.length / 172)
          const slicedConverterArr: IConverter[][] = []
          let slice: IConverter[] = []
          for (let i = 0; i < pageCount; i++) {
            if (i === 0) {
              slice = converterArr.slice(0, 172)
              slicedConverterArr.push(slice)
            } else {
              slice = converterArr.slice(i * 172, (i + 1) * 172)
              slicedConverterArr.push(slice)
            }
          }
          return (
            <div style={{ width: '100%' }} key={`${make}_+${idx}`}>
              {slicedConverterArr.map((converters, page) => {
                count++
                return (
                  <PriceListPage
                    key={count}
                    converters={converters}
                    name={name[0]}
                    page={count}
                    handleEditInput={handleEditInput}
                  />
                )
              })}
            </div>
          )
        })
      )}
    </div>
  )
}

const mapState = (state: RootState) => {
  return {
    isAdmin: state.user.isAdmin,
    allConverters: state.converter.allConverters,
  }
}

export default connect(mapState)(PriceList)
