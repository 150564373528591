import React, { useState, useEffect } from 'react'
import { Button, Row, Col, Form, Container, Alert } from 'react-bootstrap'
import { connect } from 'react-redux'
import {
  fetchCart,
  RootState,
  generateExternalInvoice,
  attachToCartThunk,
  createInvoiceThunk,
  fetchSelectedUserThunk,
  AppDispatch,
  updateUserThunk,
} from '../../store'
import { Link } from 'react-router-dom'
import {
  calculateTotalCost,
  calculateTotalPieces,
  decimalToFraction,
  generateLabels,
} from '../util/utils'
import { ILabel } from '../../types/label'
import { ICart } from '../../types/cart'
import ScaleLoader from 'react-spinners/ScaleLoader'
import PictureModal from './PictureModal'
import SignatureModal from './SignatureModal'
import FloridaFormModal from './FloridaFormModal'
import FingerprintModal from './FingerprintModal'
import { IUser } from 'client/types/user'

const isProd = process.env.NODE_ENV === 'production'

export interface AttachToCartProps {
  cartId: string
  type: string
  item: string
  format: string
}

export interface CreateInvoiceProps {
  signature: string | undefined
  cart: ICart
  labels: ILabel[]
  shouldSendEmail: boolean
  customerLocation: string
}
interface CheckoutProps {
  cart: ICart
  fetchCart: () => void
  invoiceLoading: boolean
  generateExternalInvoice: (data: any) => void
  errorBody: { error: string; message: string }
  isPartner: boolean
  location: string
  attachToCart: ({ cartId, type, item, format }: AttachToCartProps) => void
  createInvoice: ({ signature, cart, labels, customerLocation }: CreateInvoiceProps) => void
  fetchSelectedUser: ({
    userId,
    userPermission,
  }: {
    userId: string
    userPermission: boolean
  }) => void
  selectedUser: IUser
  updateUser: (body: any) => void
}

const Checkout = ({
  cart,
  fetchCart,
  invoiceLoading,
  generateExternalInvoice,
  errorBody,
  isPartner,
  location,
  attachToCart,
  createInvoice,
  selectedUser,
  fetchSelectedUser,
  updateUser,
}: CheckoutProps) => {
  const [showLabels, toggleLabels] = useState(true)
  const [labels, setLabels] = useState<ILabel[]>([])

  const [showSignatureModal, setShowSignatureModal] = useState(false)
  const [showPictureModal, setShowPictureModal] = useState(false)
  const [showFloridaFormModal, setShowFloridaFormModal] = useState(false)
  const [showUserRedirect, setShowUserRedirect] = useState(false)

  const [pictureUpdated, setPictureUpdated] = useState(false)
  const [formUpdated, setFormUpdated] = useState(false)
  const [fingerprintUpdated, setFingerprintUpdated] = useState(false)

  const [fingerprintModal, setShowFingerprintModal] = useState(false)

  useEffect(() => {
    if (!cart) {
      fetchCart()
    }
  }, [cart])

  useEffect(() => {
    if (cart) {
      const labels = generateLabels(cart)
      setLabels(labels)
    }

    if (cart && cart.customer) {
      fetchSelectedUser({ userId: cart.customer._id, userPermission: true })
    }
  }, [cart])

  const launchSignatureModal = () => {
    setShowSignatureModal(true)
    generateExternalInvoice({ labels, cart, isPartner })
  }

  const startModalFlow = () => {
    if (!selectedUser.licenseDetails.licenseNumber) {
      setShowUserRedirect(true)
    } else if (selectedUser.location === 'florida' && !selectedUser.fingerprint && !isProd) {
      setShowFingerprintModal(true)
    } else if (!isProd) {
      setShowPictureModal(true)
    } else {
      launchSignatureModal()
    }
  }

  const handleFingerprintUpload = (fingerprint: string) => {
    if (fingerprintUpdated) {
      setShowFingerprintModal(false)
      updateUser({ userId: selectedUser._id, fingerprint, userPermission: true })
      setShowPictureModal(true)
    }
  }

  const customerLocation = selectedUser.location

  const handlePictureUpload = ({ buyPicture, format }: { buyPicture: string; format: string }) => {
    setShowPictureModal(false)
    if (pictureUpdated) {
      attachToCart({ cartId: cart._id, type: 'buyPicture', item: buyPicture, format })
    }

    // Step 2: If customer is from Florida, show the Florida form modal
    if (customerLocation === 'florida') {
      setShowFloridaFormModal(true)
    } else {
      // Proceed to signature if not from Florida
      launchSignatureModal()
    }
  }

  const handleFloridaFormUpload = ({
    floridaForm,
    format,
  }: {
    floridaForm: string
    format: string
  }) => {
    if (formUpdated) {
      attachToCart({ cartId: cart._id, item: floridaForm, type: 'floridaForm', format })
    }
    setShowFloridaFormModal(false)
    // Step 3: Show Signature Modal
    launchSignatureModal()
  }

  const { error, message } = errorBody

  const renderErrorMessage = () => {
    if (error === 'address_required' && !isPartner) {
      return (
        <Alert key="danger" variant="danger">
          Please add an address to the customer profile{' '}
          <Alert.Link href={`/admin/edit/user/${cart.customer._id}`}>here.</Alert.Link>
        </Alert>
      )
    }

    return (
      <Alert key="danger" variant="danger">
        {message}
      </Alert>
    )
  }

  if (invoiceLoading) {
    return (
      <Row className="mt-3">
        <Col className="d-flex justify-content-center">
          <ScaleLoader color="#36D7B7" height={50} width={10} radius={4} margin={4} />
        </Col>
      </Row>
    )
  }

  return (
    <Container fluid>
      <Row className="mt-3">
        <Col>
          <h3>Checkout Summary</h3>
          {cart && (
            <div>
              <strong>Customer:</strong> {cart.customer.email}
            </div>
          )}
          {cart && (
            <div>
              <strong>Pieces:</strong> {calculateTotalPieces(cart.items)}
            </div>
          )}
          {cart && (
            <div>
              <strong>Total:</strong> {calculateTotalCost(cart.items, cart.credit)}
            </div>
          )}
        </Col>
        <Col style={{ display: 'flex', justifyContent: 'flex-end', maxHeight: 30 }}>
          <Form>
            <Row>
              Show Labels
              <Form.Check
                type="switch"
                id={cart && cart._id}
                defaultChecked={showLabels}
                onChange={() => toggleLabels(!showLabels)}
              />
            </Row>
          </Form>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <strong>Serial #</strong>
        </Col>
        {!showLabels && (
          <Col>
            <strong>Size</strong>
          </Col>
        )}
        <Col>
          <strong>Quantity</strong>
        </Col>
        {!showLabels && (
          <Col>
            <strong>Cost</strong>
          </Col>
        )}
        <Col
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <strong>Total</strong>
        </Col>
      </Row>
      <hr />
      {cart &&
        !showLabels &&
        cart.items.map((item) => {
          return (
            <div key={`${item._id}` + `${item.sizeModifier}`}>
              <Row>
                <Col>{item.serialNumber}</Col>
                <Col>{decimalToFraction(item.sizeModifier)}</Col>
                <Col>{item.quantity}</Col>
                <Col>
                  {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  }).format(item.price)}
                </Col>
                <Col
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  }).format(item.quantity * item.price)}
                </Col>
              </Row>
              <hr />
            </div>
          )
        })}
      {cart && cart.credit > 0 && !showLabels && (
        <>
          <Row>
            <Col>Credit</Col>
            <Col></Col>
            <Col>1</Col>
            <Col>
              {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(cart.credit)}
            </Col>
            <Col
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(cart.credit)}
            </Col>
          </Row>
          <hr />
        </>
      )}

      {cart &&
        showLabels &&
        labels.map((item) => {
          return (
            <div key={`${item.label}` + `${item.quantity}` + `${item.cost}`}>
              <Row>
                <Col>{item.label}</Col>
                <Col>{item.quantity}</Col>
                <Col
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  {' '}
                  {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  }).format(item.cost)}
                </Col>
              </Row>
              <hr />
            </div>
          )
        })}
      <Row className="mt-3">
        <Col
          xs="12"
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <div>{cart && <strong>{calculateTotalCost(cart.items, cart.credit)}</strong>}</div>
        </Col>
      </Row>
      {error && renderErrorMessage()}
      <hr />
      <Row className="mb-3 mt-3">
        <Col xs="6">
          <Link to="/admin/cart">
            <Button>Back</Button>
          </Link>
        </Col>
        <Col xs="6">
          <Button
            onClick={startModalFlow} // Start the conditional modal flow
            style={{ width: '100%' }}
            variant="success"
          >
            Continue
          </Button>
        </Col>
      </Row>

      {/* Step 0: Fingerprint Modal if customer is Florida location and doesn't have a fingerprint on file*/}
      {fingerprintModal && (
        <FingerprintModal
          show={fingerprintModal}
          onHide={() => setShowFingerprintModal(false)}
          setFingerprintUpdated={setFingerprintUpdated}
          onUpload={(fingerprint: string) => handleFingerprintUpload(fingerprint)} // Handle fingerprint upload completion
        />
      )}

      {/* Step 1: Picture Modal */}
      {showPictureModal && (
        <PictureModal
          setPictureUpdated={setPictureUpdated}
          cart={cart}
          show={showPictureModal}
          onHide={() => setShowPictureModal(false)}
          onUpload={({ buyPicture, format }) => handlePictureUpload({ buyPicture, format })} // Handle picture upload completion
        />
      )}

      {/* Step 2: Florida Form Modal (if needed) */}
      {showFloridaFormModal && (
        <FloridaFormModal
          cart={cart}
          setFormUpdated={setFormUpdated}
          show={showFloridaFormModal}
          onHide={() => setShowFloridaFormModal(false)}
          onUpload={({ floridaForm, format }) => handleFloridaFormUpload({ floridaForm, format })} // Handle Florida form upload
        />
      )}

      {/* Step 3: Signature Modal */}
      {showSignatureModal && error !== 'address_required' && (
        <SignatureModal
          show={showSignatureModal}
          toggle={() => setShowSignatureModal(false)}
          cart={cart}
          labels={labels}
          createInvoice={createInvoice}
          customerLocation={customerLocation}
        />
      )}
    </Container>
  )
}

const mapState = (state: RootState) => {
  return {
    cart: state.cart.data,
    isPartner: state.user.isPartner,
    invoiceLoading: state.invoices.invoiceLoading,
    errorBody: state.invoices.error,
    location: state.user.location,
    selectedUser: state.admin.selectedUser,
  }
}

const mapDispatch = (dispatch: AppDispatch) => {
  return {
    fetchCart() {
      dispatch(fetchCart())
    },
    generateExternalInvoice(body) {
      dispatch(generateExternalInvoice(body))
    },
    attachToCart(body) {
      dispatch(attachToCartThunk(body))
    },
    createInvoice(body) {
      dispatch(createInvoiceThunk(body))
    },
    fetchSelectedUser({ userId, userPermission }: { userId: string; userPermission: boolean }) {
      dispatch(fetchSelectedUserThunk({ userId, userPermission }))
    },
    updateUser: (body) => dispatch(updateUserThunk(body)),
  }
}

export default connect(mapState, mapDispatch)(Checkout)
