import { IConverter } from 'client/types/converter'
import React from 'react'
import { Row, Col } from 'react-bootstrap'

interface PriceListPageProps {
  name: string
  page: number
  converters: [] | IConverter[]
  handleEditInput: (evt: React.ChangeEvent) => void
}

const PriceListPage = ({ converters, name, page, handleEditInput }: PriceListPageProps) => {
  const converterArr = converters

  const divideConverterArrIntoFourths = (converterArr: IConverter[]): IConverter[][] => {
    const output: IConverter[][] = []
    const partSize = Math.ceil(converterArr.length / 4)

    for (let i = 0; i < 4; i++) {
      const startIndex = i * partSize
      const endIndex = startIndex + partSize
      const currentPart = converterArr.slice(startIndex, endIndex)
      output.push(currentPart)
    }
    return output
  }

  return (
    <>
      <div className="price-list-page-header">
        <div>Make: {name}</div>
        <img className="header-img no-print" src="https://i.imgur.com/B14SBXg.png" />
        <div>Page: {page}</div>
      </div>
      <div id="page-break">
        <Row className="price-list-page-body">
          {divideConverterArrIntoFourths(converterArr).map((fourth, idx) => {
            return (
              <Col md={3} key={idx}>
                {fourth.map((converter) => {
                  return (
                    <div key={converter._id} className="price-list-page-row">
                      <div className="price-list-page-serial-number">{converter.serialNumber}</div>
                      <div className="price-list-page-price-container">
                        $
                        <input
                          className="price-list-page-price-input"
                          placeholder={`${converter.price}`}
                          onChange={handleEditInput}
                        />
                      </div>
                    </div>
                  )
                })}
              </Col>
            )
          })}
        </Row>
      </div>
    </>
  )
}

export default PriceListPage
