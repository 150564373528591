import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import { connect, ConnectedProps } from 'react-redux'
import {
  RootState,
  AppDispatch,
  updateUserThunk,
  fetchSelectedUserThunk,
  deleteSelectedUserThunk,
  reenableSelectedUserThunk,
  updatePasswordThunk,
} from '../../../store'
import { Container, Row, Col, Form, Card, Button, FloatingLabel } from 'react-bootstrap'
import { startCase } from 'lodash'
import { locations, permissions, buyerRoles } from '../../../types/user'
import {
  editUserSchema,
  states,
  EditUserBodyProps,
  formikLicenseTouched,
  formikLicenseErrors,
  handleFileUpload,
} from './util'
import { toast } from 'react-toastify'
import ScaleLoader from 'react-spinners/ScaleLoader'
import { IUser } from '../../../types/user'
import { Link, useParams } from 'react-router-dom'
import DeleteUserModal from './DeleteUserModal'
import ReenableUserModal from './ReenableUserModal'
import UpdatePasswordModal from './UpdatePasswordModal'
import AddLicenseDetailsModal from './AddLicenseDetailsModal'

interface EditUserProps {
  selectedUser: IUser
  newUserLoading: boolean
  isAdmin: boolean
  isBuyer: boolean
  isPartner: boolean
  isCustomer: boolean
  updateUser: (body: EditUserBodyProps) => void
  fetchSelectedUser: (body: { userId: string; userPermission: boolean }) => void
  deleteSelectedUser: (body: { userId: string; userPermission: boolean }) => void
  reenableSelectedUser: (body: { userId: string; userPermission: boolean }) => void
  updatePassword: (body: { userId: string; newPassword: string }) => void
}

const EditUser: React.FC<PropsFromRedux> = ({
  selectedUser,
  newUserLoading,
  isAdmin,
  isBuyer,
  isPartner,
  isCustomer,
  updateUser,
  fetchSelectedUser,
  deleteSelectedUser,
  reenableSelectedUser,
  updatePassword,
}: EditUserProps) => {
  const [licensePhotoUpdated, setLicensePhotoUpdated] = useState<boolean>(false)
  const [fingerprintUpdated, setFingerprintUpdated] = useState<boolean>(false)

  const [loading, setLoading] = useState(true)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showReeneableModal, setShowReenableModal] = useState(false)
  const [showLicenseDetailsModal, setShowLicenseDetailsModal] = useState<boolean>(false)

  const showDeleteButton = isAdmin && selectedUser.killed === false
  const showReenableButton = isAdmin && selectedUser.killed === true

  const [showUpdatePasswordModal, setShowUpdatePasswordModal] = useState(false)
  const { id: selectedUserId } = useParams<{ id: string }>()

  useEffect(() => {
    const userPermission = isAdmin || isBuyer || isPartner

    if (selectedUserId) {
      fetchSelectedUser({ userId: selectedUserId, userPermission })
    }
    setLoading(false)
  }, [])

  const getInitialPermissionLevel = () => {
    if (selectedUser?.isAdmin) return 'admin'
    if (selectedUser?.isBuyer) return 'buyer'
    if (selectedUser?.isGuest) return 'guest'
    if (selectedUser?.isCustomer) return 'customer'
    if (selectedUser?.isPartner) return 'partner'
    return 'customer' //default to customer
  }

  const formik = useFormik({
    initialValues: {
      firstName: selectedUser?.firstName || '',
      lastName: selectedUser?.lastName || '',
      email: selectedUser?.email || '',
      password: 'A1placeholder',
      margin: selectedUser?.margin * 100 || '',
      location: selectedUser?.location || '',
      permissionLevel: getInitialPermissionLevel() || '',
      isBuyer: selectedUser?.isBuyer || false,
      isGuest: selectedUser?.isGuest || false,
      isAdmin: selectedUser?.isAdmin || false,
      isCustomer: selectedUser?.isCustomer || false,
      isPartner: selectedUser?.isPartner || false,
      streetAddress: selectedUser?.streetAddress || '',
      streetAddress2: selectedUser?.streetAddress2 || '',
      city: selectedUser?.city || '',
      state: selectedUser?.state || '',
      postalCode: selectedUser?.postalCode || '',
      fingerprint: selectedUser?.fingerprint || '',
      licenseNumber: selectedUser?.licenseDetails?.licenseNumber || '',
      licenseExpirationDate: selectedUser?.licenseDetails?.licenseExpirationDate || '',
      licenseIssueDate: selectedUser?.licenseDetails?.licenseIssueDate || '',
      licenseState: selectedUser?.licenseDetails?.licenseState || '',
      gender: selectedUser?.licenseDetails?.gender || '',
      dob: selectedUser?.licenseDetails?.dob || '',
      heightFeet: (selectedUser?.licenseDetails?.height?.match(/(\d+)'-(\d+)"/) || [])[1] || '',
      heightInches: (selectedUser?.licenseDetails?.height?.match(/(\d+)'-(\d+)"/) || [])[2] || '',
      eyeColor: selectedUser?.licenseDetails?.eyeColor || '',
      licensePhoto: selectedUser?.licenseDetails?.licensePhoto || '',
    },
    validationSchema: editUserSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const { initialValues } = formik // Access initial values

      // Define the union type of all form field names
      type FormFields = keyof typeof formik.values

      // Updated isFieldChanged helper function
      const isFieldChanged = (field: FormFields) => values[field] !== initialValues[field]

      // Create the body object with only changed values
      const body: EditUserBodyProps = {
        userPermission: isAdmin || isBuyer || isPartner,
        userId: selectedUser._id,
        ...(isFieldChanged('firstName') && { firstName: startCase(values.firstName) }),
        ...(isFieldChanged('lastName') && { lastName: startCase(values.lastName) }),
        ...(isFieldChanged('email') && { email: values.email.trim().toLowerCase() }),
        ...(isFieldChanged('password') && { password: values.password }),
        ...(isFieldChanged('margin') && { margin: Number(values.margin) }),
        ...(isFieldChanged('location') && { location: values.location }),
        ...(isFieldChanged('permissionLevel') && {
          isBuyer: values.permissionLevel === 'buyer',
          isAdmin: values.permissionLevel === 'admin',
          isGuest: values.permissionLevel === 'guest',
          isCustomer: values.permissionLevel === 'customer',
          isPartner: values.permissionLevel === 'partner',
        }),
        ...(isFieldChanged('streetAddress') && {
          streetAddress: startCase(values.streetAddress.trim()),
        }),
        ...(isFieldChanged('streetAddress2') && {
          streetAddress2: startCase(values.streetAddress2.trim()),
        }),
        ...(isFieldChanged('city') && { city: startCase(values.city.trim()) }),
        ...(isFieldChanged('state') && { state: values.state }),
        ...(isFieldChanged('postalCode') && { postalCode: values.postalCode.trim() }),
        ...(isFieldChanged('fingerprint') && { fingerprint: values.fingerprint }),
        licenseDetails: {
          ...(isFieldChanged('licensePhoto') && { licensePhoto: values.licensePhoto }),
          ...(isFieldChanged('licenseNumber') && { licenseNumber: values.licenseNumber }),
          ...(isFieldChanged('licenseIssueDate') && {
            licenseIssueDate: values.licenseIssueDate,
          }),
          ...(isFieldChanged('licenseExpirationDate') && {
            licenseExpirationDate: values.licenseExpirationDate,
          }),
          ...(isFieldChanged('licenseState') && { licenseState: values.licenseState }),
          ...(isFieldChanged('gender') && { gender: values.gender }),
          ...(isFieldChanged('dob') && { dob: values.dob }),
          ...((isFieldChanged('heightFeet') || isFieldChanged('heightInches')) && {
            height: `${values.heightFeet}'-${values.heightInches}"`,
          }),
          ...(isFieldChanged('eyeColor') && { eyeColor: values.eyeColor }),
        },
      }

      // Additional checks for fields that require a flag
      if (licensePhotoUpdated && body.licenseDetails) {
        body.licenseDetails.licensePhoto = values.licensePhoto
      }

      if (fingerprintUpdated) {
        body.fingerprint = values.fingerprint
      }

      updateUser(body)
      setLicensePhotoUpdated(false)
      setFingerprintUpdated(false)
    },
  })

  return (
    <Container fluid>
      {loading ? (
        <Row className="mt-3">
          <Col className="d-flex justify-content-center">
            <ScaleLoader color="#36D7B7" height={50} width={10} radius={4} margin={4} />
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            <Col>
              <Link to="/admin/view/users" className="ui button dashboard-add-converter-btn">
                Back To All Users
              </Link>
            </Col>
          </Row>
          <Card className="mt-3">
            <Card.Header>
              <h3>Edit User</h3>
            </Card.Header>
            <Card.Body>
              {newUserLoading ? (
                <Row className="mt-3">
                  <Col className="d-flex justify-content-center">
                    <ScaleLoader color="#36D7B7" height={50} width={10} radius={4} margin={4} />
                  </Col>
                </Row>
              ) : (
                <>
                  <Form noValidate onSubmit={formik.handleSubmit}>
                    <Row>
                      <Col xs={12}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <div className="edit-img-container">
                            <img
                              className="edit-img"
                              src={
                                formik.values.licensePhoto
                                  ? String(formik.values.licensePhoto)
                                  : '/placeholder.png'
                              }
                            />
                          </div>
                          <input
                            type="file"
                            className="inputfile"
                            id="embedpollfileinput"
                            accept="image/png, image/jpeg"
                            onChange={(e) => {
                              handleFileUpload(formik.setFieldValue, e, 'licensePhoto')

                              setLicensePhotoUpdated(true)
                            }}
                          />
                          <div style={{ height: '40px' }}>
                            <label htmlFor="embedpollfileinput" className="ui blue button">
                              <i className="ui upload icon" />
                              Upload License
                            </label>
                            {isSubmitted && !formik.values.licensePhoto && (
                              <Form.Control.Feedback type="invalid">
                                Please upload a license.
                              </Form.Control.Feedback>
                            )}
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} sm={6}>
                        <Form.Group>
                          <FloatingLabel label="First name" className="mt-3">
                            <Form.Control
                              type="text"
                              name="firstName"
                              placeholder="First name"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.firstName}
                              isValid={formik.touched.firstName && !formik.errors.firstName}
                            />
                          </FloatingLabel>
                          {formik.touched.firstName && (
                            <Form.Control.Feedback type="invalid">
                              {formik.errors.firstName}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>

                        <Form.Group>
                          <FloatingLabel label="Last name" className="mt-3">
                            <Form.Control
                              type="text"
                              name="lastName"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.lastName}
                              placeholder="Last name"
                              isValid={formik.touched.lastName && !formik.errors.lastName}
                            />
                          </FloatingLabel>
                          {formik.touched.lastName && (
                            <Form.Control.Feedback type="invalid">
                              {formik.errors.lastName}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>

                        <Form.Group>
                          <FloatingLabel label="Email" className="mt-3">
                            <Form.Control
                              type="text"
                              name="email"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.email}
                              placeholder="Email"
                              isValid={formik.touched.email && !formik.errors.email}
                            />
                          </FloatingLabel>
                          {formik.touched.email && (
                            <Form.Control.Feedback type="invalid">
                              {formik.errors.email}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                        {!isPartner && !isCustomer && (
                          <Form.Group>
                            <FloatingLabel label="Margin" className="mt-3">
                              <Form.Control
                                type="number"
                                name="margin"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.margin}
                                placeholder="margin"
                                isValid={formik.touched.margin && !formik.errors.margin}
                              />
                            </FloatingLabel>
                            <Form.Text id="marginHelpBlock" muted>
                              Must be a number at least 1 and under 100
                            </Form.Text>
                            {formik.touched.margin && (
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.margin}
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>
                        )}

                        {isAdmin && (
                          <Form.Group>
                            <FloatingLabel label="Set Permission Level" className="mt-3">
                              <Form.Control
                                id="permissionLevel"
                                name="permissionLevel"
                                as="select"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.permissionLevel}
                                isValid={
                                  formik.touched.permissionLevel && !formik.errors.permissionLevel
                                }
                              >
                                <option key="placeholder" value="">
                                  -
                                </option>
                                {permissions.map((p, idx) => {
                                  return (
                                    <option key={p.value} value={p.value}>
                                      {p.text}
                                    </option>
                                  )
                                })}
                              </Form.Control>
                            </FloatingLabel>

                            {formik.touched.permissionLevel && (
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.permissionLevel}
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>
                        )}
                      </Col>
                      <Col xs={12} sm={6}>
                        <Form.Group controlId="streetAddress">
                          <FloatingLabel label="Address Line 1" className="mt-3">
                            <Form.Control
                              type="text"
                              name="streetAddress"
                              placeholder="Street address"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.streetAddress}
                              isValid={formik.touched.streetAddress && !formik.errors.streetAddress}
                            />
                          </FloatingLabel>
                          {
                            <Form.Control.Feedback type="invalid">
                              {formik.errors.streetAddress}
                            </Form.Control.Feedback>
                          }
                        </Form.Group>

                        <Form.Group>
                          <FloatingLabel label="Address Line 2" className="mt-3">
                            <Form.Control
                              id="streetAddress2"
                              name="streetAddress2"
                              placeholder="streetAddress2"
                              type="text"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.streetAddress2}
                              isValid={
                                formik.touched.streetAddress2 && !formik.errors.streetAddress2
                              }
                            />
                          </FloatingLabel>
                          {
                            <Form.Control.Feedback type="invalid">
                              {formik.errors.streetAddress2}
                            </Form.Control.Feedback>
                          }
                        </Form.Group>

                        <Form.Group>
                          <FloatingLabel label="City" className="mt-3">
                            <Form.Control
                              id="city"
                              name="city"
                              type="text"
                              placeholder="City"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.city}
                              isValid={formik.touched.city && !formik.errors.city}
                            />
                          </FloatingLabel>
                          {
                            <Form.Control.Feedback type="invalid">
                              {formik.errors.city}
                            </Form.Control.Feedback>
                          }
                        </Form.Group>

                        <Form.Group>
                          <FloatingLabel label="State" className="mt-3">
                            <Form.Control
                              id="state"
                              name="state"
                              as="select"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.state}
                              isValid={formik.touched.state && !formik.errors.state}
                            >
                              <option key="placeholder" value="">
                                -
                              </option>
                              {states.map((p, idx) => {
                                return (
                                  <option key={p.value} value={p.value}>
                                    {p.text}
                                  </option>
                                )
                              })}
                            </Form.Control>
                          </FloatingLabel>

                          {
                            <Form.Control.Feedback type="invalid">
                              {formik.errors.state}
                            </Form.Control.Feedback>
                          }
                        </Form.Group>

                        <Form.Group>
                          <FloatingLabel label="Postal Code" className="mt-3">
                            <Form.Control
                              id="postalCode"
                              name="postalCode"
                              type="text"
                              placeholder="Postal Code"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.postalCode}
                              isValid={formik.touched.postalCode && !formik.errors.postalCode}
                            />
                          </FloatingLabel>

                          {
                            <Form.Control.Feedback type="invalid">
                              {formik.errors.postalCode}
                            </Form.Control.Feedback>
                          }
                        </Form.Group>

                        {(isAdmin || isBuyer) && (
                          <Form.Group>
                            <FloatingLabel label="Set Primary Location" className="mt-3">
                              <Form.Control
                                id="location"
                                name="location"
                                as="select"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.location}
                                isValid={formik.touched.location && !formik.errors.location}
                              >
                                {locations.map((location) => {
                                  if (location.value === 'all')
                                    return (
                                      <option key="placeholder" value={''}>
                                        -
                                      </option>
                                    )
                                  return (
                                    <option key={location.value} value={location.value}>
                                      {location.text}
                                    </option>
                                  )
                                })}
                              </Form.Control>
                            </FloatingLabel>

                            {formik.touched.location && (
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.location}
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>
                        )}

                        {formik.values.location === 'florida' &&
                          !buyerRoles.includes(formik.values.permissionLevel) && (
                            <Form.Group>
                              <div
                                style={{
                                  marginTop: '12px',
                                  border: '1px solid #ced4da',
                                  borderRadius: '.25rem',
                                  padding: '0.5rem .75rem',
                                }}
                              >
                                <label
                                  style={{ marginBottom: '4px', opacity: '.65', fontSize: '12px' }}
                                  htmlFor="fingerprint"
                                >
                                  Upload New Fingerprint:
                                </label>
                                <input
                                  type="file"
                                  id="fingerprint"
                                  onChange={(e) => {
                                    handleFileUpload(formik.setFieldValue, e, 'fingerprint')
                                    setFingerprintUpdated(true)
                                  }}
                                />
                                {formik.values.fingerprint && (
                                  <div className="mt-4">
                                    <span style={{ fontSize: '12px', marginRight: '16px' }}>
                                      Existing fingerprint:
                                    </span>
                                    <img
                                      height="100"
                                      alt="fingerprint"
                                      src={String(formik.values.fingerprint)}
                                    />
                                  </div>
                                )}
                                {isSubmitted && !formik.values.fingerprint && (
                                  <Form.Control.Feedback type="invalid">
                                    {formik.errors.fingerprint}
                                  </Form.Control.Feedback>
                                )}
                              </div>
                            </Form.Group>
                          )}
                      </Col>
                    </Row>
                  </Form>
                  {isSubmitted && formikLicenseErrors(formik) && (
                    <Form.Control.Feedback type="invalid">
                      Missing license details. Please add license details.
                    </Form.Control.Feedback>
                  )}
                  <Row className="mt-5 d-flex justify-content-around">
                    <Col xs={3}>
                      <Button
                        onClick={() => {
                          formik.submitForm()
                          setIsSubmitted(true)
                        }}
                        style={{ width: `100%` }}
                      >
                        Update User
                      </Button>
                    </Col>
                    <Col xs={3}>
                      <Button
                        variant="success"
                        onClick={() => setShowLicenseDetailsModal(true)}
                        style={{ width: `100%` }}
                      >
                        Add License Details
                      </Button>
                    </Col>
                    {showReenableButton && (
                      <Col xs={3}>
                        <Button
                          variant="secondary"
                          onClick={() => setShowReenableModal(true)}
                          style={{ width: `100%` }}
                        >
                          Re-enable User
                        </Button>
                      </Col>
                    )}
                    <Col xs={3}>
                      <Button
                        variant="secondary"
                        onClick={() => setShowUpdatePasswordModal(true)}
                        style={{ width: `100%` }}
                      >
                        Update Password
                      </Button>
                    </Col>
                    {showDeleteButton && (
                      <Col xs={3}>
                        <Button
                          variant="danger"
                          onClick={() => setShowDeleteModal(true)}
                          style={{ width: `100%` }}
                        >
                          Delete User
                        </Button>
                      </Col>
                    )}
                  </Row>
                </>
              )}
              {showReeneableModal && (
                <ReenableUserModal
                  showReeneableUserModal={showReeneableModal}
                  reenableSelectedUser={reenableSelectedUser}
                  selectedUser={selectedUser}
                  setShowReenableModal={setShowReenableModal}
                  userPermission={isAdmin || isBuyer}
                />
              )}
              {showDeleteModal && (
                <DeleteUserModal
                  showDeleteUserModal={showDeleteModal}
                  deleteSelectedUser={deleteSelectedUser}
                  selectedUser={selectedUser}
                  setShowDeleteModal={setShowDeleteModal}
                  userPermission={isAdmin || isBuyer}
                />
              )}
              {showUpdatePasswordModal && (
                <UpdatePasswordModal
                  showUpdatePasswordModal={showUpdatePasswordModal}
                  updatePassword={updatePassword}
                  selectedUser={selectedUser}
                  setShowUpdatePasswordModal={setShowUpdatePasswordModal}
                />
              )}
              {showLicenseDetailsModal && (
                <AddLicenseDetailsModal
                  show={showLicenseDetailsModal}
                  setShowLicenseDetailsModal={setShowLicenseDetailsModal}
                  setFieldValue={formik.setFieldValue}
                  initialValues={{
                    licenseNumber: formik.values.licenseNumber,
                    licenseExpirationDate: formik.values.licenseExpirationDate,
                    licenseIssueDate: formik.values.licenseIssueDate,
                    licenseState: formik.values.licenseState,
                    heightFeet: formik.values.heightFeet,
                    heightInches: formik.values.heightInches,
                    eyeColor: formik.values.eyeColor,
                    gender: formik.values.gender,
                    dob: formik.values.dob,
                  }}
                  parentFormik={formik}
                />
              )}
            </Card.Body>
          </Card>
        </>
      )}
    </Container>
  )
}

const mapStateToProps = (state: RootState) => ({
  isAdmin: state.user.isAdmin,
  isBuyer: state.user.isBuyer,
  isPartner: state.user.isPartner,
  newUserLoading: state.admin.newUserLoading,
  selectedUser: state.admin.selectedUser,
})

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    updateUser: (body: EditUserBodyProps) => dispatch(updateUserThunk(body)),
    fetchSelectedUser: (body: { userId: string; userPermission: boolean }) =>
      dispatch(fetchSelectedUserThunk(body)),
    deleteSelectedUser: (body: { userId: string; userPermission: boolean }) =>
      dispatch(deleteSelectedUserThunk(body)),
    reenableSelectedUser: (body: { userId: string; userPermission: boolean }) =>
      dispatch(reenableSelectedUserThunk(body)),
    updatePassword: (body: { userId: string; newPassword: string }) =>
      dispatch(updatePasswordThunk(body)),
  }
}

// Merge the Redux props
const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(EditUser)
