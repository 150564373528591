import React from 'react'
import { connect } from 'react-redux'
import Invoices from './admin-dashboard/Invoices'

const SelectedUserInvoices = ({ user }) => {
  return <Invoices myInvoices={user.invoices} />
}

const mapState = (state) => {
  return {
    user: state.user,
  }
}

export default connect(mapState, null)(SelectedUserInvoices)
