import React from 'react'
import { connect } from 'react-redux'
import { fetchSingleConverter } from '../../store'
import { AppDispatch } from '../../store'
import { Button, InputGroup, Form } from 'react-bootstrap'
import { exportPriceListToCsv } from './utils'

interface MarginUpdateProps {
  resetDefault: () => void
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void
  handleChange: (event: React.ChangeEvent) => void
  makes: object[]
}

const MarginUpdate = ({ resetDefault, handleSubmit, handleChange, makes }: MarginUpdateProps) => {
  return (
    <div className="margin-update-container">
      <div style={{ width: '230px' }}>
        <InputGroup>
          <InputGroup.Text id="inputGroup-sizing-lg">Set custom margin:</InputGroup.Text>
          <Form.Control
            aria-label="Default"
            aria-describedby="inputGroup-sizing-default"
            onChange={handleChange}
            // value={margin}
          />
        </InputGroup>
      </div>

      <Button onClick={handleSubmit} variant="outline-primary" className="update-margin-btn">
        Update
      </Button>
      <Button onClick={resetDefault} variant="outline-warning" className="update-margin-btn">
        Reset
      </Button>
      <Button onClick={() => window.print()} variant="success" className="update-margin-btn">
        Save as PDF
      </Button>
      <Button
        onClick={() => exportPriceListToCsv(makes)}
        variant="success"
        className="update-margin-btn"
      >
        Download to CSV
      </Button>
    </div>
  )
}

const mapDispatch = (dispatch: AppDispatch) => {
  return {
    fetchSingleConverter: (serialNumber: string) => dispatch(fetchSingleConverter(serialNumber)),
  }
}

const Connected = connect(null, mapDispatch)(MarginUpdate)

export default Connected
