import history from '../history'
import { toast } from 'react-toastify'
import { apiCaller } from '../utils/apiCaller'
import { getPrices } from '../store/prices'

/**
 * ACTION TYPES
 */

const GET_ALL_USERS = 'GET_ALL_USERS'
const GET_SELECTED_USER = 'GET_SELECTED_USER'
const SIGN_UP = 'SIGN-UP'
const REMOVE_SIGN_UP = 'REMOVE_SIGN_UP'
const REMOVE_SELECTED_USER = 'REMOVE_SELECTED_USER'
const SET_CONTROLS = 'SET_CONTROLS'
const GET_KILLED_CONVERTERS = 'GET_KILLED_CONVERTERS'
const GET_INVOICES_DATA = 'GET_INVOICES_DATA'
const SET_NEW_USER_LOADING_TRUE = 'SET_NEW_USER_LOADING_TRUE'
const SET_NEW_USER_LOADING_FALSE = 'SET_NEW_USER_LOADING_FALSE'
const GET_ALL_BUYERS = 'GET_ALL_BUYERS'

/**
 * INITIAL STATE
 */
const defaultState = {
  allUsers: [],
  selectedUser: {
    id: 0,
    firstName: '',
    lastName: '',
    email: '',
    margin: '',
    isAdmin: false,
    photo: '',
    error: '',
  },
  signUp: {},
  controls: {},
  killedConverters: [],
  invoicesData: [],
  newUserLoading: false,
  allBuyers: [],
}

/**
 * ACTION CREATORS
 */
const setNewUserLoadingTrue = () => ({ type: SET_NEW_USER_LOADING_TRUE })
const setNewUserLoadingFalse = () => ({ type: SET_NEW_USER_LOADING_FALSE })

const getAllUsers = (allUsers) => ({ type: GET_ALL_USERS, allUsers })
const getSelectedUser = (user) => ({ type: GET_SELECTED_USER, user })

const signUp = (user) => ({ type: SIGN_UP, user })
export const removeSignUp = (user) => ({ type: REMOVE_SIGN_UP, user })
const updateSelectedUser = (user) => ({ type: GET_SELECTED_USER, user })
export const removeSelectedUser = (user) => ({ type: REMOVE_SELECTED_USER, user })

const setControls = (controls) => ({ type: SET_CONTROLS, controls })
const setKilledConverters = (converters) => ({ type: GET_KILLED_CONVERTERS, converters })

const getInvoicesData = (invoicesData) => ({ type: GET_INVOICES_DATA, invoicesData })

const getAllBuyers = (buyers) => ({ type: GET_ALL_BUYERS, buyers })
/**
 * THUNK CREATORS
 */
export const fetchAllUsersThunk = () => async (dispatch) => {
  try {
    const res = await apiCaller(`api/users`)
    dispatch(getAllUsers(res.data.users))
  } catch (err) {
    console.error(err)
  }
}

export const fetchAllBuyers = () => async (dispatch) => {
  try {
    const res = await apiCaller(`api/users/buyers`)
    dispatch(getAllBuyers(res.data.users))
  } catch (err) {
    console.error(err)
  }
}

export const fetchSelectedUserThunk = (body) => async (dispatch) => {
  if (body.userPermission) {
    try {
      const res = await apiCaller(`api/users/admin/${body.userId}`)
      dispatch(getSelectedUser(res.data.user))
    } catch (error) {
      console.error('this is err', error)
      dispatch(getSelectedUser({ error }))
    }
  } else {
    //can only access their own profile
    try {
      const res = await apiCaller(`api/users/${body.userId}`)
      dispatch(getSelectedUser(res.data.user))
    } catch (error) {
      console.error('this is err', error)
      dispatch(getSelectedUser({ error }))
    }
  }
}

export const updateUserThunk = (body) => async (dispatch) => {
  dispatch(setNewUserLoadingTrue())

  if (body.userPermission) {
    try {
      const res = await apiCaller(`api/users/admin/${body.userId}`, 'put', body)
      dispatch(updateSelectedUser(res.data.user))
      toast.success(`Success updating user`)
    } catch (error) {
      console.error('this is the error', error.response)
      toast.error(`There was an error: ${error.response.data.error}.`)
    }
  } else {
    try {
      const res = await apiCaller(`api/users/${body.userId}`, 'put', body)
      dispatch(updateSelectedUser(res.data.user))
    } catch (error) {
      console.error('this is the error', error.response)
      toast.error(`There was an error: ${error.response.data.error}.`)
    }
  }
  dispatch(fetchAllUsersThunk())
  dispatch(setNewUserLoadingFalse())
}

export const updatePasswordThunk = (body) => async (dispatch) => {
  try {
    const res = await apiCaller(`api/users/${body.userId}/password`, 'put', body)
    dispatch(updateSelectedUser(res.data.user))
    toast.success('Password updated!')
  } catch (error) {
    console.error('this is the error', error.response)
    dispatch(updateSelectedUser({ error }))
  }
}

export const deleteSelectedUserThunk = (body) => async (dispatch) => {
  if (body.userPermission) {
    try {
      const res = await apiCaller(`api/users/admin/${body.userId}`, 'delete', body)
      history.push('/admin/view/users')
      toast.success('User Deleted')
    } catch (error) {
      history.push('/admin/view/users')
      toast.error(`There was an error: ${error.response.data}.`)
    }
  }
}

export const reenableSelectedUserThunk = (body) => async (dispatch) => {
  if (body.userPermission) {
    try {
      const res = await apiCaller(`api/users/admin/${body.userId}/reenable`, 'put', body)
      toast.success('User Re-Enabled!')
      dispatch(getSelectedUser(res.data.user))
    } catch (error) {
      toast.error(`There was an error: ${error.response.data}.`)
    }
  }
}

export const postSignUp = (body, resetForm) => async (dispatch) => {
  dispatch(setNewUserLoadingTrue())
  try {
    const { data } = await apiCaller(`auth/signup`, 'post', body)
    toast.success('User created!')
    resetForm()
    history.push(`/admin/edit/user/${data.user._id}`)
    dispatch(setNewUserLoadingFalse())
  } catch (error) {
    toast.error(`There was an error: ${error.response?.data?.message}`)
    dispatch(setNewUserLoadingFalse())
  }
}

export const fetchControls = (body) => async (dispatch) => {
  let res
  try {
    res = await apiCaller(`api/controls`)
    dispatch(setControls(res.data.control))
  } catch (error) {
    console.error('There was an error', error.response.data)
    toast.error(`There was an error: ${error.response.data}.`)
  }
}

export const updatePriceControls = (body) => async (dispatch) => {
  try {
    let { data } = await apiCaller(`api/controls/price-modifier`, 'put', body)
    dispatch(getPrices(data.prices))
  } catch (error) {
    console.error('There was an error', error.response.data)
    toast.error(`There was an error: ${error.response.data}.`)
  }
}

export const resetPriceControlsThunk = () => async (dispatch) => {
  try {
    let { data } = await apiCaller(`api/controls/reset-price-modifier`, 'put', {})
    dispatch(getPrices(data.prices))
  } catch (error) {
    console.error('There was an error', error.response.data)
    toast.error(`There was an error: ${error.response.data}.`)
  }
}

export const fetchKilledConverters = () => async (dispatch) => {
  let res
  try {
    res = await apiCaller(`api/converters/all/killed`)
    dispatch(setKilledConverters(res.data.converters))
  } catch (error) {
    console.error('There was an error', error.response.data)
    toast.error(`There was an error: ${error.response.data}.`)
  }
}

export const reAddKilledConverter = (converter) => async (dispatch) => {
  let res
  try {
    res = await apiCaller(`api/converters/killed/${converter._id}`, 'put', {})
    dispatch(setKilledConverters(res.data.converters))
  } catch (error) {
    console.error('There was an error', error.response.data)
    toast.error(`There was an error: ${error.response.data}.`)
  }
}

export const fetchInvoicesData =
  ({ startDate, endDate }) =>
  async (dispatch) => {
    try {
      const { data } = await apiCaller('api/admin/invoices-data', 'post', {
        startDate,
        endDate,
      })

      dispatch(getInvoicesData(data))
    } catch (err) {
      console.error(err)
    }
  }

/**
 * REDUCER
 */
export default function (state = defaultState, action) {
  switch (action.type) {
    case GET_ALL_USERS:
      return { ...state, allUsers: action.allUsers }
    case GET_ALL_BUYERS:
      return { ...state, allBuyers: action.buyers }
    case GET_SELECTED_USER:
      return { ...state, selectedUser: action.user }
    case SIGN_UP:
      return { ...state, signUp: action.user }
    case REMOVE_SIGN_UP:
      return { ...state, signUp: action.user }
    case REMOVE_SELECTED_USER:
      return { ...state, selectedUser: action.user }
    case SET_CONTROLS:
      return { ...state, controls: action.controls }
    case GET_KILLED_CONVERTERS:
      return { ...state, killedConverters: action.converters }
    case SET_NEW_USER_LOADING_TRUE:
      return { ...state, newUserLoading: true }
    case SET_NEW_USER_LOADING_FALSE:
      return { ...state, newUserLoading: false }
    default:
      return state
  }
}
