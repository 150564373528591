import React from 'react'
import { Container, Row, Col, Form, Card, Button } from 'react-bootstrap'
import { fetchMetalsData, RootState, AppDispatch } from '../../store'
import { useFormik } from 'formik'
import * as yup from 'yup' // for everything
import { connect } from 'react-redux'
import DatePicker from 'react-date-picker'
import { startCase } from 'lodash'

const schema = yup.object({
  startDate: yup.date().required('Start date required.'),
  endDate: yup
    .date()
    .required('End date required.')
    .min(yup.ref('startDate'), "End date can't be before start date."),
})

interface MetalsDataProps {
  historicalMetalsData: any
  fetchMetalsData: (values: { startDate: string; endDate: string }) => void
}

const MetalsData = ({ historicalMetalsData, fetchMetalsData }: MetalsDataProps) => {
  const formik = useFormik({
    initialValues: {
      startDate: '',
      endDate: '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      fetchMetalsData(values)
    },
  })

  const { locationSums } = historicalMetalsData || {}
  const locations = locationSums && Object.keys(locationSums)

  return (
    <Container fluid>
      <Card className="mt-3">
        <Card.Header>
          <h3>Historical Metals Data</h3>
        </Card.Header>
        <Card.Body>
          <Form noValidate onSubmit={formik.handleSubmit}>
            <Row>
              <Col xs={12} sm={4} style={{ margin: 0 }}>
                <Form.Label style={{ fontWeight: '600' }}>Set Start Date</Form.Label>
                <div style={{ width: '100%' }}>
                  <DatePicker
                    type="date"
                    name="startDate"
                    onChange={(val) => {
                      formik.setFieldValue('startDate', val)
                    }}
                    value={formik.values.startDate}
                  />
                </div>
                {formik.touched.startDate && (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.startDate}
                  </Form.Control.Feedback>
                )}
              </Col>
              <Col xs={12} sm={4} style={{ margin: 0 }}>
                <Form.Label style={{ fontWeight: '600' }}>Set End Date</Form.Label>
                <DatePicker
                  type="date"
                  name="endDate"
                  onChange={(val) => {
                    formik.setFieldValue('endDate', val)
                  }}
                  value={formik.values.endDate}
                />
                {formik.touched.endDate && (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.endDate}
                  </Form.Control.Feedback>
                )}
              </Col>
            </Row>
          </Form>
          <Row className="mt-3">
            <Col xs={6} sm={4}>
              <Button
                type="submit"
                onClick={() => {
                  formik.submitForm()
                }}
                style={{ width: `100%` }}
              >
                Get Data
              </Button>
            </Col>
            <Col xs={6} sm={4} style={{ justifyContent: 'flex-end', display: 'flex' }}></Col>
          </Row>
        </Card.Body>
      </Card>
      {Object.values(historicalMetalsData).length > 0 && (
        <Card className="mt-3">
          <Card.Header>
            <h3>All PPM</h3>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col>
                <strong></strong>
              </Col>
              <Col>
                <strong>Rhodium</strong>
              </Col>
              <Col>
                <strong>Platinum</strong>
              </Col>
              <Col>
                <strong>Palladium</strong>
              </Col>
            </Row>
            <hr />

            <div>
              <Row>
                <Col>
                  <strong>Total Troy Ounces</strong>
                </Col>
                <Col>{historicalMetalsData.troyRHSum}</Col>
                <Col>{historicalMetalsData.troyPTSum}</Col>
                <Col>{historicalMetalsData.troyPDSum}</Col>
              </Row>
              {locations.map((location) => {
                return (
                  <Row className="mt-3" key={locationSums[location]._id}>
                    <Col>{startCase(location)}</Col>
                    <Col>{locationSums[location].troyRHSum}</Col>
                    <Col>{locationSums[location].troyPTSum}</Col>
                    <Col>{locationSums[location].troyPDSum}</Col>
                  </Row>
                )
              })}

              <hr />
            </div>
          </Card.Body>
        </Card>
      )}
    </Container>
  )
}

const mapState = (state: RootState) => {
  return {
    historicalMetalsData: state.prices.historical,
  }
}

const mapDispatch = (dispatch: AppDispatch) => {
  return {
    fetchMetalsData(dates: { startDate: string; endDate: string }) {
      dispatch(fetchMetalsData(dates))
    },
  }
}

export default connect(mapState, mapDispatch)(MetalsData)
