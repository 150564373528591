import React from 'react'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import { removePdfThunk } from '../../store'
import { sanitizeMarginInput } from './utils'
import { toast } from 'react-toastify'

//20190506183451
//20190506183522
//20190506183522
class PdfViewer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}

    this.notifyLoad = this.notifyLoad.bind(this)
    this.notifyDownload = this.notifyDownload.bind(this)
    //this.generatePriceList = this.generatePriceList.bind(this)
  }

  componentDidMount() {
    this.notifyLoad()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pdf.data !== this.props.pdf.data) {
      this.notifyDownload()
    }
  }

  // generatePriceList(margin) {
  //   let sanitizedMargin = sanitizeMarginInput(margin)
  //   this.props.generatePDF(sanitizedMargin)
  // }

  notifyLoad() {
    toast.info('We are preparing your price list, hang tight!')
  }

  notifyDownload() {
    toast.success('Your price list has been generated and is ready to be printed or downloaded!')
  }

  render() {
    const { error } = this.props.pdf

    if (error) {
      toast.error(`${error.response.data}, please try again in a moment!`)
      return <Redirect to="/admin/create-price-list/" />
    } else if (this.props.pdf.data === undefined) {
      return (
        <div className="price-list-page-container">
          <Link
            to="/admin/create-price-list"
            className="ui fluid button orange margin"
            onClick={this.props.removePDF}
          >
            Back
          </Link>
          <div className="price-list-page ">
            <div className="ui fluid segment full">
              <div className="ui active transition visible inverted dimmer">
                <div className="content">
                  <div className="ui large text loader">Preparing Files</div>
                </div>
              </div>
              <img
                src="https://react.semantic-ui.com/images/wireframe/paragraph.png"
                className="ui image"
              />
            </div>

            <div className="ui fluid segment full">
              <div className="ui active transition visible inverted dimmer">
                <div className="content">
                  <div className="ui large text loader">Preparing Files</div>
                </div>
              </div>
              <img
                src="https://react.semantic-ui.com/images/wireframe/paragraph.png"
                className="ui image"
              />
            </div>
            <div className="ui fluid segment full">
              <div className="ui active transition visible inverted dimmer">
                <div className="content">
                  <div className="ui large text loader">Preparing Files</div>
                </div>
              </div>
              <img
                src="https://react.semantic-ui.com/images/wireframe/paragraph.png"
                className="ui image"
              />
            </div>
            <div className="ui fluid segment full">
              <div className="ui active transition visible inverted dimmer">
                <div className="content">
                  <div className="ui large text loader">Preparing Files</div>
                </div>
              </div>
              <img
                src="https://react.semantic-ui.com/images/wireframe/paragraph.png"
                className="ui image"
              />
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="price-list-page-container">
          <Link
            to="/admin/create-price-list"
            className="ui fluid button orange margin"
            onClick={this.props.removePDF}
          >
            Back
          </Link>
          <div className="price-list-page margin">
            <embed
              src="../accurate-converter-price-list.pdf"
              width="100%"
              height="99%"
              type="application/pdf"
            />
          </div>
        </div>
      )
    }
  }
}

const mapState = (state) => {
  return {
    pdf: state.converter.pdf,
  }
}

const mapDispatch = (dispatch) => {
  return {
    removePDF: () => dispatch(removePdfThunk()),
  }
}

const connected = connect(mapState, mapDispatch)(PdfViewer)

export default connected
