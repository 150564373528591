import { apiCaller } from '../utils/apiCaller'
import { toast } from 'react-toastify'
import history from '../history'

/**
 * ACTION TYPES
 */
const GET_ALL_BUSINESSES = 'GET_ALL_BUSINESSES'
const GET_SELECTED_BUSINESS = 'GET_SELECTED_BUSINESS'
const REMOVE_SELECTED_BUSINESS = 'REMOVE_SELECTED_BUSINESS'
const ADD_BUSINESS = 'ADD_BUSINESS'
const SET_BUSINESSES_LOADING_TRUE = 'SET_BUSINESSES_LOADING_TRUE'
const SET_BUSINESSES_LOADING_FALSE = 'SET_BUSINESSES_LOADING_FALSE'
const SET_NEW_BUSINESS_LOADING_TRUE = 'SET_NEW_BUSINESS_LOADING_TRUE'
const SET_NEW_BUSINESS_LOADING_FALSE = 'SET_NEW_BUSINESS_LOADING_FALSE'

const emptyBusiness = {
  id: 0,
  name: '',
  address: '',
  city: '',
  state: '',
  zip: '',
  phone: '',
  email: '',
  website: '',
  error: '',
}

/**
 * INITIAL STATE
 */
const initialState = {
  allBusinesses: [],
  selectedBusiness: emptyBusiness,
  businessesLoading: false,
  newBusinessLoading: false,
}

/**
 * ACTION CREATORS
 */

const setBusinessesLoadingTrue = () => ({ type: SET_BUSINESSES_LOADING_TRUE })
const setBusinessesLoadingFalse = () => ({ type: SET_BUSINESSES_LOADING_FALSE })

const setNewBusinessLoadingTrue = () => ({ type: SET_NEW_BUSINESS_LOADING_TRUE })
const setNewBusinessLoadingFalse = () => ({ type: SET_NEW_BUSINESS_LOADING_FALSE })

const getAllBusinesses = (allBusinesses) => ({
  type: GET_ALL_BUSINESSES,
  allBusinesses,
})

const getSingleBusiness = (selectedBusiness) => ({
  type: GET_SELECTED_BUSINESS,
  selectedBusiness,
})

const updateBusiness = (selectedBusiness) => ({
  type: GET_SELECTED_BUSINESS,
  selectedBusiness,
})

export const removeSingleBusiness = (selectedBusiness) => ({
  type: REMOVE_SELECTED_BUSINESS,
  selectedBusiness,
})

export const addBusiness = (business) => ({
  type: ADD_BUSINESS,
  business,
})

/**
 * THUNK CREATORS
 */

export const fetchAllBusinessesThunk = () => async (dispatch) => {
  dispatch(setBusinessesLoadingTrue())
  try {
    const res = await apiCaller('api/business', 'get', {})
    dispatch(getAllBusinesses(res.data.businesses))
    dispatch(setBusinessesLoadingFalse())
  } catch (err) {
    console.error(err)
  }
}

export const fetchSelectedBusinessThunk = (id) => async (dispatch) => {
  dispatch(removeSingleBusiness(emptyBusiness))
  dispatch(setBusinessesLoadingTrue())
  try {
    const res = await apiCaller(`api/business/${id}`)
    dispatch(getSingleBusiness(res.data.business))
    dispatch(setBusinessesLoadingFalse())
  } catch (err) {
    console.error(err)
  }
}

export const createBusiness = (body, resetForm) => async (dispatch) => {
  dispatch(setNewBusinessLoadingTrue())
  try {
    const res = await apiCaller('api/business', 'post', body)
    dispatch(addBusiness(res.data))
    resetForm()
    dispatch(fetchAllBusinessesThunk()).then(() => {
      history.push(`/admin/view/businesses`)
      toast.success('Business created!')
    })
  } catch (err) {
    console.error(err)
  }
  dispatch(setNewBusinessLoadingFalse())
}

export const updateBusinessThunk = (body) => async (dispatch) => {
  dispatch(setNewBusinessLoadingTrue())

  try {
    const res = await apiCaller(`api/business/${body.businessId}`, 'put', body)

    dispatch(updateBusiness(res.data.business))
    dispatch(fetchAllBusinessesThunk())
    toast.success('Business updated!')
    dispatch(setNewBusinessLoadingFalse())
  } catch (err) {
    console.error(err)
  }
}

/**
 * REDUCER
 */

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_BUSINESSES:
      return { ...state, allBusinesses: action.allBusinesses }
    case GET_SELECTED_BUSINESS:
      return { ...state, selectedBusiness: action.selectedBusiness }
    case REMOVE_SELECTED_BUSINESS:
      return { ...state, selectedBusiness: emptyBusiness }
    case ADD_BUSINESS:
      return { ...state, allBusinesses: [...state.allBusinesses, action.business] }
    case SET_BUSINESSES_LOADING_TRUE:
      return { ...state, businessesLoading: true }
    case SET_BUSINESSES_LOADING_FALSE:
      return { ...state, businessesLoading: false }
    case SET_NEW_BUSINESS_LOADING_TRUE:
      return { ...state, newBusinessLoading: true }
    case SET_NEW_BUSINESS_LOADING_FALSE:
      return { ...state, newBusinessLoading: false }
    default:
      return state
  }
}
