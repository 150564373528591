import React, { useState } from 'react'
import { Modal, Button, Row, Col, Form } from 'react-bootstrap'
import { useFormik } from 'formik'
import * as yup from 'yup' // for everything
import { connect } from 'react-redux'

import { addToCartThunk } from '../store'

const schema = yup.object({
  code: yup.string().required('Required'),
  currentPrice: yup.number().required('Required').typeError('Must be a number'),
})

const AddOutsideConverterToCart = ({ show, toggle, cart, addToCart }) => {
  const [index, setIndex] = useState(0)
  const formik = useFormik({
    initialValues: {
      code: '',
      currentPrice: '',
      quantity: '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      addToCart({
        cartId: cart._id,
        converter: {
          currentPrice: values.currentPrice,
          serialNumber: values.code,
          make: values.code,
        },
      })
      toggle()
      setIndex(0)
      formik.resetForm()
    },
  })

  const handleBody = () => {
    const filtered =
      cart &&
      cart.items.filter((item) => {
        if (item.inDatabase === false) {
          return item
        }
      })
    if (index === 0) {
      return (
        <>
          <Modal.Body>
            <Row style={{ minHeight: `75%` }}>
              <Col>
                {cart && !filtered.length ? (
                  <div>There are currently no outside codes in your cart.</div>
                ) : (
                  <>
                    <Row>
                      <Col>Code</Col>
                      <Col>Quantity</Col>
                      <Col>Price</Col>
                      <Col></Col>
                    </Row>
                    <hr />
                  </>
                )}
                {cart &&
                  filtered.map((item, idx) => {
                    if (item.inDatabase === false) {
                      return (
                        <>
                          <Row className="mt-3" style={{ display: 'flex', alignItems: 'center' }}>
                            <Col> {item.serialNumber}</Col>
                            <Col> {item.quantity}</Col>
                            <Col>
                              {' '}
                              {new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              }).format(item.price)}
                            </Col>
                            <Col
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <Button
                                style={{
                                  background: '#0d6efd',
                                  borderColor: '#0d6efd',
                                  color: 'white',
                                }}
                                onClick={() => {
                                  addToCart({
                                    cartId: cart._id,
                                    converter: {
                                      currentPrice: item.price,
                                      _id: item._id,
                                      serialNumber: item.serialNumber,
                                    },
                                  })
                                }}
                              >
                                {' '}
                                +
                              </Button>
                            </Col>
                          </Row>
                          {idx !== cart.items.length - 1 && <hr />}
                        </>
                      )
                    }
                  })}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Row className="mb-3" style={{ width: `100%` }}>
              <Col xs="3">
                <Button block onClick={toggle} style={{ width: `100%` }} variant="danger">
                  Back
                </Button>
              </Col>
              <Col xs="9">
                <Button
                  block
                  onClick={() => {
                    setIndex(1)
                  }}
                  variant="success"
                  style={{
                    width: `100%`,
                    background: '#f2711c',
                    borderColor: '#f2711c',
                    color: 'white',
                  }}
                >
                  Continue
                </Button>
              </Col>
            </Row>
          </Modal.Footer>
        </>
      )
    } else if (index === 1) {
      return (
        <>
          <Modal.Body>
            <Row>
              <Col>
                <Form noValidate onSubmit={formik.handleSubmit}>
                  <Row className="mt-3">
                    <Col xs="11">
                      <Form.Label style={{ fontWeight: '600' }}>Enter Code</Form.Label>
                      <Form.Control
                        type="text"
                        name="code"
                        value={formik.values.code}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        isValid={formik.touched.code && !formik.errors.code}
                        isInvalid={
                          (formik.submitCount || formik.touched.code) && !!formik.errors.code
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.code}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col xs="11">
                      <Form.Label style={{ fontWeight: '600' }}>Enter Price</Form.Label>
                      <Form.Control
                        type="text"
                        name="currentPrice"
                        value={formik.values.currentPrice}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        isValid={formik.touched.currentPrice && !formik.errors.currentPrice}
                        isInvalid={
                          (formik.submitCount || formik.touched.currentPrice) &&
                          !!formik.errors.currentPrice
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.currentPrice}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Row className="mb-3" style={{ width: `100%` }}>
              <Col xs="3">
                <Button block onClick={toggle} style={{ width: `100%` }} variant="danger">
                  Back
                </Button>
              </Col>
              <Col xs="9">
                <Button
                  block
                  onClick={() => {
                    formik.submitForm(formik.values)
                  }}
                  variant="success"
                  style={{
                    width: `100%`,
                    background: '#f2711c',
                    borderColor: '#f2711c',
                    color: 'white',
                  }}
                >
                  Add New Code
                </Button>
              </Col>
            </Row>
          </Modal.Footer>
        </>
      )
    }
  }
  return (
    <Modal show={show} centered onHide={toggle} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Outside Codes</Modal.Title>
      </Modal.Header>
      {handleBody()}
    </Modal>
  )
}

const mapState = (state) => {
  return {
    cart: state.cart.data,
    user: state.user,
    allUsers: state.admin.allUsers,
  }
}

const mapDispatch = (dispatch) => {
  return {
    addToCart(body) {
      dispatch(addToCartThunk(body))
    },
  }
}

export default connect(mapState, mapDispatch)(AddOutsideConverterToCart)
