import axios from 'axios'
import history from '../history'
import { setAuthCookie, removeAuthCookie } from '../utils/cookies'
import { apiCaller } from '../utils/apiCaller'
import { fetchAllUsersThunk } from './admin'

/**
 * ACTION TYPES
 */
const GET_USER = 'GET_USER'
const REMOVE_USER = 'REMOVE_USER'

/**
 * INITIAL STATE
 */
const defaultUser = {}

/**
 * ACTION CREATORS
 */
const getUser = (user) => ({ type: GET_USER, user })
const removeUser = () => ({ type: REMOVE_USER })

/**
 * THUNK CREATORS
 */
export const me = () => async (dispatch) => {
  try {
    const res = await apiCaller('auth/me', 'get', {})
    dispatch(getUser(res.data.user || defaultUser))
  } catch (err) {
    console.error(err)
  }
}

export const login = (body) => async (dispatch) => {
  try {
    const { data } = await apiCaller(`auth/login`, 'post', body)
    const user = data.user
    const token = data.token
    setAuthCookie(user._id, token)
    dispatch(me())
    history.push('/dashboard')
  } catch (authError) {
    return dispatch(getUser({ error: authError }))
  }
}

export const signUp = (body) => async (dispatch) => {
  let res
  try {
    res = await apiCaller(`auth/signup`, 'post', body)
    dispatch(fetchAllUsersThunk())
    history.push('/admin/view/users')
  } catch (authError) {
    console.log('Sign up err:', authError)
  }

  try {
    history.push('/admin/view/users')
  } catch (dispatchOrHistoryErr) {
    console.error(dispatchOrHistoryErr)
  }
}

export const logout = () => async (dispatch) => {
  try {
    await apiCaller('auth/logout')
    removeAuthCookie()
    dispatch(removeUser())
    history.push('/login')
  } catch (err) {
    console.error(err)
  }
}

/**
 * REDUCER
 */
export default function (state = defaultUser, action) {
  switch (action.type) {
    case GET_USER:
      return action.user
    case REMOVE_USER:
      return defaultUser
    default:
      return state
  }
}
