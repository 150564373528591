import React from 'react'
// import {Modal} from 'semantic-ui-react'
import {Modal, Button, Row, Col, Form} from 'react-bootstrap'
import {Formik} from 'formik'
import * as yup from 'yup' // for everything

const schema = yup.object({
  //   price: yup.number().required('Required'),
})

const EditCartPriceModal = ({show, toggle, editPrice, item, cart}) => {
  return (
    <Modal show={show} centered onHide={toggle}>
      <Modal.Header closeButton>
        <Modal.Title>The Current price is: ${item.price}</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={{
          price: item.price,
        }}
        // enableReinitialize={true}
        validationSchema={schema}
        onSubmit={async (values, formikBag) => {}}
      >
        {({
          handleSubmit,
          submitCount,
          handleChange,
          handleBlur,
          setFieldValue,
          values,
          touched,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mt-3">
              <Col xs="6">
                <Form.Label style={{fontWeight: '600'}}>Edit Price</Form.Label>
                <Form.Control
                  type="text"
                  name="price"
                  value={values.price}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  isValid={touched.price && !errors.price}
                  isInvalid={(submitCount || touched.price) && !!errors.price}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.price}
                </Form.Control.Feedback>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs="12">
                <Button
                  block
                  onClick={() => editPrice({item, price: values.price, cart})}
                  variant="success"
                >
                  Edit Price
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default EditCartPriceModal
