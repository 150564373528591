import React, { useState } from 'react'
import { useFormik } from 'formik'
import { connect, ConnectedProps } from 'react-redux'
import { RootState, AppDispatch } from '../../store/'
import { Container, Row, Col, Form, Card, Button, FloatingLabel } from 'react-bootstrap'
import * as yup from 'yup'
import { startCase } from 'lodash'
import sanitizeHtml from 'sanitize-html'

const partnerCustomerSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup.string().email('Invalid email address').required('Email is required'),
  margin: yup
    .number()
    .required('Margin is required')
    .min(0, 'Allowed minimum is 0')
    .max(99, 'Allowed maximum is 99'),
})

interface PartnerCustomerFormProps {
  createCart: (body: any) => void
}

const PartnerCustomerForm = ({ createCart }: PartnerCustomerFormProps) => {
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      margin: '',
    },
    validationSchema: partnerCustomerSchema,
    onSubmit: (values) => {
      const { firstName, lastName, email, margin } = values

      const body = {
        firstName: startCase(firstName),
        lastName: startCase(lastName),
        email: sanitizeHtml(email).toLowerCase(),
        margin: Number(margin),
      }

      createCart(body)
    },
  })

  const disableSubmit =
    Object.keys(formik.errors).length > 0 || Object.keys(formik.touched).length === 0

  return (
    <Container fluid>
      <Card className="mt-3">
        <Card.Header>
          <h3>Enter Customer</h3>
        </Card.Header>
        <Card.Body>
          <>
            <Form noValidate onSubmit={formik.handleSubmit}>
              <Row>
                <Col xs={12} sm={6}>
                  <Form.Group>
                    <FloatingLabel label="First name" className="mt-3">
                      <Form.Control
                        type="text"
                        name="firstName"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.firstName}
                        placeholder="First name"
                        isValid={formik.touched.firstName && !formik.errors.firstName}
                      />
                    </FloatingLabel>

                    {formik.touched.firstName && (
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.firstName}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Form.Group>
                    <FloatingLabel label="Last name" className="mt-3">
                      <Form.Control
                        type="text"
                        name="lastName"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.lastName}
                        placeholder="Last name"
                        isValid={formik.touched.lastName && !formik.errors.lastName}
                      />
                    </FloatingLabel>
                    {formik.touched.lastName && (
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.lastName}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Form.Group>
                    <FloatingLabel label="Email" className="mt-3">
                      <Form.Control
                        type="text"
                        name="email"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        placeholder="Email"
                        isValid={formik.touched.email && !formik.errors.email}
                      />
                    </FloatingLabel>
                    {formik.touched.email && (
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.email}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Form.Group>
                    <FloatingLabel label="Margin" className="mt-3">
                      <Form.Control
                        type="number"
                        name="margin"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.margin}
                        placeholder="margin"
                        isValid={formik.touched.margin && !formik.errors.margin}
                      />
                    </FloatingLabel>
                    <Form.Text id="marginHelpBlock" muted>
                      Must be between 0 and 100
                    </Form.Text>
                    {formik.touched.margin && (
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.margin}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </Form>
            <Row className="mt-5 d-flex justify-content-around">
              <Col xs={6} sm={4}>
                <Button
                  disabled={disableSubmit}
                  onClick={() => formik.submitForm()}
                  style={{ width: `100%` }}
                >
                  Confirm Details and Create Cart
                </Button>
              </Col>
            </Row>
          </>
        </Card.Body>
      </Card>
    </Container>
  )
}

const mapStateToProps = (state: RootState) => ({})

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {}
}

// Merge the Redux props
const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(PartnerCustomerForm)
