import { IBusiness } from './business'
export interface IUser {
  _id: string
  email: string
  firstName: string
  lastName: string
  isBuyer: boolean
  isAdmin: boolean
  isGuest: boolean
  isCustomer: boolean
  isPartner: boolean
  margin: number
  platinumMargin: number
  palladiumMargin: number
  rhodiumMargin: number
  photo: string
  location: string
  killed: boolean
  dateAdded: Date
  canSeeInvoiceData: boolean
  streetAddress: string
  streetAddress2?: string
  city: string
  state: string
  postalCode: string
  business?: IBusiness
  fingerprint?: string
  license?: string // deprecated in favor of licenseDetails, will remove in the future
  licenseDetails: {
    licenseNumber: string
    licenseExpirationDate: string
    licenseIssueDate: string
    licenseState: string
    gender: string
    dob: string
    height: string
    eyeColor: string
    licensePhoto: string
  }
}

export const locations = [
  { text: 'Rhode Island', value: 'rhodeIsland' },
  { text: 'Minnesota', value: 'minnesota' },
  { text: 'Florida', value: 'florida' },
  { text: 'Arizona', value: 'arizona' },
  { text: 'Chicago', value: 'chicago' },
  { text: 'Brooklyn', value: 'brooklyn' },
  { text: 'Mississippi', value: 'mississippi' },
]

export const permissions = [
  { text: 'Guest', value: 'guest' },
  { text: 'Customer', value: 'customer' },
  { text: 'Buyer', value: 'buyer' },
  { text: 'Admin', value: 'admin' },
  { text: 'Partner', value: 'partner' },
]

export const buyerRoles = ['buyer', 'admin', 'partner']
