import { format } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'

export function convertJsonToCsv(jsonData, excludeProperties = []) {
  // Function to flatten nested objects
  function flattenObject(obj, prefix = '') {
    const flattened = {}
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const propName = prefix ? `${prefix}.${key}` : key
        if (
          typeof obj[key] === 'object' &&
          obj[key] !== null &&
          !excludeProperties.includes(propName) // Check if the property path should be excluded
        ) {
          if (key === 'customer' && obj[key].hasOwnProperty('email')) {
            flattened[`${propName}.email`] = obj[key].email
          } else if (key === 'createdBy') {
            if (obj[key].hasOwnProperty('email')) {
              flattened[`${propName}.email`] = obj[key].email
            }
            if (obj[key].hasOwnProperty('location')) {
              flattened[`location`] = obj[key].location
            }
          } else {
            Object.assign(flattened, flattenObject(obj[key], propName))
          }
        } else {
          flattened[propName] = obj[key]
        }
      }
    }
    return flattened
  }

  // Flatten the JSON data
  const flattenedData = jsonData.map((item) => flattenObject(item))

  // Extract unique keys (column names) from the flattened data
  const keys = Array.from(new Set(flattenedData.flatMap((item) => Object.keys(item))))

  // Remove excluded properties from the keys
  const filteredKeys = keys.filter(
    (key) => !excludeProperties.some((path) => key.startsWith(path)) // Check if any excluded property path matches the key
  )

  // Create the header row of the CSV using the filtered keys
  const header = filteredKeys.join(',')

  // Create the data rows of the CSV
  const rows = flattenedData.map((item) => {
    // Extract the values of each object in the flattened data
    const values = filteredKeys.map((key) => {
      let value = item[key]
      // Convert boolean values, number 0, and dates to strings
      if (typeof value === 'boolean' || value === 0) {
        value = String(value)
      } else if (key === 'dateAdded') {
        const date = zonedTimeToUtc(value, 'America/New_York')
        value = format(date, 'P', { timeZone: 'America/New_York' })
      }
      return value || ''
    })

    // Convert the values to a comma-separated string
    return values.join(',')
  })

  // Combine the header and rows into a single CSV content
  const csvContent = [header, ...rows].join('\n')

  return csvContent
}

export const profitabilityJsonToCsv = (jsonData, excludeProperties = []) => {
  // Function to flatten nested objects
  function flattenObject(obj, prefix = '') {
    const flattened = {}
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const propName = prefix ? `${prefix}.${key}` : key
        if (
          typeof obj[key] === 'object' &&
          obj[key] !== null &&
          !excludeProperties.includes(propName) // Check if the property path should be excluded
        ) {
          if (key === 'customer' && obj[key].hasOwnProperty('email')) {
            flattened[`${propName}.email`] = obj[key].email
          } else if (key === 'createdBy') {
            if (obj[key].hasOwnProperty('email')) {
              flattened[`${propName}.email`] = obj[key].email
            }
            if (obj[key].hasOwnProperty('location')) {
              flattened[`location`] = obj[key].location
            }
          } else {
            Object.assign(flattened, flattenObject(obj[key], propName))
          }
        } else {
          flattened[propName] = obj[key]
        }
      }
    }
    return flattened
  }

  // Flatten the JSON data
  const flattenedData = jsonData.map((item) => flattenObject(item))

  // Extract unique keys (column names) from the flattened data
  const keys = Array.from(new Set(flattenedData.flatMap((item) => Object.keys(item))))

  // Remove excluded properties from the keys
  const filteredKeys = keys.filter((key) => !excludeProperties.some((path) => key.startsWith(path)))

  // Create the header row of the CSV using the filtered keys
  const header = filteredKeys.join(',')

  // Create the data rows of the CSV
  let rows = flattenedData.map((item) => {
    // Extract the values of each object in the flattened data
    const values = filteredKeys.map((key) => {
      let value = item[key]
      // Convert boolean values, number 0, and dates to strings
      if (typeof value === 'boolean' || value === 0) {
        value = String(value)
      } else if (key === 'dateAdded') {
        const date = zonedTimeToUtc(value, 'America/New_York')
        value = format(date, 'P', { timeZone: 'America/New_York' })
      }
      return value || ''
    })
    // Convert the values to a comma-separated string
    return values.join(',')
  })

  // Separate rows for invoice credits
  jsonData.forEach((item) => {
    rows.push(item.invoiceCredit.toString())
  })

  // Combine the header and rows into a single CSV content
  const csvContent = [header, ...rows].join('\n')

  return csvContent
}
