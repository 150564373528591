import React, { useState, useEffect } from 'react'
import { Modal, Button, Form, Image } from 'react-bootstrap'
import { isMobile } from '../util/utils'

interface FingerprintModalProps {
  show: boolean
  onHide: () => void
  setFingerprintUpdated: (updated: boolean) => void
  onUpload: (fingerprint: string) => void
}

const FingerprintModal: React.FC<FingerprintModalProps> = ({
  show,
  onHide,
  onUpload,
  setFingerprintUpdated,
}) => {
  const [fingerprint, setFingerprint] = useState<string | null>(null)
  const [previewUrl, setPreviewUrl] = useState<string | null>(null)
  const [isMobileDevice, setIsMobileDevice] = useState<boolean>(isMobile())

  useEffect(() => {
    setIsMobileDevice(isMobile())
  }, [])

  const handlePictureChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null

    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setPreviewUrl(reader.result as string)
        setFingerprint(reader.result as string)
      }
      reader.readAsDataURL(file)
      setFingerprintUpdated(true)
    }
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    if (fingerprint) {
      onUpload(fingerprint)
    }
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          {isMobileDevice
            ? 'Take or Upload a Picture of the Customer Fingerprint'
            : 'Upload a Picture of the Customer Fingerprint'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formFile">
            <Form.Label>Choose a picture</Form.Label>
            <Form.Control
              type="file"
              accept="image/*"
              onChange={handlePictureChange}
              capture={isMobileDevice ? undefined : undefined} // On mobile, camera is one option by default
            />
          </Form.Group>

          {isMobileDevice && (
            <Form.Group controlId="formFile">
              <Form.Label>Take a picture</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                onChange={handlePictureChange}
                capture={'environment'} // Use camera on mobile devices
                size="lg"
              />
            </Form.Group>
          )}

          {previewUrl && (
            <div className="my-3 text-center">
              <div style={{ fontSize: '12px', marginRight: '16px' }}>Existing fingerprint:</div>
              <Image src={previewUrl} alt="Image preview" thumbnail width={300} />
            </div>
          )}

          <Button
            style={{ marginTop: '12px' }}
            variant="primary"
            type="submit"
            disabled={!fingerprint}
          >
            {isMobileDevice ? 'Upload Picture' : 'Submit'}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default FingerprintModal
