import React from 'react'
// import {Modal} from 'semantic-ui-react'
import {Modal, Button} from 'react-bootstrap'

const AbandonCartModal = ({show, toggle, abandonCart}) => {
  return (
    <Modal show={show} centered>
      <Modal.Header>Are you sure you want to abandon your cart?</Modal.Header>
      <Modal.Body>
        Abandoning your cart will remove all items and references
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={toggle} variant="success">
          Close
        </Button>
        <Button onClick={abandonCart} variant="danger">
          Abandon
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default AbandonCartModal
