import React from 'react'
import { useFormik } from 'formik'
import { Form, FloatingLabel, Row, Col, Modal, Button } from 'react-bootstrap'
import { states, HeightForm, licenseSchema, formikLicenseTouched } from './util'

interface AddLicenseDetailsModalProps {
  show: boolean
  initialValues?: {
    licenseNumber: string
    licenseExpirationDate: string
    licenseIssueDate: string
    licenseState: string
    heightFeet: string
    heightInches: string
    eyeColor: string
    gender: string
    dob: string
  }
  setShowLicenseDetailsModal: (flag: boolean) => void
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  parentFormik: any
}

const AddLicenseDetailsModal = ({
  show,
  initialValues,
  setShowLicenseDetailsModal,
  setFieldValue,
  parentFormik,
}: AddLicenseDetailsModalProps) => {
  const formik = useFormik({
    initialValues: {
      licenseNumber: initialValues?.licenseNumber || '',
      licenseExpirationDate: initialValues?.licenseExpirationDate || '',
      licenseIssueDate: initialValues?.licenseIssueDate || '',
      licenseState: initialValues?.licenseState || '',
      heightFeet: initialValues?.heightFeet || '',
      heightInches: initialValues?.heightInches || '',
      eyeColor: initialValues?.eyeColor || '',
      gender: initialValues?.gender || '',
      dob: initialValues?.dob || '',
    },
    validationSchema: licenseSchema,
    onSubmit: async (values) => {
      // Handle license details submission
      if (formikLicenseTouched(formik)) {
        setFieldValue('licenseNumber', values.licenseNumber)
        setFieldValue('licenseExpirationDate', values.licenseExpirationDate)
        setFieldValue('licenseIssueDate', values.licenseIssueDate)
        setFieldValue('licenseState', values.licenseState)
        setFieldValue('heightFeet', values.heightFeet)
        setFieldValue('heightInches', values.heightInches)
        setFieldValue('eyeColor', values.eyeColor)
        setFieldValue('gender', values.gender)
        setFieldValue('dob', values.dob)
        await formik.validateForm()
        await parentFormik.validateForm()
      }
      setShowLicenseDetailsModal(false)
    },
  })

  return (
    <Modal show={show} centered>
      <Modal.Header>Add License Details</Modal.Header>
      <Modal.Body>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Row>
            <Col xs={12} sm={6}>
              <Form.Group>
                <FloatingLabel label="ID Number" className="mt-3">
                  <Form.Control
                    type="text"
                    name="licenseNumber"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.licenseNumber}
                    placeholder="ID Number"
                    isValid={formik.touched.licenseNumber && !formik.errors.licenseNumber}
                  />
                </FloatingLabel>
                {formik.touched.licenseNumber && (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.licenseNumber}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group>
                <FloatingLabel label="State" className="mt-3">
                  <Form.Control
                    id="state"
                    name="licenseState"
                    as="select"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.licenseState}
                    isValid={formik.touched.licenseState && !formik.errors.licenseState}
                  >
                    <option key="placeholder" value="">
                      -
                    </option>
                    {states.map((p, idx) => {
                      return (
                        <option key={p.value} value={p.value}>
                          {p.text}
                        </option>
                      )
                    })}
                  </Form.Control>
                </FloatingLabel>
                {formik.touched.licenseState && (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.licenseState}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <HeightForm formik={formik} />

              <Form.Group>
                <FloatingLabel label="Eye Color" className="mt-3">
                  <Form.Control
                    type="text"
                    name="eyeColor"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.eyeColor}
                    placeholder="Eye Color"
                    isValid={formik.touched.eyeColor && !formik.errors.eyeColor}
                  />
                </FloatingLabel>
                {formik.touched.eyeColor && (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.eyeColor}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>

            <Col xs={12} sm={6}>
              <Form.Group>
                <FloatingLabel label="Gender" className="mt-3">
                  <Form.Control
                    as="select"
                    name="gender"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.gender}
                    isValid={formik.touched.gender && !formik.errors.gender}
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </Form.Control>
                </FloatingLabel>
                {formik.touched.gender && (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.gender}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group>
                <FloatingLabel label="Expiration Date" className="mt-3">
                  <Form.Control
                    type="date"
                    name="licenseExpirationDate"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.licenseExpirationDate}
                    placeholder="Expiration Date"
                    isValid={
                      formik.touched.licenseExpirationDate && !formik.errors.licenseExpirationDate
                    }
                  />
                </FloatingLabel>
                {formik.touched.licenseExpirationDate && (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.licenseExpirationDate}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group>
                <FloatingLabel label="Issue Date" className="mt-3">
                  <Form.Control
                    type="date"
                    name="licenseIssueDate"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.licenseIssueDate}
                    placeholder="Issue Date"
                    isValid={formik.touched.licenseIssueDate && !formik.errors.licenseIssueDate}
                  />
                </FloatingLabel>
                {formik.touched.licenseIssueDate && (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.licenseIssueDate}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group>
                <FloatingLabel label="Date of Birth" className="mt-3">
                  <Form.Control
                    type="date"
                    name="dob"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.dob}
                    placeholder="Date of Birth"
                    isValid={formik.touched.dob && !formik.errors.dob}
                  />
                </FloatingLabel>
                {formik.touched.dob && (
                  <Form.Control.Feedback type="invalid">{formik.errors.dob}</Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => formik.submitForm()} style={{ width: '100%' }}>
          Add License Details
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default AddLicenseDetailsModal
