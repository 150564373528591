import React from 'react'
import { Link } from 'react-router-dom'
import { Card, Row, Col } from 'react-bootstrap'

import { IBusiness } from 'client/types/business'

interface IndividualBusinessProps {
  business: IBusiness
}

const IndividualBusiness = ({ business }: IndividualBusinessProps) => {
  return (
    <>
      <Col xs="6" sm="4">
        <Card.Body>
          <Row style={{ display: 'flex', alignItems: 'center' }}>
            <Col>
              <div className="mb-1">
                <h3>{business.name}</h3>
              </div>
              <div>Platinum Offset: {(business.platinumOffset * 100).toFixed(0)}%</div>
              <div>Palladium Offset: {(business.palladiumOffset * 100).toFixed(0)}%</div>
              <div>Rhodium Offset: {(business.rhodiumOffset * 100).toFixed(0)}%</div>
              <div>Users: {business.userCount}</div>
            </Col>
            <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Row>
                <Col style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
                  <Link
                    to={`/admin/edit/business/${business._id}`}
                    className="ui button edit-user-btn blue"
                  >
                    Edit
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
        <hr />
      </Col>
    </>
  )
}

export default IndividualBusiness
