import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Card, Alert, Form } from 'react-bootstrap'
import { Trash } from 'react-bootstrap-icons'
import { blueLight } from '../../style/colors'
import moment from 'moment'
import { calculateTotalCost, calculateTotalPieces } from '../util/utils'

interface CartSummaryProps {
  cart: any
  setShowAbandonCartModal: (value: boolean) => void
  updatePayment: () => void
  setShowCreditCartModal: (value: boolean) => void
}

const CartSummary = ({
  cart,
  setShowAbandonCartModal,
  updatePayment,
  setShowCreditCartModal,
}: CartSummaryProps) => {
  return (
    <Card>
      <Card.Header style={{ fontWeight: 'bold' }}>
        <Row>
          <Col>Cart Summary</Col>
          <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Trash
              color="red"
              style={{ cursor: 'pointer' }}
              onClick={() => setShowAbandonCartModal(true)}
            />
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <div>
              <strong>Customer:</strong> {`${cart.customer.email}`}
            </div>
            <div>
              <strong>Buyer:</strong> {`${cart.createdBy.email}`}
            </div>
            <hr />
            <div>
              <strong>Date:</strong> {moment().format('llll')}
            </div>
            <div>
              <strong>Cart Id:</strong> {`${cart._id}`}
            </div>
            <hr />
            <div>
              <strong>Total Pieces:</strong> {calculateTotalPieces(cart.items)}
            </div>
            <div>
              <strong>Credit:</strong>{' '}
              {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
              }).format(cart.credit)}
            </div>
            <div>
              <strong>Cart Total:</strong> {calculateTotalCost(cart.items, cart.credit)}
            </div>
            <hr />
            <div>
              <strong>Payment Method:</strong>{' '}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                Pay with Check
                <Form.Check
                  type="switch"
                  id={cart && cart._id}
                  defaultChecked={cart.payWithCheck}
                  onChange={() => updatePayment()}
                />
              </div>
            </div>
            {cart.payWithCheck && (
              <div className="mt-3">
                <Alert>
                  <p>Customer paid with Check</p>
                </Alert>
              </div>
            )}
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer>
        <Row>
          <Col
            style={{
              fontWeight: 'bold',
              display: 'flex',
              color: `#21ba45 !important`,
              cursor: 'pointer',
            }}
          >
            {Boolean(cart.items.length) && (
              <div
                style={{
                  fontWeight: 'bold',
                  color: '#21ba45',
                  cursor: 'pointer',
                }}
                onClick={() => setShowCreditCartModal(true)}
              >
                Add Credit
              </div>
            )}
          </Col>
          <Col
            style={{
              fontWeight: 'bold',
              display: 'flex',
              justifyContent: 'flex-end',
              color: `${blueLight}`,
              cursor: 'pointer',
            }}
          >
            {Boolean(cart.items.length) && (
              <Link to={`/admin/checkout/${cart._id}`}>Begin Checkout</Link>
            )}
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  )
}

export default CartSummary
