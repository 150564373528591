import React from 'react'
import { Container, Col, Row, Form, Card, Button } from 'react-bootstrap'
import { useFormik } from 'formik'
import * as yup from 'yup' // for everything
import { connect } from 'react-redux'
import { RootState, AppDispatch, updatePriceControls, resetPriceControlsThunk } from '../store'
import { IPrice } from 'client/types/price'

const schema = yup.object({
  rhodium: yup.string().required('Required').typeError('Must be a number'),
  palladium: yup.string().required('Required').typeError('Must be a number'),
  platinum: yup.string().required('Required').typeError('Must be a number'),
})

const filterPrice = (metal: string, prices: IPrice[]) => {
  let returnVal = ''
  prices.forEach((price) => {
    if (metal === 'rh' && price.metal === 'Rhodium') {
      returnVal = price.bid
    } else if (metal === 'pd' && price.metal === 'Palladium') {
      returnVal = price.bid
    } else if (metal === 'pt' && price.metal === 'Platinum') {
      returnVal = price.bid
    }
  })
  return returnVal
}

type AdminControlsProps = {
  prices: IPrice[]
  updatePriceControls: (body: any) => void
  resetPriceControls: () => void
}

const AdminControls = ({ prices, updatePriceControls, resetPriceControls }: AdminControlsProps) => {
  const pricesLoaded = prices?.length > 0 && prices[0]?.bid !== ''

  if (!pricesLoaded) return <div />

  const formik = useFormik({
    initialValues: {
      rhodium: filterPrice('rh', prices),
      platinum: filterPrice('pt', prices),
      palladium: filterPrice('pd', prices),
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: (values) => {
      updatePriceControls(values)
    },
  })

  const resetPrices = () => {
    resetPriceControls()
  }

  return (
    <Container fluid>
      <Card className="mt-3">
        <Card.Header>
          <h3>Admin Controls</h3>
        </Card.Header>
        <Card.Body>
          <Form noValidate onSubmit={formik.handleSubmit}>
            <Row>
              <Col xs={12} sm={4} style={{ margin: 0 }}>
                <Form.Label style={{ fontWeight: '600' }}>Set Platinum</Form.Label>
                <Form.Control
                  type="text"
                  name="platinum"
                  value={formik.values.platinum}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  isValid={formik.touched.platinum && !formik.errors.platinum}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.platinum}
                </Form.Control.Feedback>
              </Col>

              <Col xs={12} sm={4} style={{ margin: 0 }}>
                <Form.Label style={{ fontWeight: '600' }}>Set Palladium</Form.Label>
                <Form.Control
                  type="text"
                  name="palladium"
                  value={formik.values.palladium}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  isValid={formik.touched.palladium && !formik.errors.palladium}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.palladium}
                </Form.Control.Feedback>
              </Col>

              <Col xs={12} sm={4} style={{ margin: 0 }}>
                <Form.Label style={{ fontWeight: '600' }}>Set Rhodium</Form.Label>
                <Form.Control
                  type="text"
                  name="rhodium"
                  value={formik.values.rhodium}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  isValid={formik.touched.rhodium && !formik.errors.rhodium}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.rhodium}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Form>
          <Row className="mt-3">
            <Col xs={6} sm={4}>
              <Button onClick={() => formik.submitForm()} style={{ width: `100%` }}>
                Update Prices
              </Button>
            </Col>
            <Col xs={6} sm={4}>
              <Button variant="secondary" onClick={resetPrices} style={{ width: `100%` }}>
                Reset to Market Prices
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  )
}

const mapState = (state: RootState) => {
  return {
    prices: state.prices.prices,
    hedge: state.hedge.data,
    cart: state.cart.data,
  }
}

const mapDispatch = (dispatch: AppDispatch) => {
  return {
    updatePriceControls(body) {
      dispatch(updatePriceControls(body))
    },
    resetPriceControls() {
      dispatch(resetPriceControlsThunk())
    },
  }
}

const Connected = connect(mapState, mapDispatch)(AdminControls)

export default Connected
