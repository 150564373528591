import React, { useEffect } from 'react'
import { Container, Row, Col, Alert, Form, Card, Button } from 'react-bootstrap'
import { fetchKilledConverters } from '../store'

import { connect } from 'react-redux'
import ConverterList from './authorized-user/ConverterList'

const KilledConverters = ({ fetchKilledConverters, killedConverters }) => {
  useEffect(() => {
    fetchKilledConverters()
  }, [])

  return (
    <Container fluid>
      <Card className="mt-3">
        <Card.Header>
          <h3>Killed Converters</h3>
        </Card.Header>
        <Card.Body>
          <ConverterList converters={killedConverters} />
        </Card.Body>
      </Card>
    </Container>
  )
}

const mapState = (state) => {
  return {
    killedConverters: state.admin.killedConverters,
  }
}

const mapDispatch = (dispatch) => {
  return {
    fetchKilledConverters() {
      dispatch(fetchKilledConverters())
    },
  }
}

export default connect(mapState, mapDispatch)(KilledConverters)
