import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { IUser } from '../../../types/user'
import { FloatingLabel, Form } from 'react-bootstrap'
import { useFormik } from 'formik'
import { passwordSchema } from './util'

interface DeleteUserModalProps {
  showUpdatePasswordModal: boolean
  updatePassword: (body: { userId: string; newPassword: string }) => void
  selectedUser: IUser
  setShowUpdatePasswordModal: (arg: boolean) => void
}

const DeleteUserModal = ({
  showUpdatePasswordModal,
  updatePassword,
  selectedUser,
  setShowUpdatePasswordModal,
}: DeleteUserModalProps) => {
  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: passwordSchema,
    onSubmit: (values) => {
      const { password } = values

      const body = {
        userId: selectedUser._id,
        newPassword: password,
      }
      updatePassword(body)
      setShowUpdatePasswordModal(false)
    },
  })

  return (
    <Modal show={showUpdatePasswordModal} centered>
      <Modal.Header>Update Password</Modal.Header>
      <Modal.Body>
        <FloatingLabel label="Password" className="mt-3">
          <Form.Control
            type="password"
            name="password"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.password}
            placeholder="password"
            isValid={formik.touched.password && !formik.errors.password}
          />
        </FloatingLabel>
        <Form.Text id="passwordHelpBlock" muted>
          Must be 6+ characters & contain 1 upper case, 1 lower case, and 1 number
        </Form.Text>
        {formik.touched.password && (
          <Form.Control.Feedback type="invalid">{formik.errors.password}</Form.Control.Feedback>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setShowUpdatePasswordModal(false)} variant="danger">
          Close
        </Button>
        <Button onClick={() => formik.submitForm()} variant="success">
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteUserModal
