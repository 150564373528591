import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Dropdown } from 'semantic-ui-react'
import { toast } from 'react-toastify'

const EditConverter = (props) => {
  const {
    id,
    disabled,
    weightError,
    ppmPTError,
    ppmPDError,
    ppmRHError,
    newMake,
    serialNumberError,
    secondarySerialNumber,
  } = props

  let add
  let edit
  if (props.type === 'Add') {
    add = true
    edit = false
  } else {
    edit = true
    add = false
  }
  let newMakeInput
  if (newMake !== false) {
    newMakeInput = true
  } else {
    newMakeInput = false
  }
  let { photo, imagePreviewUrl } = props
  let imagePreview = null
  if (imagePreviewUrl) {
    imagePreview = <img className="edit-cat-img" src={imagePreviewUrl} />
  } else {
    imagePreview = <img className="edit-cat-img" src={photo} />
  }

  let option = props.options !== undefined

  return (
    <div className="site-container">
      <Link to="/dashboard" className="width ui button dashboard-add-converter-btn">
        Back to Dashboard
      </Link>
      {edit && (
        <div className="margin-top full-width">
          <Link
            to={`/converter/${props.id}`}
            className="ui button blue dashboard-add-converter-btn"
          >
            {`View ${props.serialNumber}`}
          </Link>
        </div>
      )}
      <div className="selected-info-container">
        <div className="selected-info-top-row">
          <div className="bold">{props.type}</div>
          <div>
            {props.serialNumber}{' '}
            {props.secondarySerialNumber ? `- ${props.secondarySerialNumber}` : ''}
          </div>
        </div>
        <div className="edit-info-body">
          <div className="edit-info-body-left">
            {edit && (
              <div className="edit-info-body-price">
                <div className="bold green">{`$${props.currentPrice}`}</div>
              </div>
            )}
            {serialNumberError && (
              <div id="edit-input-error" className="ui mini left labeled edit-input">
                <div className="ui red pointing below basic label">{serialNumberError}</div>
              </div>
            )}
            <div className="ui mini left labeled input edit-input">
              <div className="ui basic label label">Serial Number </div>
              <input
                className="ui fluid"
                type="text"
                value={props.serialNumber}
                onChange={props.handleInputChange}
                name="serialNumber"
              />
            </div>
            <div className="ui mini left labeled input edit-input">
              <div className="ui basic label label">Secondary Serial </div>
              <input
                className="ui fluid"
                type="text"
                value={props.secondarySerialNumber}
                onChange={props.handleInputChange}
                name="secondarySerialNumber"
              />
            </div>
            {option && (
              <Dropdown
                className="edit-input"
                placeholder={props.make}
                fluid
                selection
                options={props.options}
                onChange={props.handleInputChange}
              />
            )}
            {newMakeInput && (
              <div className={`ui mini left labeled  input edit-input ${weightError}`}>
                <div className="ui basic label label">New Make </div>

                <input
                  className="ui fluid"
                  type="text"
                  value={props.newMake}
                  onChange={props.handleInputChange}
                  name="newMake"
                />
              </div>
            )}
            <div className={`ui mini left labeled  input edit-input ${weightError}`}>
              <div className="ui basic label label">Weight </div>

              <input
                className="ui fluid"
                type="text"
                value={props.weight}
                onChange={props.handleInputChange}
                name="weight"
              />
            </div>

            <div className="edit-input-admin">
              <div className={`ui mini left labeled input edit-input ${ppmPTError}`}>
                <div className="ui basic label label">PT PPM </div>
                <input
                  className="ui fluid"
                  type="text"
                  value={props.ppmPT}
                  onChange={props.handleInputChange}
                  name="ppmPT"
                />
              </div>

              <div className={`ui mini left labeled input edit-input ${ppmPDError}`}>
                <div className="ui basic label label">PD PPM </div>
                <input
                  name="ppmPD"
                  className="ui fluid"
                  type="text"
                  value={props.ppmPD}
                  onChange={props.handleInputChange}
                />
              </div>

              <div className={`ui mini left labeled input edit-input ${ppmRHError}`}>
                <div className="ui basic label label">RH PPM </div>
                <input
                  className="ui fluid"
                  type="text"
                  value={props.ppmRH}
                  onChange={props.handleInputChange}
                  name="ppmRH"
                />
              </div>
            </div>

            <button onClick={props.handleSubmit} className={`ui button positive ${disabled}`}>
              {`${props.type} Converter`}
            </button>
            <button
              onClick={() => props.delete(id)}
              className={`ui button negative ${disabled}`}
              style={{ marginTop: `5px` }}
            >
              Remove Converter
            </button>
          </div>
          <div className="selected-info-body-right">
            <div className="edit-img-container">{imagePreview}</div>
            <input
              type="file"
              className="inputfile"
              id="embedpollfileinput"
              accept="image/png, image/jpeg"
              onChange={props.getPhoto}
            />
            <label htmlFor="embedpollfileinput" className="ui blue button">
              <i className="ui upload icon" />
              Upload New Image
            </label>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapState = (state) => {
  return {
    allMakes: state.makes.allMakes,
  }
}

const Connected = connect(mapState)(EditConverter)

export default Connected
