import React from 'react'
import EditConverter from './edit-converter'
import { connect } from 'react-redux'
import {
  fetchSingleConverter,
  removeSingleConverter,
  fetchAllMakes,
  fetchAllConverters,
  postConverter,
} from '../../../store'
import { createMakeOptions } from '../../util/utils'
import { validateWeight, validatePPM, validateSerialNumber } from './utils'
import { toast } from 'react-toastify'

class AddConverter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      serialNumber: '',
      secondarySerialNumber: '',
      make: 'Choose A Make',
      newMake: false,
      weight: '',
      ppmPT: '',
      ppmPD: '',
      ppmRH: '',
      photo: 'https://i.imgur.com/vu1nOBn.png',
      imagePreviewUrl: '',
      type: 'Add',
      options: [],
      serialNumberError: '',
      created: false,
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.getPhoto = this.getPhoto.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    if (this.props.allMakes !== undefined && this.props.allMakes.length) {
      let options = createMakeOptions(this.props.allMakes)
      options[0] = {
        key: 'create new make',
        text: 'Create New Make',
        value: 'create new make',
      }
      this.setState({ options })
    } else {
      this.props.fetchAllMakes()
    }
    if (this.props.allConverters.length === 2) {
      this.props.fetchAllConverters()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.allMakes === undefined && this.props.allMakes !== undefined) {
      let options = createMakeOptions(this.props.allMakes)
      options[0] = {
        key: 'create new make',
        text: 'Create New Make',
        value: 'create new make',
      }
      this.setState({ options })
    }
    if (
      prevProps.allConverters.length !== this.props.allConverters.length &&
      this.props.allConverters.length > 5 &&
      prevProps.allConverters.length > 5
    ) {
      this.setState({ created: true })
      let make
      if (this.state.make !== false) {
        make = this.state.make
      } else {
        make = this.state.newMake
      }
      toast.success(`Success: ${make} - ${this.state.serialNumber} has been created!`)
    }
  }

  handleInputChange(evt) {
    evt.preventDefault()
    let value = event.target.value
    let name = event.target.name
    if (value === undefined) {
      if (evt.target.querySelector('span') !== null) {
        value = evt.target.querySelector('span').innerText
      } else {
        value = evt.target.innerText
      }

      if (value === 'Create New Make') {
        name = 'newMake'
        value = ''
        this.setState({ make: false })
      } else {
        name = 'make'
        this.setState({ newMake: false })
      }
    }
    if (name === 'serialNumber' && this.state.serialNumberError.length) {
      this.setState({ serialNumberError: '' })
    }
    this.setState({ [name]: value })
  }

  getPhoto(e) {
    e.preventDefault()
    let reader = new FileReader()
    let file = e.target.files[0]

    reader.onloadend = () => {
      this.setState({
        imagePreviewUrl: reader.result,
      })
    }
    reader.readAsDataURL(file)
  }

  handleSubmit() {
    const {
      serialNumber,
      weight,
      photo,
      imagePreviewUrl,
      ppmPD,
      ppmPT,
      ppmRH,
      newMake,
      secondarySerialNumber,
    } = this.state

    let converterExists = validateSerialNumber(serialNumber, this.props.allConverters)

    if (converterExists) {
      this.setState({
        serialNumberError: 'A converter with this serial number already exists!',
      })
    } else {
      let make
      if (newMake) {
        make = newMake
      } else {
        make = this.state.make
      }

      const body = {
        serialNumber,
        secondarySerialNumber,
        make,
        weight,
        ppmPD,
        ppmPT,
        ppmRH,
      }

      if (imagePreviewUrl === '') {
        body.photo = photo
      } else {
        body.photo = imagePreviewUrl
      }
      this.setState({
        serialNumberError: '',
      })

      this.props.addConverter(body)
    }
  }

  render() {
    const {
      serialNumber,
      make,
      type,
      weight,
      photo,
      imagePreviewUrl,
      options,
      ppmPT,
      ppmPD,
      ppmRH,
      newMake,
      serialNumberError,
      created,
      secondarySerialNumber,
    } = this.state

    let disabled

    if (
      weight === '' ||
      ppmPT === '' ||
      ppmPD === '' ||
      ppmRH === '' ||
      make === 'Choose A Make' ||
      serialNumber === ''
    ) {
      disabled = 'disabled'
    } else {
      disabled = ''
    }

    let weightValidation = validateWeight(weight)

    let weightError = weightValidation ? '' : 'error'

    let ppmPDValidation = validatePPM(ppmPD)

    let ppmPDError = ppmPDValidation ? '' : 'error'

    let ppmPTValidation = validatePPM(ppmPT)

    let ppmPTError = ppmPTValidation ? '' : 'error'

    let ppmRHValidation = validatePPM(ppmRH)

    let ppmRHError = ppmRHValidation ? '' : 'error'

    if (ppmRHError === 'error' || ppmPDError === 'error' || ppmPTError === 'error') {
      disabled = 'disabled'
    }

    return (
      <EditConverter
        handleInputChange={this.handleInputChange}
        serialNumber={this.state.serialNumber}
        make={this.state.make}
        type={this.state.type}
        weight={this.state.weight}
        photo={this.state.photo}
        imagePreviewUrl={this.state.imagePreviewUrl}
        getPhoto={this.getPhoto}
        handleSubmit={this.handleSubmit}
        options={this.state.options}
        ppmPT={this.state.ppmPT}
        ppmPD={this.state.ppmPD}
        ppmRH={this.state.ppmRH}
        disabled={disabled}
        weightError={weightError}
        ppmPDError={ppmPDError}
        ppmPTError={ppmPTError}
        ppmRHError={ppmRHError}
        newMake={newMake}
        serialNumberError={serialNumberError}
        secondarySerialNumber={secondarySerialNumber}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    selectedConverter: state.converter.selectedConverter,
    allMakes: state.makes.allMakes,
    allConverters: state.converter.allConverters,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSingleConverter: (serialNumber) => dispatch(fetchSingleConverter(serialNumber)),
    removeSelectedConverter: (body) => dispatch(removeSingleConverter(body)),
    fetchAllMakes: () => dispatch(fetchAllMakes()),
    fetchAllConverters: () => dispatch(fetchAllConverters()),
    addConverter: (body) => dispatch(postConverter(body)),
  }
}

const connected = connect(mapStateToProps, mapDispatchToProps)(AddConverter)

export default connected
