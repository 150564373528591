import React, { useState } from 'react'
import { useFormik, FormikProps } from 'formik'
import { connect, ConnectedProps } from 'react-redux'
import { RootState, AppDispatch, postSignUp } from '../../../store'
import { Container, Row, Col, Form, Card, Button } from 'react-bootstrap'
import { startCase } from 'lodash'
import { locations, permissions } from '../../../types/user'
import {
  newUserSchema,
  states,
  AddUserBodyProps,
  HeightForm,
  handleFileUpload,
  handleExtractLicenseInfo,
} from './util'
import { ScaleLoader } from 'react-spinners'
import { Link } from 'react-router-dom'

import sanitizeHtml from 'sanitize-html'

import FormGroup from './FormGroup'

const isProd = process.env.NODE_ENV === 'production'

interface AddUserProps {
  newUserLoading: boolean
  isAdmin: boolean
  isBuyer: boolean
  isPartner: boolean
  addUser: (body: AddUserBodyProps, resetForm: () => void) => void
}

export interface AddUserFormValues {
  firstName: string
  lastName: string
  email: string
  password: string
  margin: number
  platinumMargin: number
  palladiumMargin: number
  rhodiumMargin: number
  permissionLevel: string
  streetAddress: string
  streetAddress2?: string
  city: string
  state: string
  postalCode: string
  location: string
  licensePhoto: string
  licensePhotoBack: string
  licenseNumber: string
  licenseExpirationDate: string
  licenseIssueDate: string
  licenseState: string
  gender: string
  dob: string
  height: string
  eyeColor: string
  fingerprint?: string
  businessLicensePhoto?: string
  businessName?: string
  businessLicenseIssueDate?: string
  businessLicenseExpirationDate?: string
  partnerLogo?: string
}

const AddUser: React.FC<PropsFromRedux> = ({
  isAdmin,
  isBuyer,
  isPartner,
  addUser,
  newUserLoading,
}: AddUserProps) => {
  const [licensePhotoBackFile, setLicensePhotoBackFile] = useState<File | undefined>(undefined)

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      margin: '',
      location: '',
      permissionLevel: isBuyer ? 'guest' : '', //hardcode guest for buyer accounts since only admins can set permission level
      streetAddress: '',
      streetAddress2: '',
      city: '',
      state: '',
      postalCode: '',
      fingerprint: '',
      licensePhoto: '',
      licensePhotoBack: '',
      licenseNumber: '',
      licenseIssueDate: '',
      licenseExpirationDate: '',
      licenseState: '',
      gender: '',
      dob: '',
      heightFeet: '',
      heightInches: '',
      eyeColor: '',
      platinumMargin: '',
      palladiumMargin: '',
      rhodiumMargin: '',
      businessLicensePhoto: '',
      businessName: '',
      businessLicenseIssueDate: '',
      businessLicenseExpirationDate: '',
      partnerLogo: '',
    },
    validationSchema: newUserSchema,
    onSubmit: (values, { resetForm }) => {
      const {
        firstName,
        lastName,
        email,
        password,
        margin,
        location,
        permissionLevel,
        streetAddress,
        streetAddress2,
        city,
        state,
        postalCode,
        licensePhoto,
        licensePhotoBack,
        fingerprint,
        licenseNumber,
        licenseIssueDate,
        licenseExpirationDate,
        licenseState,
        gender,
        dob,
        heightFeet,
        heightInches,
        eyeColor,
        platinumMargin,
        palladiumMargin,
        rhodiumMargin,
        businessLicensePhoto,
        businessName,
        businessLicenseIssueDate,
        businessLicenseExpirationDate,
        partnerLogo,
      } = values

      const body = {
        firstName: startCase(firstName),
        lastName: startCase(lastName),
        email: sanitizeHtml(email).trim().toLowerCase(),
        password,
        margin: Number(margin),
        location,
        isBuyer: permissionLevel === 'buyer',
        isAdmin: permissionLevel === 'admin',
        isGuest: permissionLevel === 'guest',
        isCustomer: permissionLevel === 'customer',
        isPartner: permissionLevel === 'partner',
        streetAddress: startCase(streetAddress.trim()),
        streetAddress2: startCase(streetAddress2.trim()),
        city: startCase(city.trim()),
        state,
        postalCode: postalCode.trim(),
        licensePhoto,
        licensePhotoBack,
        fingerprint,
        licenseNumber,
        licenseIssueDate,
        licenseExpirationDate,
        licenseState,
        gender,
        dob,
        height: `${heightFeet}'-${heightInches}"`,
        eyeColor,
        platinumMargin: Number(platinumMargin),
        palladiumMargin: Number(palladiumMargin),
        rhodiumMargin: Number(rhodiumMargin),
        businessLicensePhoto,
        businessName: startCase(businessName.trim()),
        businessLicenseIssueDate,
        businessLicenseExpirationDate,
        partnerLogo,
      }

      const handleResetForm = () => {
        resetForm()
      }
      addUser(body, handleResetForm)
    },
  })

  const renderContactInfoSection = ({ formik }: { formik: FormikProps<AddUserFormValues> }) => (
    <>
      <h4>Contact Info</h4>
      <Row>
        <Col xs={12} lg={6}>
          <FormGroup label="First name" name="firstName" formik={formik} />
          <FormGroup label="Last name" name="lastName" formik={formik} />
          <FormGroup label="Business Name" name="businessName" formik={formik} />
          <FormGroup label="City" name="city" formik={formik} />
        </Col>
        <Col xs={12} lg={6}>
          <FormGroup label="Street Address" name="streetAddress" formik={formik} />
          <FormGroup label="Street Address 2" name="streetAddress2" formik={formik} />
          <FormGroup label="State" name="state" formik={formik} isSelect selectOptions={states} />
          <FormGroup label="Postal Code" name="postalCode" formik={formik} />
        </Col>
      </Row>
    </>
  )

  const shouldShowMetalMargins = isAdmin || isPartner

  const renderAccountSettingsSection = ({ formik }: { formik: FormikProps<AddUserFormValues> }) => (
    <>
      <h4>Account Settings</h4>
      <Row>
        <Col xs={12} lg={6}>
          <FormGroup label="Email" name="email" formik={formik} />
          <FormGroup
            label="Password"
            name="password"
            type="password"
            formik={formik}
            helpText="Must be 8+ chars & contain 1 uppercase & 1 number"
          />
          {isAdmin && (
            <FormGroup
              label="Set Permission Level"
              name="permissionLevel"
              formik={formik}
              isSelect
              selectOptions={permissions}
            />
          )}
          {(isAdmin || isBuyer || isPartner) && (
            <FormGroup
              label="AC Location"
              name="location"
              formik={formik}
              isSelect
              selectOptions={locations}
            />
          )}
          {isAdmin && formik.values.permissionLevel === 'partner' && (
            <Col
              style={{
                marginTop: '12px',
                border: '1px solid #ced4da',
                borderRadius: '.25rem',
                padding: '0.5rem .75rem',
                flex: '1', // Ensures both columns are evenly spaced
                marginRight: '8px', // Adds spacing between columns
              }}
            >
              <label
                style={{ marginBottom: '4px', opacity: '.65', fontSize: '12px' }}
                htmlFor="partnerLogo"
              >
                Upload Partner Logo:
              </label>
              <input
                type="file"
                id="partnerLogo"
                name="partnerLogo"
                onChange={(e) => handleFileUpload(formik.setFieldValue, e, 'partnerLogo')}
              />
              {formik.values.partnerLogo && (
                <div
                  className="mt-4"
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  <img height="100" alt="partnerLogo" src={String(formik.values.partnerLogo)} />
                </div>
              )}
              {formik.touched.partnerLogo && (
                <Form.Control.Feedback type="invalid">
                  {formik.errors.partnerLogo}
                </Form.Control.Feedback>
              )}
            </Col>
          )}

          {formik.values.location === 'florida' &&
            ['guest', 'customer'].includes(formik.values.permissionLevel) && (
              <Col
                style={{
                  marginTop: '12px',
                  border: '1px solid #ced4da',
                  borderRadius: '.25rem',
                  padding: '0.5rem .75rem',
                  flex: '1', // Ensures both columns are evenly spaced
                  marginRight: '8px', // Adds spacing between columns
                }}
              >
                <label
                  style={{ marginBottom: '4px', opacity: '.65', fontSize: '12px' }}
                  htmlFor="fingerprint"
                >
                  Upload Fingerprint:
                </label>
                <input
                  type="file"
                  id="fingerprint"
                  name="fingerprint"
                  onChange={(e) => handleFileUpload(formik.setFieldValue, e, 'fingerprint')}
                />
                {formik.values.fingerprint && (
                  <div
                    className="mt-4"
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                  >
                    <img height="100" alt="fingerprint" src={String(formik.values.fingerprint)} />
                  </div>
                )}
                {formik.touched.fingerprint && (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.fingerprint}
                  </Form.Control.Feedback>
                )}
              </Col>
            )}
        </Col>
        <Col xs={12} lg={6}>
          <FormGroup
            label="Account margin"
            name="margin"
            formik={formik}
            helpText="Must be a number at least 1 and under 100"
          />
          {shouldShowMetalMargins && (
            <>
              <FormGroup
                label="Platinum margin"
                name="platinumMargin"
                formik={formik}
                helpText="Must be a number at least 1 and under 100"
              />
              <FormGroup
                label="Palladium margin"
                name="palladiumMargin"
                formik={formik}
                helpText="Must be a number at least 1 and under 100"
              />
              <FormGroup
                label="Rhodium margin"
                name="rhodiumMargin"
                formik={formik}
                helpText="Must be a number at least 1 and under 100"
              />
            </>
          )}
        </Col>
      </Row>
    </>
  )

  const renderComplianceCenterSection = ({
    formik,
  }: {
    formik: FormikProps<AddUserFormValues>
  }) => (
    <>
      <h4>Compliance Center</h4>
      <Row>
        <Col xs={12} lg={6}>
          <Form.Group
            style={{
              marginTop: '12px',
              border: '1px solid #ced4da',
              borderRadius: '.25rem',
              padding: '0.5rem .75rem',
            }}
          >
            <Row>
              <Col lg={6} xs={12}>
                <div>
                  <label
                    style={{ marginBottom: '4px', opacity: '.65', fontSize: '12px' }}
                    htmlFor="licensePhoto"
                  >
                    Upload Driver&apos;s License Front:
                  </label>
                  <input
                    type="file"
                    name="licensePhoto"
                    id="licensePhoto"
                    accept="image*"
                    onChange={(e) => handleFileUpload(formik.setFieldValue, e, 'licensePhoto')}
                  />
                </div>
                <div className="edit-img-container">
                  <img
                    className="edit-img"
                    src={
                      formik.values.licensePhoto
                        ? String(formik.values.licensePhoto)
                        : '/placeholder.png'
                    }
                  />
                </div>
                {formik.touched.licensePhoto && (
                  <Form.Control.Feedback
                    type="invalid"
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {formik.errors.licensePhoto}
                  </Form.Control.Feedback>
                )}
              </Col>
              {!isProd && (
                <Col lg={6} xs={12}>
                  <div>
                    <label
                      style={{ marginBottom: '4px', opacity: '.65', fontSize: '12px' }}
                      htmlFor="licensePhotoBack"
                    >
                      Upload Driver&apos;s License Back:
                    </label>
                    <input
                      type="file"
                      name="licensePhotoBack"
                      id="licensePhotoBack"
                      accept="image*"
                      onChange={(e) => {
                        handleFileUpload(formik.setFieldValue, e, 'licensePhotoBack')
                        setLicensePhotoBackFile(e.target.files?.[0])
                      }}
                    />
                  </div>
                  <div className="edit-img-container">
                    <img
                      className="edit-img"
                      src={
                        formik.values.licensePhotoBack
                          ? String(formik.values.licensePhotoBack)
                          : '/placeholder.png'
                      }
                    />
                  </div>
                  <div
                    style={{
                      marginTop: '10px',
                    }}
                  >
                    <Button onClick={() => handleExtractLicenseInfo(licensePhotoBackFile, formik)}>
                      Extract Info
                    </Button>
                  </div>
                  {formik.touched.licensePhotoBack && (
                    <Form.Control.Feedback
                      type="invalid"
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      {formik.errors.licensePhotoBack}
                    </Form.Control.Feedback>
                  )}
                </Col>
              )}
            </Row>
          </Form.Group>
        </Col>
        <Col xs={12} lg={3}>
          <FormGroup label="License Number" name="licenseNumber" formik={formik} />
          <FormGroup
            label="License State"
            name="licenseState"
            formik={formik}
            isSelect
            selectOptions={states}
          />
          <div className="mt-3">
            <HeightForm formik={formik} />
          </div>
          <FormGroup label="Eye Color" name="eyeColor" formik={formik} />
        </Col>
        <Col xs={12} lg={3}>
          <FormGroup label="Gender" name="gender" formik={formik} />
          <FormGroup label="Issue Date" name="licenseIssueDate" formik={formik} type="date" />
          <FormGroup
            label="Expiration Date"
            name="licenseExpirationDate"
            formik={formik}
            type="date"
          />
          <FormGroup label="Date of Birth" name="dob" formik={formik} type="date" />
        </Col>
      </Row>
      {!isProd && (
        <Row>
          <Col xs={12} lg={6}>
            <Form.Group
              style={{
                marginTop: '12px',
                border: '1px solid #ced4da',
                borderRadius: '.25rem',
                padding: '0.5rem .75rem',
              }}
            >
              <Row>
                <div>
                  <label
                    style={{ marginBottom: '4px', opacity: '.65', fontSize: '12px' }}
                    htmlFor="businessLicensePhoto"
                  >
                    Upload Business License:
                  </label>
                  <input
                    type="file"
                    name="businessLicensePhoto"
                    id="businessLicensePhoto"
                    accept="image*"
                    onChange={(e) =>
                      handleFileUpload(formik.setFieldValue, e, 'businessLicensePhoto')
                    }
                  />
                </div>
                <div className="edit-img-container">
                  <img
                    className="edit-img"
                    src={
                      formik.values.businessLicensePhoto
                        ? String(formik.values.businessLicensePhoto)
                        : '/placeholder.png'
                    }
                  />
                </div>
                {formik.touched.businessLicensePhoto && (
                  <Form.Control.Feedback
                    type="invalid"
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    {formik.errors.businessLicensePhoto}
                  </Form.Control.Feedback>
                )}
              </Row>
            </Form.Group>
          </Col>
          <Col xs={12} lg={3}>
            <FormGroup
              label="Business License Issue Date"
              name="businessLicenseIssueDate"
              formik={formik}
              type="date"
            />
            <FormGroup
              label="Business License Expiration Date"
              name="businessLicenseExpirationDate"
              formik={formik}
              type="date"
            />
          </Col>
        </Row>
      )}
    </>
  )

  if (newUserLoading) {
    return (
      <Row className="mt-3">
        <Col className="d-flex justify-content-center">
          <ScaleLoader color="#36D7B7" height={50} width={10} radius={4} margin={4} />
        </Col>
      </Row>
    )
  }

  return (
    <Container fluid>
      <Row>
        <Col>
          <Link to="/admin/view/users" className="ui button dashboard-add-converter-btn">
            Back To All Users
          </Link>
        </Col>
      </Row>
      <Card className="mt-3">
        <Card.Header>
          <h3>Create User</h3>
        </Card.Header>
        <Card.Body>
          <Form noValidate onSubmit={formik.handleSubmit}>
            <Row>
              <Col xs={12} sm={6} className="mt-3">
                {renderContactInfoSection({ formik })}
              </Col>
              <Col xs={12} sm={6} className="mt-3">
                {renderAccountSettingsSection({ formik })}
              </Col>
              <Col xs={12} className="mt-3">
                {renderComplianceCenterSection({ formik })}
              </Col>
            </Row>
            <Row className="mt-5 d-flex justify-content-around">
              <Col xs={6} sm={4}>
                <Button
                  onClick={() => {
                    formik.submitForm()
                  }}
                  style={{ width: `100%` }}
                >
                  Create User
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  )
}

const mapStateToProps = (state: RootState) => ({
  isAdmin: state.user.isAdmin,
  isBuyer: state.user.isBuyer,
  isPartner: state.user.isPartner,
  newUserLoading: state.admin.newUserLoading,
})

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    addUser: (body, resetForm) => dispatch(postSignUp(body, resetForm)),
  }
}

// Merge the Redux props
const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(AddUser)
