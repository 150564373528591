import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { connect, ConnectedProps } from 'react-redux'
import { RootState, AppDispatch, createBusiness, fetchAllUsersThunk } from '../../../store'
import { Container, Row, Col, Form, Card, Button, FloatingLabel } from 'react-bootstrap'
import { startCase } from 'lodash'
import ScaleLoader from 'react-spinners/ScaleLoader'
import { newBusinessSchema, states, AddBusinessBodyProps } from './AddBusiness.util'
import { IUser } from 'client/types/user'
import AddUsersToBusiness from './AddUsersToBusiness'

export type userToAdd = {
  readonly value: string
  readonly label: string
}

interface AddBusinessProps {
  newBusinessLoading: boolean
  addBusiness: (body: AddBusinessBodyProps, resetForm: () => void) => void
  fetchAllUsers: () => void
  allUsers: IUser[]
}

const AddBusiness: React.FC<PropsFromRedux> = ({
  newBusinessLoading,
  addBusiness,
  fetchAllUsers,
  allUsers,
}: AddBusinessProps) => {
  const [usersToAdd, setUsersToAdd] = useState<userToAdd[]>([])
  const [selectedOption, setSelectedOption] = useState<readonly userToAdd[]>([])

  useEffect(() => {
    if (!usersToAdd.length) {
      fetchAllUsers()
      setUsersToAdd(
        allUsers
          .map((user) => {
            const label = user.business
              ? `${user.firstName} ${user.lastName} - ${user.business.name}`
              : `${user.firstName} ${user.lastName}`
            return {
              value: user._id,
              label,
            }
          })
          .sort((a, b) => a.label.localeCompare(b.label))
      )
    }
  }, [fetchAllUsers, usersToAdd, allUsers])

  const formik = useFormik({
    initialValues: {
      name: '',
      streetAddress: '',
      streetAddress2: '',
      city: '',
      state: '',
      postalCode: '',
      rhodiumOffset: 100,
      platinumOffset: 100,
      palladiumOffset: 100,
    },
    validationSchema: newBusinessSchema,
    onSubmit: (values, { resetForm }) => {
      const {
        name,
        streetAddress,
        streetAddress2,
        city,
        state,
        postalCode,
        rhodiumOffset,
        platinumOffset,
        palladiumOffset,
      } = values

      const body = {
        name,
        streetAddress: startCase(streetAddress.trim()),
        streetAddress2: startCase(streetAddress2.trim()),
        city: startCase(city.trim()),
        state,
        postalCode: postalCode.trim(),
        rhodiumOffset,
        platinumOffset,
        palladiumOffset,
        users: selectedOption.map((user) => user.value),
      }

      const handleResetForm = () => {
        resetForm()
      }
      addBusiness(body, handleResetForm)
    },
  })

  const disableSubmit =
    Object.keys(formik.errors).length > 0 || Object.keys(formik.touched).length === 0

  return (
    <Container fluid>
      <Card className="mt-3">
        <Card.Header>
          <h3>Create Business</h3>
        </Card.Header>
        <Card.Body>
          {newBusinessLoading ? (
            <Row className="mt-3">
              <Col className="d-flex justify-content-center">
                <ScaleLoader color="#36D7B7" height={50} width={10} radius={4} margin={4} />
              </Col>
            </Row>
          ) : (
            <>
              <Form noValidate onSubmit={formik.handleSubmit}>
                <Row>
                  <Col xs={12} sm={6}>
                    <Form.Group>
                      <FloatingLabel label="Name" className="mt-3">
                        <Form.Control
                          type="text"
                          name="name"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.name}
                          placeholder="Name"
                          isValid={formik.touched.name && !formik.errors.name}
                        />
                      </FloatingLabel>
                      {formik.touched.name && (
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.name}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group>
                      <FloatingLabel label="Platinum Offset %" className="mt-3">
                        <Form.Control
                          type="number"
                          name="platinumOffset"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.platinumOffset}
                          placeholder="platinumOffset"
                          isValid={formik.touched.platinumOffset && !formik.errors.platinumOffset}
                        />
                      </FloatingLabel>
                      <Form.Text id="platinumOffsetHelpBlock" muted>
                        Must be a number between 1 and 100
                      </Form.Text>
                      {formik.touched.platinumOffset && (
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.platinumOffset}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                    <Form.Group>
                      <FloatingLabel label="Palladium Offset %" className="mt-3">
                        <Form.Control
                          type="number"
                          name="palladiumOffset"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.palladiumOffset}
                          placeholder="palladiumOffset"
                          isValid={formik.touched.palladiumOffset && !formik.errors.palladiumOffset}
                        />
                      </FloatingLabel>
                      <Form.Text id="palladiumOffsetHelpBlock" muted>
                        Must be a number between 1 and 100
                      </Form.Text>
                      {formik.touched.palladiumOffset && (
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.palladiumOffset}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                    <Form.Group>
                      <FloatingLabel label="Rhodium Offset %" className="mt-3">
                        <Form.Control
                          type="number"
                          name="rhodiumOffset"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.rhodiumOffset}
                          placeholder="rhodiumOffset"
                          isValid={formik.touched.rhodiumOffset && !formik.errors.rhodiumOffset}
                        />
                      </FloatingLabel>
                      <Form.Text id="rhodiumOffsetHelpBlock" muted>
                        Must be a number between 1 and 100
                      </Form.Text>
                      {formik.touched.rhodiumOffset && (
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.rhodiumOffset}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                    <AddUsersToBusiness
                      usersToAdd={usersToAdd}
                      setSelectedOption={setSelectedOption}
                    />
                  </Col>
                  <Col xs={12} sm={6}>
                    <Form.Group>
                      <FloatingLabel label="Address Line 1" className="mt-3">
                        <Form.Control
                          id="streetAddress"
                          name="streetAddress"
                          type="text"
                          placeholder="streetAddress"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.streetAddress}
                          isValid={formik.touched.streetAddress && !formik.errors.streetAddress}
                        />
                      </FloatingLabel>
                      {formik.touched.streetAddress && (
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.streetAddress}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group>
                      <FloatingLabel label="Address Line 2" className="mt-3">
                        <Form.Control
                          id="streetAddress2"
                          name="streetAddress2"
                          placeholder="streetAddress2"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.streetAddress2}
                          isValid={formik.touched.streetAddress2 && !formik.errors.streetAddress2}
                        />
                      </FloatingLabel>
                      {formik.touched.streetAddress2 && (
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.streetAddress2}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group>
                      <FloatingLabel label="City" className="mt-3">
                        <Form.Control
                          id="city"
                          name="city"
                          type="text"
                          placeholder="City"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.city}
                          isValid={formik.touched.city && !formik.errors.city}
                        />
                      </FloatingLabel>
                      {formik.touched.city && (
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.city}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group>
                      <FloatingLabel label="State" className="mt-3">
                        <Form.Control
                          id="state"
                          name="state"
                          as="select"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.state}
                          isValid={formik.touched.state && !formik.errors.state}
                        >
                          {Object.entries(states).map(([key, value]) => {
                            return <option key={key}>{value}</option>
                          })}
                        </Form.Control>
                      </FloatingLabel>

                      {formik.touched.state && (
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.state}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group>
                      <FloatingLabel label="Postal Code" className="mt-3">
                        <Form.Control
                          id="postalCode"
                          name="postalCode"
                          type="text"
                          placeholder="Postal Code"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.postalCode}
                          isValid={formik.touched.postalCode && !formik.errors.postalCode}
                        />
                      </FloatingLabel>

                      {formik.touched.postalCode && (
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.postalCode}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
              <Row className="mt-5 d-flex justify-content-around">
                <Col xs={6} sm={4}>
                  <Button
                    disabled={disableSubmit}
                    onClick={() => formik.submitForm()}
                    style={{ width: `100%` }}
                  >
                    Create Business
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Card.Body>
      </Card>
    </Container>
  )
}

const mapStateToProps = (state: RootState) => ({
  isAdmin: state.user.isAdmin,
  isBuyer: state.user.isBuyer,
  newBusinessLoading: state.business.newBusinessLoading,
  allUsers: state.admin.allUsers,
})

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    addBusiness: (body, resetForm) => dispatch(createBusiness(body, resetForm)),
    fetchAllUsers: () => dispatch(fetchAllUsersThunk()),
  }
}

// Merge the Redux props
const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(AddBusiness)
