export function createMakes(converters) {
  let output = []

  let object = {}

  for (let i = 0; i < converters.length; i++) {
    let current = {}
    current._id = converters[i]._id
    current.price = Math.round(converters[i].currentPrice)
    current.serialNumber = converters[i].serialNumber
    if (object[converters[i].make] === undefined) {
      object[converters[i].make] = [current]
    } else {
      object[converters[i].make].push(current)
    }
  }

  let makes = Object.keys(object)

  const sortedMakes = makes.sort((a, b) => {
    // we are sorting here because the common grades were not appearing first in the price list.
    if (b.toLowerCase() === 'common grade') {
      return 0
    }
    return 1
  })

  for (let i = 0; i < sortedMakes.length; i++) {
    let makeObject = {}
    makeObject[sortedMakes[i]] = object[sortedMakes[i]]
    output.push(makeObject)
  }

  return output
}

export function updatePrices(converters, val) {
  val = val.replace(/[!@#$%^&*]/g, '')
  if (val >= 1 && val < 10) {
    val = '.' + '0' + val
  } else if (val >= 10) {
    val = '.' + val
  }

  val = 1 - Number(val)
  let copy = converters.slice()
  let output = []
  copy.forEach((converter) => {
    let current = {}
    current.currentPrice = converter.currentPrice * val
    current.currentPrice = current.currentPrice.toFixed(2)
    current.serialNumber = converter.serialNumber
    current.make = converter.make
    current.id = converter.id
    output.push(current)
  })

  return output
}

export function sanitizeMarginInput(val) {
  val = val.replace(/[!@#$%^&*]/g, '')
  if (val > 1 && val < 10) {
    val = '.' + '0' + val
  } else if (val >= 10) {
    val = '.' + val
  }
  return val
}

export function makeEditsArray(edits) {
  let array = []

  let keys = Object.keys(edits)

  keys.forEach((key) => {
    let editObj = {}
    editObj[key] = edits[key]
    array.push(editObj)
  })

  return array
}

export const exportPriceListToCsv = (makes) => {
  const csvData = makes.reduce((acc, make) => {
    for (const grade in make) {
      const gradeData = make[grade]
      gradeData.forEach(({ serialNumber, price }) => {
        acc.push({ make: grade, serialNumber, price })
      })
    }
    return acc
  }, [])

  const header = 'make,serialNumber,price\n'
  const csvContent = header + csvData.map((row) => Object.values(row).join(',')).join('\n')

  const blob = new Blob([csvContent], { type: 'text/csv' })
  const url = URL.createObjectURL(blob)
  const downloadLink = document.createElement('a')
  downloadLink.href = url
  downloadLink.download = 'price_list.csv'
  document.body.appendChild(downloadLink)
  downloadLink.click()
  document.body.removeChild(downloadLink)
}
