import React from 'react'
import { Form } from 'react-bootstrap'
import Select, { ActionMeta } from 'react-select'
import makeAnimated from 'react-select/animated'
import { userToAdd } from './AddBusiness'

interface AddUsersToBusinessProps {
  usersToAdd: userToAdd[]
  setSelectedOption: (option: readonly userToAdd[]) => void
  existingUsersOfBusiness?: readonly userToAdd[]
  setFieldTouched?: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void
}
const animatedComponents = makeAnimated()

const AddUsersToBusiness = ({
  usersToAdd,
  setSelectedOption,
  existingUsersOfBusiness,
  setFieldTouched,
}: AddUsersToBusinessProps) => {
  const onChange = (option: readonly userToAdd[], actionMeta: ActionMeta<userToAdd>) => {
    setSelectedOption(option)
    setFieldTouched && setFieldTouched('usersToAdd')
  }

  return (
    <Form.Group className="mt-3">
      <Select
        isSearchable
        closeMenuOnSelect={false}
        components={animatedComponents}
        isMulti
        options={usersToAdd}
        onChange={onChange}
        placeholder="Select users to add"
        defaultValue={existingUsersOfBusiness?.length ? existingUsersOfBusiness : []}
      />
    </Form.Group>
  )
}

export default AddUsersToBusiness
