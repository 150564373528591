import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { login } from '../store'
import { Image } from 'semantic-ui-react'
import { Card } from 'react-bootstrap'

/**
 * COMPONENT
 */
class AuthForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      toggle: false,
    }
  }

  render() {
    const { name, displayName, handleSubmit, error } = this.props

    return (
      <Card className="mt-3">
        <Card.Header>
          <div className="auth-header">
            <Image wrapped size="medium" src="https://i.imgur.com/B14SBXg.png" />
          </div>
        </Card.Header>
        <Card.Body>
          <div>
            <form className="auth-form" onSubmit={handleSubmit} name={name}>
              <div>
                <label htmlFor="email" />
                <div className="ui icon input">
                  <input type="text" placeholder="Email..." id="login-email" name="email" />
                  <i aria-hidden="true" className="circular mail icon" />
                </div>
              </div>
              <div>
                <label htmlFor="password" />
                <div className="ui icon input">
                  <input
                    type="password"
                    placeholder="Password..."
                    id="login-password"
                    name="password"
                  />
                  <i aria-hidden="true" className="circular lock icon" />
                </div>
              </div>
              <div>
                <button id="login-button" type="submit" className="ui button positive">
                  {displayName}
                </button>
              </div>
              {error && error.response && <div> {error.response.data} </div>}
            </form>
          </div>
        </Card.Body>
      </Card>
    )
  }
}

/**
 * CONTAINER
 *   Note that we have two different sets of 'mapStateToProps' functions -
 *   one for Login, and one for Signup. However, they share the same 'mapDispatchToProps'
 *   function, and share the same Component. This is a good example of how we
 *   can stay DRY with interfaces that are very similar to each other!
 */
const mapLogin = (state) => {
  return {
    name: 'login',
    displayName: 'Login',
    error: state.user.error,
  }
}

const mapDispatch = (dispatch) => {
  return {
    handleSubmit(evt) {
      evt.preventDefault()

      const email = evt.target.email.value.toLowerCase()
      const password = evt.target.password.value
      const body = {
        email,
        password,
      }
      dispatch(login(body))
    },
  }
}

export const Login = connect(mapLogin, mapDispatch)(AuthForm)

/**
 * PROP TYPES
 */
AuthForm.propTypes = {
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.object,
}
