import React, { useEffect } from 'react'

import { connect } from 'react-redux'
import { Alert, Container, Row, Col, Form } from 'react-bootstrap'
import { fetchHedge, togglePause, fetchPricesThunk } from '../store'

const PriceFeed = ({ hedge, doFetchHedge, isLoggedIn, prices, doTogglePause, fetchPrices }) => {
  const { isPaused } = hedge || {}

  useEffect(() => {
    if (isLoggedIn) {
      doFetchHedge()
      fetchPrices()
    }
  }, [isLoggedIn])

  const renderHedgeAlert = () => {
    return (
      <Alert style={{ margin: 0 }} variant={isPaused ? 'light' : 'warning'}>
        <Row>
          <Col
            xs="12"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <div>
                {isPaused ? (
                  <strong>Your hedge is paused.</strong>
                ) : (
                  <>
                    <strong>You have an active hedge.</strong>
                    <span>All cat prices are locked.</span>
                  </>
                )}
              </div>
              <Form.Check
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                type="switch"
                defaultChecked={hedge && !isPaused}
                value={hedge && !isPaused}
                onChange={() => doTogglePause()}
              />
            </div>
          </Col>
        </Row>
      </Alert>
    )
  }
  return (
    <>
      {hedge && (
        <Container fluid style={{ padding: 0 }} className="hedge-alert">
          {renderHedgeAlert()}
        </Container>
      )}

      <div className="price-feed-container">
        {prices.map((metal) => {
          let angle
          let priceBackground

          if (metal.changeDollar[0] !== '-' && metal.changeDollar !== '') {
            angle = 'up'
            priceBackground = 'positive'
          } else if (metal.changeDollar[0] === '-') {
            angle = 'down'
            priceBackground = 'negative'
          } else {
            priceBackground = 'neutral'
          }

          if (hedge && !isPaused) {
            priceBackground = 'hedge'
          }

          return (
            <div
              key={metal.metal}
              className={`indiv-price-feed-container ${priceBackground}`}
              style={{ marginTop: 0 }}
            >
              <div className="indiv-price-feed-top">
                <div>{metal.metal}</div>
                {hedge && !isPaused ? (
                  <div>${hedge[`${metal.metal.toLowerCase()}`]}</div>
                ) : (
                  <div>${metal.bid}</div>
                )}
              </div>
              <div className="indiv-price-feed-bottom">
                <div className="indiv-price-feed-bottom-angle">
                  {hedge && !isPaused ? (
                    <i className="fas fa-lock white" />
                  ) : (
                    <i className={`fas fa-caret-${angle} white`} />
                  )}
                </div>
                {hedge && !isPaused ? (
                  <div className="indiv-price-feed-bottom-prices">
                    <div>LIVE: ${metal.bid}</div>
                  </div>
                ) : (
                  <div className="indiv-price-feed-bottom-prices">
                    <div className="margin-right">{metal.changeDollar}</div>
                    <div>{metal.changePercent}</div>
                  </div>
                )}
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

const mapState = (state) => {
  return {
    prices: state.prices.prices,
    hedge: state.hedge.data,
    isLoggedIn: !!state.user._id || !!state.user.id,
  }
}

const mapDispatch = (dispatch) => {
  return {
    doFetchHedge() {
      dispatch(fetchHedge())
    },
    doTogglePause() {
      dispatch(togglePause())
    },
    fetchPrices() {
      dispatch(fetchPricesThunk())
    },
  }
}

const Connected = connect(mapState, mapDispatch)(PriceFeed)

export default Connected
