import React from 'react'
import EditConverter from './edit-converter'
import { connect } from 'react-redux'
import {
  fetchSingleConverter,
  removeSingleConverter,
  fetchAllMakes,
  putConverter,
  deleteConverter,
} from '../../../store'
import { createMakeOptions } from '../../util/utils'
import { validateWeight } from './utils'
import { toast } from 'react-toastify'
import UserError from '../users/user-error'

class UpdateConverter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      id: 0,
      serialNumber: '',
      make: '',
      weight: '',
      ppmRH: '',
      ppmPT: '',
      ppmPD: '',
      currentPrice: 0.0,
      photo: '',
      imagePreviewUrl: '',
      type: 'Edit',
      options: [],
      newMake: false,
      created: false,
      secondarySerialNumber: '',
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.getPhoto = this.getPhoto.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.delete = this.delete.bind(this)
  }

  componentDidMount() {
    let urlArr = this.props.match.url.split('/')
    let id = urlArr[urlArr.length - 1]

    this.props.fetchSingleConverter(id)
    if (this.props.allMakes !== undefined && this.props.allMakes) {
      let options = createMakeOptions(this.props.allMakes)
      options = options.slice(1, options.length)
      this.setState({ options })
    } else {
      this.props.fetchAllMakes()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedConverter !== this.props.selectedConverter) {
      const {
        weight,
        id,
        make,
        serialNumber,
        photo,
        currentPrice,
        ppmRH,
        ppmPT,
        ppmPD,
        secondarySerialNumber,
      } = this.props.selectedConverter

      this.setState({
        weight,
        id,
        make,
        serialNumber,
        photo,
        currentPrice,
        ppmRH,
        ppmPT,
        ppmPD,
        secondarySerialNumber,
      })
    }

    if (prevProps.allMakes !== this.props.allMakes) {
      let options = createMakeOptions(this.props.allMakes)
      options = options.slice(1, options.length)
      this.setState({ options })
    }

    if (
      this.props.selectedConverter.error === undefined &&
      this.state.created &&
      prevProps.selectedConverter !== this.props.selectedConverter
    ) {
      toast.success(`Success: ${this.state.serialNumber} has been updated!`)
      this.setState({ created: false })
    }
    if (prevProps.selectedConverter.photo !== this.props.selectedConverter.photo) {
      this.setState({
        photo: this.props.selectedConverter.photo,
        imagePreviewUrl: this.props.selectedConverter.photo,
      })
    }
  }

  componentWillUnmount() {
    this.props.removeSelected({
      id: 0,
      serialNumber: '',
      make: '',
      weight: '',
      currentPrice: 0.0,
      photo: '',
      error: '',
      ppmRH: '',
      ppmPT: '',
      ppmRD: '',
    })
  }

  handleInputChange(evt) {
    evt.preventDefault()
    let value = event.target.value
    let name = event.target.name

    if (value === undefined) {
      value = evt.target.querySelector('span').innerText
      name = 'make'
    }

    this.setState({ [name]: value })
  }

  delete(id) {
    this.props.deleteConverter(id)
  }

  getPhoto(e) {
    e.preventDefault()
    let reader = new FileReader()
    let file = e.target.files[0]
    reader.onloadend = () => {
      this.setState({
        imagePreviewUrl: reader.result,
      })
    }
    reader.readAsDataURL(file)
  }

  handleSubmit() {
    const { id, serialNumber, weight, photo, ppmPD, ppmPT, ppmRH, secondarySerialNumber } =
      this.state
    let make
    if (this.state.make !== undefined) {
      make = this.state.make
    } else {
      make = this.state.make
    }

    const body = {
      id,
      serialNumber,
      make,
      weight,
      ppmPD,
      ppmPT,
      ppmRH,
      secondarySerialNumber,
    }

    const imagePreviewUrl = this.state.imagePreviewUrl
    if (imagePreviewUrl === '') {
      body.photo = photo
    } else {
      body.photo = imagePreviewUrl
    }
    this.setState({ created: true })
    this.props.updateConverter(body)
  }

  render() {
    const {
      id,
      serialNumber,
      make,
      type,
      weight,
      photo,
      imagePreviewUrl,
      options,
      currentPrice,
      secondarySerialNumber,
    } = this.state

    const { error } = this.props.selectedConverter
    if (error) {
      return <UserError error={error} />
    } else {
      let disabled
      let weightValidation = validateWeight(weight)
      let weightError = weightValidation ? '' : 'error'
      if (weightError) {
        disabled = 'disabled'
      } else {
        disabled = ''
      }
      return (
        <EditConverter
          id={id}
          currentPrice={currentPrice}
          handleInputChange={this.handleInputChange}
          serialNumber={this.state.serialNumber}
          make={this.state.make}
          type={this.state.type}
          weight={this.state.weight}
          ppmPD={this.state.ppmPD}
          ppmPT={this.state.ppmPT}
          ppmRH={this.state.ppmRH}
          photo={this.state.photo}
          imagePreviewUrl={this.state.imagePreviewUrl}
          getPhoto={this.getPhoto}
          handleSubmit={this.handleSubmit}
          options={this.state.options}
          disabled={disabled}
          weightError={weightError}
          newMake={this.state.newMake}
          delete={this.delete}
          secondarySerialNumber={this.state.secondarySerialNumber}
        />
      )
    }
  }
}

const mapStateToProps = (state) => {
  return {
    selectedConverter: state.converter.selectedConverter,
    allMakes: state.makes.allMakes,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSingleConverter: (serialNumber) => dispatch(fetchSingleConverter(serialNumber)),
    removeSelectedConverter: (body) => dispatch(removeSingleConverter(body)),
    fetchAllMakes: () => dispatch(fetchAllMakes()),
    updateConverter: (body) => dispatch(putConverter(body)),
    removeSelected: (body) => dispatch(removeSingleConverter(body)),
    deleteConverter: (body) => dispatch(deleteConverter(body)),
  }
}

const connected = connect(mapStateToProps, mapDispatchToProps)(UpdateConverter)

export default connected
