import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { Input, Dropdown } from 'semantic-ui-react'
import { setSearch, setMake, RootState, AppDispatch } from '../../store'

interface ConverterSearchProps {
  options?: any
  position: string
  filterConverters: (value: string, make?: string) => void
  setSearchTerm: (term: string) => void
  searchTerm: string
  make: string
  setMakeTerm: (make: string) => void
}

function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value //assign the value of ref to the argument
  }, [value]) //this code will run when the value of 'value' changes
  return ref.current //in the end, return the current ref value.
}

const ConverterSearch = ({
  options,
  position,
  filterConverters,
  setSearchTerm,
  searchTerm,
  make,
  setMakeTerm,
}: ConverterSearchProps) => {
  const prevSearchTerm = usePrevious(searchTerm)

  useEffect(() => {
    if (searchTerm === '' && prevSearchTerm !== '') {
      setMakeTerm('all converters')
    }

    filterConverters(searchTerm, make)
  }, [searchTerm, make])

  const handleChange = (evt: React.ChangeEvent) => {
    evt.preventDefault()
    const { value } = evt.target as HTMLInputElement

    setSearchTerm(value)
    filterConverters(searchTerm, make)
  }

  const handleOptionChange = (e: React.SyntheticEvent, { value }) => {
    setMakeTerm(value)
    filterConverters(searchTerm, value)
  }

  let style: any = {
    zIndex: 1,
  }

  if (position === 'fixed') {
    style = {
      ...style,
      position: 'fixed',
      top: '-10px',
      left: '0px',
      paddingLeft: '10px',
      paddingRight: '10px',
      marginTop: '0px !important',
    }
  }

  return (
    <div className="converter-search-container" style={style}>
      <Input
        className="ui action input converter-search-input"
        type="text"
        placeholder="Enter a serial number or make..."
        name="serialNumber"
        action
      >
        <input value={searchTerm} onChange={handleChange} />
        {options?.length > 0 && (
          <Dropdown
            onChange={handleOptionChange}
            options={options}
            placeholder="All Converters"
            selection
            value={make}
          />
        )}
      </Input>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    searchTerm: state.cart.search,
    make: state.cart.make,
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    setSearchTerm: (term: string) => dispatch(setSearch(term)),
    setMakeTerm: (term: string) => dispatch(setMake(term)),
  }
}

const Connected = connect(mapStateToProps, mapDispatchToProps)(ConverterSearch)

export default Connected
