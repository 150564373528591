import { createStore, combineReducers, applyMiddleware } from 'redux'
import { createLogger } from 'redux-logger'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import user from './user'
import converter from './converter'
import prices from './prices'
import admin from './admin'
import makes from './makes'
import logs from './logs'
import invoices from './invoices'
import cart from './cart'
import hedge from './hedge'
import business from './business'
import { useDispatch } from 'react-redux'

const reducer = combineReducers({
  user,
  converter,
  prices,
  admin,
  makes,
  logs,
  invoices,
  cart,
  hedge,
  business,
})

const middleware =
  process.env.NODE_ENV === 'development'
    ? composeWithDevTools(applyMiddleware(thunkMiddleware, createLogger({ collapsed: true })))
    : composeWithDevTools(applyMiddleware(thunkMiddleware))

const store = createStore(reducer, middleware)

export default store
export * from './user'
export * from './converter'
export * from './prices'
export * from './admin'
export * from './makes'
export * from './logs'
export * from './invoices'
export * from './cart'
export * from './hedge'
export * from './business'

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>()
