import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form, Card, Button } from 'react-bootstrap'
import { RootState, AppDispatch, fetchAllInvoices, useAppDispatch } from '../../store'
import { useFormik } from 'formik'
import * as yup from 'yup' // for everything
import { connect } from 'react-redux'
import DatePicker from 'react-date-picker'
import { ScaleLoader } from 'react-spinners'
import { format } from 'date-fns'
import { IInvoice } from '../../types/invoice'
import { IUser, locations } from '../../types/user'
import { Link } from 'react-router-dom'
import JSZip from 'jszip'
import { convertJsonToCsv } from '../util/jsonToCsv'
import { startCase } from 'lodash'

const schema = yup.object({
  startDate: yup.date().required('Start date required.'),
  endDate: yup
    .date()
    .required('End date required.')
    .min(yup.ref('startDate'), "End date can't be before start date."),
  location: yup.string().required('Location required.'),
})

interface InvoicesDataProps {
  historicalInvoicesData: IInvoice[]
  fetchInvoicesData: (body: { startDate: Date; endDate: Date; location?: string }) => void
  user: IUser
}

const InvoicesData = ({ historicalInvoicesData, fetchInvoicesData, user }: InvoicesDataProps) => {
  const [isLoading, setIsLoading] = useState(true)
  const dispatch: any = useAppDispatch()

  const { isAdmin, canSeeInvoiceData } = user || {}

  const formik = useFormik({
    initialValues: {
      startDate: new Date().toString(),
      endDate: new Date().toString(),
      location: isAdmin ? 'all' : user.location,
    },
    validationSchema: schema,
    onSubmit: ({ startDate, endDate, location }) => {
      setIsLoading(true)

      dispatch(
        fetchAllInvoices({
          startDate: new Date(startDate),
          endDate: new Date(endDate),
          location,
        })
      ).then(setIsLoading(false))

      formik.setFieldTouched('startDate', false)
      formik.setFieldTouched('endDate', false)
      formik.setFieldTouched('location', false)
    },
  })

  useEffect(() => {
    fetchInvoicesData({
      startDate: new Date(),
      endDate: new Date(),
      location: isAdmin ? 'all' : user.location,
    })
    setIsLoading(false)
  }, [])

  const downloadCsv = () => {
    const propertiesToExclude = [
      '__v',
      '_id',
      'cart',
      'invoiceUrl',
      'createdAt',
      'updatedAt',
      'items',
      'labels',
    ]
    // Convert JSON data to CSV format
    const csvContent = convertJsonToCsv(historicalInvoicesData, propertiesToExclude)

    // Create a Blob object with CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })

    // Create a temporary anchor element
    const link = document.createElement('a')

    // Set anchor's properties
    link.href = URL.createObjectURL(blob)
    link.download = `invoices_${formik.values.location}_${format(
      new Date(formik.values.startDate),
      'P'
    )}_to_${format(new Date(formik.values.endDate), 'P')}.csv`

    // Append the anchor element to the document body
    document.body.appendChild(link)

    // Simulate a click on the anchor element to trigger the download
    link.click()

    // Clean up the temporary anchor element
    document.body.removeChild(link)
  }

  async function downloadInvoicesAsZip() {
    const zip = new JSZip()

    try {
      // Iterate over each invoice data in historicalInvoicesData

      await Promise.all(
        historicalInvoicesData.map(async (invoice) => {
          const response = await fetch(invoice.invoiceUrl)
          if (!response.ok) throw new Error(`Failed to fetch invoice PDF: ${invoice.invoiceUrl}`)
          const blob = await response.blob()

          // Add the PDF file to the zip folder with a unique filename
          zip.file(
            `invoice_${invoice.customer.location}_${invoice.customer.firstName}_${
              invoice.customer.lastName
            }_${format(new Date(invoice.dateAdded), 'MM-dd-yyyy')}.pdf`,
            blob
          )
        })
      )

      // Generate the zip folder asynchronously
      const content = await zip.generateAsync({ type: 'blob' })

      // Create a temporary <a> element to trigger the file download
      const downloadLink = document.createElement('a')
      downloadLink.href = URL.createObjectURL(content)
      downloadLink.download = `invoices_${formik.initialValues.location}_${format(
        new Date(formik.values.startDate),
        'MM-dd-yyyy'
      )}_to_${format(new Date(formik.values.endDate), 'MM-dd-yyyy')}.zip`

      // Trigger the download
      downloadLink.click()

      // Clean up the temporary <a> element
      URL.revokeObjectURL(downloadLink.href)
      downloadLink.remove()
    } catch (error) {
      console.error('Failed to download invoices as a zip:', error)
    }
  }

  const disableSubmit =
    !formik.touched.startDate &&
    !formik.touched.endDate &&
    !formik.touched.location &&
    !formik.errors.startDate &&
    !formik.errors.endDate &&
    !formik.errors.location

  return (
    <Container fluid>
      <Card className="mt-3">
        <Card.Header>
          <h3>Invoices Data</h3>
        </Card.Header>
        <Card.Body>
          <Form noValidate onSubmit={formik.handleSubmit}>
            <Row>
              <Col xs={12} sm={4} style={{ margin: 0 }}>
                <Form.Label style={{ fontWeight: '600' }}>Set Start Date</Form.Label>
                <div style={{ width: '100%' }}>
                  <DatePicker
                    clearIcon={null}
                    name="startDate"
                    onChange={(val: Date) => {
                      formik.setFieldValue('startDate', val)
                      formik.setFieldTouched('startDate', true, true)
                    }}
                    value={new Date(formik.values.startDate)}
                    maxDate={new Date()}
                  />
                </div>
                {formik.touched.startDate && (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.startDate}
                  </Form.Control.Feedback>
                )}
              </Col>
              <Col xs={12} sm={4} style={{ margin: 0 }}>
                <Form.Label style={{ fontWeight: '600' }}>Set End Date</Form.Label>
                <DatePicker
                  clearIcon={null}
                  name="endDate"
                  onChange={(val: Date) => {
                    formik.setFieldValue('endDate', val)
                    formik.setFieldTouched('endDate', true, true)
                  }}
                  minDate={new Date(formik.values.startDate)}
                  maxDate={new Date()}
                  value={new Date(formik.values.endDate)}
                />
                {formik.touched.endDate && (
                  <Form.Control.Feedback type="invalid">
                    <div>{formik.errors.endDate}</div>
                  </Form.Control.Feedback>
                )}
              </Col>
              <Col xs={12} sm={4} style={{ margin: 0 }}>
                <Form.Group>
                  {isAdmin && <Form.Label htmlFor="location">Set Location:</Form.Label>}
                  {!isAdmin && canSeeInvoiceData && (
                    <Form.Label htmlFor="location">Location:</Form.Label>
                  )}
                  {isAdmin && (
                    <Form.Select
                      onChange={(e) => {
                        formik.handleChange(e)
                        formik.handleBlur(e)
                      }}
                      id="location"
                      name="location"
                      value={formik.values.location}
                    >
                      {locations.map((location) => {
                        return (
                          <option key={location.value} value={location.value}>
                            {location.text}
                          </option>
                        )
                      })}
                    </Form.Select>
                  )}
                  {!isAdmin && canSeeInvoiceData && (
                    <Form.Label htmlFor="location">{startCase(user.location)}</Form.Label>
                  )}
                </Form.Group>

                {formik.touched.location && (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.location}
                  </Form.Control.Feedback>
                )}
              </Col>
            </Row>
          </Form>
          <Row className="mt-4">
            <Col xs={6} sm={4}>
              <Button
                type="submit"
                onClick={() => {
                  formik.submitForm()
                }}
                style={{ width: `100%` }}
                disabled={disableSubmit}
              >
                Get Data
              </Button>
            </Col>
            {historicalInvoicesData.length > 0 && (
              <>
                <Col xs={6} sm={4}>
                  <Button className="me-3" variant="outline-info" onClick={downloadCsv}>
                    Export to CSV
                  </Button>

                  <Button variant="outline-info" onClick={downloadInvoicesAsZip}>
                    Download All
                  </Button>
                </Col>
              </>
            )}
          </Row>
          {isLoading ? (
            <Row className="mt-3">
              <Col className="d-flex justify-content-center">
                <ScaleLoader color="#36D7B7" height={50} width={10} radius={4} margin={4} />
              </Col>
            </Row>
          ) : (
            <div className="mt-4">
              {historicalInvoicesData.length === 0 ? (
                <div>No invoices found.</div>
              ) : (
                <Col>
                  <Card>
                    <br />
                    {historicalInvoicesData.map((invoice) => {
                      return (
                        <div key={invoice._id}>
                          <Card.Body>
                            <Row>
                              <Col xs={12} sm={3}>
                                <div>
                                  <strong>Customer</strong>
                                </div>
                                <div>{`${invoice.customer.firstName} ${invoice.customer.lastName}`}</div>
                                <div>{invoice.customer.email}</div>
                              </Col>
                              <Col xs={12} sm={3}>
                                {' '}
                                <div>
                                  <strong>Buyer</strong>
                                </div>
                                <div>{`${invoice.createdBy.firstName} ${invoice.createdBy.lastName}`}</div>
                                <div>{invoice.createdBy.email}</div>
                              </Col>
                              <Col xs={12} sm={3}>
                                <div>
                                  <strong>
                                    {'Total Cost: '}
                                    {new Intl.NumberFormat('en-US', {
                                      style: 'currency',
                                      currency: 'USD',
                                    }).format(invoice.cost)}
                                  </strong>
                                </div>
                                <div>Total Pieces:{` ${invoice.pieces}`}</div>
                                <div>
                                  {invoice.payWithCheck ? 'Paid With Check' : 'Paid In Cash'}
                                </div>
                                <div>{format(new Date(invoice.dateAdded), 'Pp')}</div>
                              </Col>
                              <Col
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                }}
                                xs={12}
                                sm={3}
                              >
                                <Link to={`/admin/invoices/${invoice._id}`}>
                                  <Button style={{ maxHeight: 38 }}>View Invoice</Button>
                                </Link>
                              </Col>
                            </Row>
                          </Card.Body>
                          <hr />
                        </div>
                      )
                    })}
                  </Card>
                </Col>
              )}
            </div>
          )}
        </Card.Body>
      </Card>
    </Container>
  )
}

const mapState = (state: RootState) => {
  return {
    historicalInvoicesData: state.invoices.allInvoices,
    user: state.user,
  }
}

const mapDispatch = (dispatch: AppDispatch) => {
  return {
    fetchInvoicesData(body: { startDate: Date; endDate: Date; location?: string }) {
      dispatch(fetchAllInvoices(body))
    },
  }
}

export default connect(mapState, mapDispatch)(InvoicesData)
