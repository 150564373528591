import React from 'react'
import {Link} from 'react-router-dom'
import {toast} from 'react-toastify'

const UserError = props => {
  let {error} = props
  let status
  let text
  if (error && error.response) {
    status = error.response.status
    text = error.response.data
    error = error.response.statusText
    toast.error(`There was an Error! ${error}`)
  } else {
    error = error.message
  }

  return (
    <div className="site-container">
      <Link
        to="/dashboard"
        className="width ui button dashboard-add-converter-btn"
      >
        Back to Dashboard
      </Link>
      <div className="selected-info-container">
        <div className="selected-info-top-row">
          <div className="bold">{status}</div>
          <div className="bold">{error}</div>
        </div>
        <div className="edit-info-body">
          <div className="user-error-body">{text}</div>
        </div>
      </div>
    </div>
  )
}

export default UserError
