import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { fetchSelectedUserThunk, fetchAllUserLogs } from '../store'
import IndividualLog from '../components/admin-dashboard/users/activity-logs/individual-log'
import { lastLogin } from '../components/admin-dashboard/users/activity-logs/utils'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { calculateAllInvoiceValue } from './util/utils'

const SelectedUser = ({ user, fetchSelectedUser, fetchLogs, allUserLogs }) => {
  const { id: userId } = useParams()
  const [errorLogs, setErrorLogs] = useState([])
  const [loginLogs, setLoginLogs] = useState([])
  const [allLogs, setAllLogs] = useState(null)

  useEffect(() => {
    fetchSelectedUser({ userPermission: true, userId })

    if (!allLogs) {
      fetchLogs(userId)
    }

    const errorLogsArr = []
    const loginLogsArr = []

    allUserLogs.forEach((log) => {
      if (log.status && (log.status.startsWith('4') || log.status.startsWith('5'))) {
        errorLogsArr.push(log)
      }
      if (log.type === 'AUTH') {
        loginLogsArr.push(log)
      }
    })
    setAllLogs(allUserLogs)
    setErrorLogs(errorLogsArr)
    setLoginLogs(loginLogsArr)
  }, [allUserLogs])

  return (
    <Container fluid>
      <Row>
        <Col>
          <Link to="/admin/view/users" className="ui button dashboard-add-converter-btn">
            Back To All Users
          </Link>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs="12" lg="12">
          <Card>
            <Card.Header>
              <h3>User Summary</h3>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col xs="12" sm="4">
                  {!user.killed && user.isAdmin && (
                    <div
                      style={{
                        color: 'white',
                        padding: 2,
                        borderRadius: 5,
                        background: 'rgb(7, 29, 57)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontWeight: 'bold',
                        marginBottom: 10,
                        width: `75%`,
                      }}
                    >
                      Admin
                    </div>
                  )}
                  {!user.killed && user.isBuyer && (
                    <div
                      style={{
                        color: 'white',
                        padding: 2,
                        borderRadius: 5,
                        background: '#f2711c',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontWeight: 'bold',
                        marginBottom: 10,
                        width: `75%`,
                      }}
                    >
                      Buyer
                    </div>
                  )}
                  {!user.killed && user.isGuest && (
                    <div
                      style={{
                        color: 'white',
                        padding: 2,
                        borderRadius: 5,
                        background: '#e0e1e2',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontWeight: 'bold',
                        marginBottom: 10,
                        width: `75%`,
                      }}
                    >
                      Guest
                    </div>
                  )}
                  {!user.killed && !user.isGuest && !user.isBuyer && !user.isAdmin && (
                    <div
                      style={{
                        color: 'white',
                        padding: 2,
                        borderRadius: 5,
                        background: '#0b5ed7',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontWeight: 'bold',
                        marginBottom: 10,
                        width: `75%`,
                      }}
                    >
                      Customer
                    </div>
                  )}
                  {user.killed && (
                    <div
                      style={{
                        color: 'white',
                        padding: 2,
                        borderRadius: 5,
                        background: '#db2828',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontWeight: 'bold',
                        marginBottom: 10,
                        width: `75%`,
                      }}
                    >
                      Deleted
                    </div>
                  )}
                  <div>
                    <strong>Name:</strong> {` ${user.firstName} ${user.lastName}`}
                  </div>
                  <div>
                    <strong>Email:</strong> {` ${user.email}`}
                  </div>
                </Col>
                <Col xs="12" sm="4">
                  <div>
                    <strong>Margin:</strong> {` ${Number(user.margin * 100).toFixed(2)}%`}
                  </div>
                  <div>
                    <strong>Last Login:</strong> {lastLogin(allUserLogs)}
                  </div>
                  <div>
                    <strong>Total Actions:</strong> {allUserLogs.length}
                  </div>
                </Col>
                <Col xs="12" sm="4">
                  {user.invoices && (
                    <>
                      <div>
                        <strong>Total Invoices:</strong> {` ${user.invoices.length}`}
                      </div>
                      <div>
                        <strong>Total Invoice Amount:</strong>{' '}
                        {` ${calculateAllInvoiceValue(user.invoices)}`}
                      </div>
                    </>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col xs="12" lg="8" className="mt-3">
          <Card>
            <Card.Header>
              {' '}
              <h3>Invoices</h3>
            </Card.Header>
            <Card.Body>
              {user.invoices && (
                <Row>
                  <Col
                    style={{
                      height: window.innerWidth > 500 ? '50vh' : 'unset',
                      overflowY: window.innerWidth > 500 ? 'scroll' : 'unset',
                    }}
                  >
                    {!user.invoices.length && <div>No Invoices</div>}
                    {user.invoices &&
                      user.invoices.map((invoice) => {
                        return (
                          <>
                            <Row>
                              <Col xs="6">
                                <div>
                                  <strong>Cost:</strong>
                                  {` ${new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                  }).format(invoice.cost)}`}
                                </div>
                                <div>
                                  <strong>Created By:</strong> {`${invoice.createdBy.email}`}
                                </div>
                                <div>
                                  <strong>Date Purchased:</strong>
                                  {` ${moment(invoice.dateAdded).format('llll')}`}
                                </div>
                              </Col>
                              <Col
                                style={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                }}
                              >
                                <Link to={`/admin/invoices/${invoice._id}`}>
                                  <Button style={{ maxHeight: 38 }}>View Invoice</Button>
                                </Link>
                              </Col>
                            </Row>
                            <hr />
                          </>
                        )
                      })}
                  </Col>
                </Row>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col xs="12" lg="4" className="mt-3">
          <Card>
            <Card.Header>
              <h3>Logs</h3>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col
                  style={{
                    overflowY: window.innerWidth > 500 ? 'scroll' : 'unset',
                    height: allLogs && allLogs.length && window.innerWidth > 500 ? '50vh' : 'unset',
                  }}
                >
                  {allLogs && !allLogs.length && <div>No Logs</div>}

                  {allLogs &&
                    allLogs.map((logs, idx) => {
                      const { status, route, type, dateAdded, message, serialNumber, method } = logs
                      return (
                        <IndividualLog
                          key={idx}
                          status={status}
                          route={route}
                          type={type}
                          dateAdded={dateAdded}
                          message={message}
                          serialNumber={serialNumber}
                          method={method}
                        />
                      )
                    })}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

const mapState = (state) => {
  return {
    user: state.admin.selectedUser,
    isAdmin: state.user.isAdmin,
    errorLogs: state.logs.errors,
    allUserLogs: state.logs.allUserLogs,
  }
}

const mapDispatch = (dispatch) => {
  return {
    fetchSelectedUser: (body) => dispatch(fetchSelectedUserThunk(body)),
    fetchLogs: (id) => dispatch(fetchAllUserLogs(id)),
  }
}

export default connect(mapState, mapDispatch)(SelectedUser)
