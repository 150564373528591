import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'

interface SizesModalProps {
  converter: any
  showSizesModal: boolean
  setShowSizesModal: (arg: boolean) => void
  addCustomSizeToCart: (arg: number) => void
}

const SizesModal = ({
  converter,
  showSizesModal,
  setShowSizesModal,
  addCustomSizeToCart,
}: SizesModalProps) => {
  const [sizeModifier, setSizeModifier] = useState(1)
  const handleClick = (size) => {
    setSizeModifier(size)
  }
  return (
    <Modal show={showSizesModal} centered>
      <Modal.Header>Other Sizes</Modal.Header>
      <Modal.Body>
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <Button
            className="mx-2"
            style={{ width: '33%' }}
            variant="primary"
            onClick={() => handleClick(0.25)}
          >
            1/4
          </Button>
          <Button
            className="mx-2"
            style={{ width: '33%' }}
            variant="primary"
            onClick={() => handleClick(0.5)}
          >
            1/2
          </Button>
          <Button
            className="mx-2"
            style={{ width: '33%' }}
            variant="primary"
            onClick={() => handleClick(0.75)}
          >
            3/4
          </Button>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '20px' }}>
          <Button
            className="mx-2"
            style={{ width: '33%' }}
            variant="primary"
            onClick={() => handleClick(0.33)}
          >
            1/3
          </Button>
          <Button
            className="mx-2"
            style={{ width: '33%' }}
            variant="primary"
            onClick={() => handleClick(0.66)}
          >
            2/3
          </Button>
        </div>
        {sizeModifier < 1 && (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <span>
              <h1>
                ${Number(converter.currentPrice).toFixed(2)} * {sizeModifier} = $
                {Number(converter.currentPrice * sizeModifier).toFixed(2)}
              </h1>
            </span>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setShowSizesModal(false)} variant="danger">
          Close
        </Button>
        <Button
          disabled={sizeModifier === 1}
          onClick={() => {
            addCustomSizeToCart(sizeModifier)
            setShowSizesModal(false)
          }}
          variant="success"
        >
          Add Custom Size to Cart
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default SizesModal
