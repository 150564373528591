import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { RootState, AppDispatch, fetchAllUsersThunk } from '../../../store'
import { Link } from 'react-router-dom'
import IndividualUser from './IndividualUser'
import { Row, Col, Container, Card } from 'react-bootstrap'
import { IUser } from 'client/types/user'

interface AllUserPageProps {
  fetchAllUsers: () => void
  allUsers: IUser[]
  currentUser: IUser
}

const AllUserPage = ({ fetchAllUsers, allUsers, currentUser }: AllUserPageProps) => {
  const [users, setUsers] = useState<Array<IUser>>([])
  const [admins, setAdmins] = useState<Array<IUser>>([])
  const [buyers, setBuyers] = useState<Array<IUser>>([])
  const [customers, setCustomers] = useState<Array<IUser>>([])
  const [guests, setGuests] = useState<Array<IUser>>([])
  const [partners, setPartners] = useState<Array<IUser>>([])
  const [val, setVal] = useState<string>('')
  const [toggled, setToggled] = useState(false)

  const filterUsers = (value: string) => {
    const filtered = allUsers.filter((user) => {
      const email = user.email
      const fullName = `${user.firstName.toLowerCase()} ${user.lastName.toLowerCase()}`

      return fullName.includes(value) || email.includes(value)
    })

    setUsers(filtered)

    const buyersFiltered = filtered.filter((user) => user.isBuyer)
    setBuyers(buyersFiltered)

    const guestsFiltered = filtered.filter((user) => user.isGuest)
    setGuests(guestsFiltered)

    const customersFiltered = filtered.filter(
      (user) => !user.isGuest && !user.isAdmin && !user.isBuyer
    )
    setCustomers(customersFiltered)

    const adminsFiltered = filtered.filter((user) => user.isAdmin)
    setAdmins(adminsFiltered)

    const partnersFiltered = filtered.filter((user) => user.isPartner)
    setPartners(partnersFiltered)
  }

  const handleChange = (evt: React.ChangeEvent) => {
    evt.preventDefault()
    const { value } = evt.target as HTMLInputElement

    setVal(value)
    filterUsers(value.toLowerCase())
  }

  useEffect(() => {
    fetchAllUsers()
  }, [fetchAllUsers])

  useEffect(() => {
    if (allUsers.length > 0 && !toggled) {
      const sortedUsers = allUsers.sort((a, b) => a.firstName.localeCompare(b.firstName))
      const adminsFiltered = sortedUsers.filter((user) => user.isAdmin)
      const buyersFiltered = sortedUsers.filter((user) => user.isBuyer)
      const guestsFiltered = sortedUsers.filter((user) => user.isGuest)
      const customersFiltered = sortedUsers.filter((user) => user.isCustomer)
      const partnersFiltered = sortedUsers.filter((user) => user.isPartner)

      setUsers(sortedUsers)
      setAdmins(adminsFiltered)
      setBuyers(buyersFiltered)
      setGuests(guestsFiltered)
      setCustomers(customersFiltered)
      setPartners(partnersFiltered)
      setToggled(true)
    }
  }, [allUsers, toggled])

  if (users.length === 0 && !toggled) {
    return (
      <div className="site-container">
        {[1, 2, 3].map((index) => (
          <div key={index} className="ui fluid site-container segment">
            <div className="ui active fluid transition visible inverted dimmer">
              <div className="content">
                <div className="ui large fluid text loader">Loading</div>
              </div>
            </div>
            <img
              src="https://react.semantic-ui.com/images/wireframe/paragraph.png"
              className="ui image"
              alt={`Placeholder ${index}`}
            />
          </div>
        ))}
      </div>
    )
  }

  return (
    <Container fluid>
      <div className="converter-search-container">
        <div className="ui action input converter-search-input">
          <input
            type="text"
            placeholder="Search users by name..."
            value={val}
            onChange={handleChange}
          />
          <button className="ui disabled button">Search</button>
        </div>
      </div>
      <Link to="/admin/add/user" className="ui button dashboard-add-converter-btn orange">
        Add User
      </Link>

      <Row className="mt-3">
        <Col>
          <Card>
            <Card.Header>
              <h3>Customer Accounts</h3>
            </Card.Header>
            {customers.map((user) => (
              <div key={user._id}>
                <IndividualUser key={user._id} user={user} />
              </div>
            ))}
          </Card>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Card>
            <Card.Header>
              <h3>Partner Accounts</h3>
            </Card.Header>
            {partners.map((user) => (
              <div key={user._id}>
                <IndividualUser key={user._id} user={user} />
              </div>
            ))}
          </Card>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Card>
            <Card.Header>
              <h3>Guest Accounts</h3>
            </Card.Header>
            {guests.map((user) => (
              <div key={user._id}>
                <IndividualUser key={user._id} user={user} />
              </div>
            ))}
          </Card>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Card>
            <Card.Header>
              <h3>Buyer Accounts</h3>
            </Card.Header>
            {buyers.map((user) => (
              <div key={user._id}>
                <IndividualUser key={user._id} user={user} />
              </div>
            ))}
          </Card>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Card>
            <Card.Header>
              <h3>Admin Accounts</h3>
            </Card.Header>
            {admins.map((user) => (
              <div key={user._id}>
                <IndividualUser key={user._id} user={user} />
              </div>
            ))}
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

const mapState = (state: RootState) => {
  return {
    allUsers: state.admin.allUsers,
    currentUser: state.user,
  }
}

const mapDispatch = (dispatch: AppDispatch) => {
  return {
    fetchAllUsers: () => dispatch(fetchAllUsersThunk()),
  }
}

export default connect(mapState, mapDispatch)(AllUserPage)
