import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card, Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { RootState, AppDispatch, fetchAllInvoicesSingleUser } from '../../store'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Input } from 'semantic-ui-react'
import { IInvoice } from '../../types/invoice'
import { IUser } from '../../types/user'
import ScaleLoader from 'react-spinners/ScaleLoader'

interface InvoicesProps {
  fetchAllInvoices: () => void
  invoices: IInvoice[]
  user: IUser
  myInvoices: IInvoice[]
}

const Invoices = ({ fetchAllInvoices, invoices, user }: InvoicesProps) => {
  const [searchValue, setSearchValue] = useState('')
  const [filteredInvoices, setFilteredInvoices] = useState<IInvoice[]>([])
  const [loading, setLoading] = useState(true)
  const { isAdmin, isBuyer, isPartner } = user
  useEffect(() => {
    if (!invoices.length) {
      fetchAllInvoices()
    }
  }, [])

  useEffect(() => {
    setFilteredInvoices(invoices)
    setLoading(false)
  }, [invoices])

  const filterInvoices = (e) => {
    const pageInvoices = invoices

    const val = e.target.value.toLowerCase()

    setSearchValue(val)
    const filtered = pageInvoices.filter((invoice) => {
      const customer = invoice.customer
      const buyer = invoice.createdBy
      if (
        customer.firstName.toLowerCase().includes(val) ||
        customer.lastName.toLowerCase().includes(val) ||
        customer.email.toLowerCase().includes(val)
      ) {
        return invoice
      }
      if (
        buyer.firstName.toLowerCase().includes(val) ||
        buyer.lastName.toLowerCase().includes(val) ||
        buyer.email.toLowerCase().includes(val)
      ) {
        return invoice
      }
    })

    if (val.length === 0) {
      setFilteredInvoices(pageInvoices)
    } else {
      setFilteredInvoices(filtered)
    }
  }

  return (
    <Container fluid className="mt-3">
      {(isAdmin || isBuyer) && (
        <Row>
          <div className="converter-search-container">
            <Input
              className="ui action input converter-search-input"
              type="text"
              placeholder="Search for a user"
              name="serialNumber"
            >
              <input value={searchValue} onChange={filterInvoices} />
            </Input>
          </div>
        </Row>
      )}
      {loading ? (
        // <Row className="mt-3">
        //   <Col className="d-flex justify-content-center">
        //     <ScaleLoader color="#36D7B7" height={50} width={10} radius={4} margin={4} />
        //   </Col>
        // </Row>
        <div />
      ) : (
        <>
          {filteredInvoices.length ? (
            <Row className="mt-3">
              <Col>
                <Card>
                  <Card.Header>Invoices</Card.Header>
                  <br />
                  {filteredInvoices.map((invoice) => {
                    return (
                      <div key={invoice._id}>
                        <Card.Body>
                          <Row>
                            <Col xs={12} sm={3}>
                              <div>
                                <strong>Customer</strong>
                              </div>
                              <div>{`${invoice.customer.firstName} ${invoice.customer.lastName}`}</div>
                              <div>{invoice.customer.email}</div>
                            </Col>
                            <Col xs={12} sm={3}>
                              {' '}
                              <div>
                                <strong>{isPartner ? 'Partner' : 'Buyer'}</strong>
                              </div>
                              <div>{`${invoice.createdBy.firstName} ${invoice.createdBy.lastName}`}</div>
                              <div>{invoice.createdBy.email}</div>
                            </Col>
                            <Col xs={12} sm={3}>
                              <div>
                                <strong>
                                  {'Total Cost: '}
                                  {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                  }).format(invoice.cost)}
                                </strong>
                              </div>
                              <div>Total Pieces:{` ${invoice.pieces}`}</div>
                              <div>{invoice.payWithCheck ? 'Paid With Check' : 'Paid In Cash'}</div>
                              <div>{moment(invoice.dateAdded).format('llll')}</div>
                            </Col>
                            <Col
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                              xs={12}
                              sm={3}
                            >
                              <Link
                                to={
                                  user.isAdmin
                                    ? `/admin/invoices/${invoice._id}`
                                    : `/invoices/${invoice._id}`
                                }
                              >
                                <Button style={{ maxHeight: 38 }}>View Invoice</Button>
                              </Link>
                            </Col>
                          </Row>
                        </Card.Body>
                        <hr />
                      </div>
                    )
                  })}
                </Card>
              </Col>
            </Row>
          ) : (
            'No invoices found.'
          )}
        </>
      )}
    </Container>
  )
}

const mapState = (state: RootState) => {
  return {
    cart: state.cart.data,
    user: state.user,
    allUsers: state.admin.allUsers,
    invoices: state.invoices.allInvoices,
  }
}

const mapDispatch = (dispatch: AppDispatch) => {
  return {
    fetchAllInvoices() {
      dispatch(fetchAllInvoicesSingleUser())
    },
  }
}

export default connect(mapState, mapDispatch)(Invoices)
