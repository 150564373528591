import React from 'react'
import { Row, Col, Card, Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { PencilSquare } from 'react-bootstrap-icons'
import { addToCartThunk, subtractCart } from '../../store'
import { decimalToFraction } from '../util/utils'
import { ICartRequest } from '../../types/cart'

interface CartItemProps {
  item: any
  idx: number
  setEditItem: (item: any) => void
  setShowEditPriceModal: (value: boolean) => void
  cartSize: number
  addToCart: (body: ICartRequest) => void
  subtractCart: (body: ICartRequest) => void
  cartId: string
}
const CartItem = ({
  item,
  idx,
  setEditItem,
  setShowEditPriceModal,
  cartSize,
  addToCart,
  subtractCart,
  cartId,
}: CartItemProps) => {
  return (
    <div>
      <Card.Body>
        <Row style={{ display: 'flex', alignItems: 'center' }}>
          <Col xs="3">
            <div>{item.serialNumber}</div>
            <div>
              Size: <strong>{decimalToFraction(item.sizeModifier)}</strong>
            </div>
          </Col>
          <Col xs="3">
            <Row style={{ display: 'flex', alignItems: 'center' }}>
              <Col xs="2">{item.quantity}</Col>
              <Col
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
                xs="9"
                sm="5"
              >
                <Button
                  variant="success"
                  onClick={() =>
                    addToCart({
                      cartId,
                      converter: {
                        currentPrice: item.price,
                        _id: item._id,
                        serialNumber: item.serialNumber,
                      },
                      sizeModifier: item.sizeModifier,
                    })
                  }
                >
                  +
                </Button>
                <Button
                  variant="danger"
                  className="mt-3"
                  onClick={() => {
                    subtractCart({
                      cartId,
                      converter: {
                        currentPrice: item.price,
                        _id: item._id,
                        serialNumber: item.serialNumber,
                      },
                      sizeModifier: item.sizeModifier,
                    })
                  }}
                >
                  -
                </Button>
              </Col>
            </Row>
          </Col>
          <Col xs="3">
            {' '}
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(item.price)}
            <PencilSquare
              style={{ cursor: 'pointer', marginLeft: 10 }}
              onClick={() => {
                setEditItem(item)
                setShowEditPriceModal(true)
              }}
            />
          </Col>
          <Col
            xs="3"
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(item.quantity * item.price)}
          </Col>
        </Row>
      </Card.Body>
      {idx !== cartSize - 1 && <hr />}
    </div>
  )
}

const mapDispatch = (dispatch) => {
  return {
    addToCart(body) {
      dispatch(addToCartThunk(body))
    },
    subtractCart(body) {
      dispatch(subtractCart(body))
    },
  }
}

export default connect(null, mapDispatch)(CartItem)
