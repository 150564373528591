import React from 'react'
import { Form, FloatingLabel } from 'react-bootstrap'
import { FormikProps } from 'formik'
import { AddUserFormValues } from './AddUser'

interface SelectOption {
  value: string
  text: string
}

interface FormGroupProps {
  label: string
  name: keyof AddUserFormValues
  formik: FormikProps<AddUserFormValues>
  type?: string
  as?: 'input' | 'textarea'
  helpText?: string
  isSelect?: boolean
  selectOptions?: SelectOption[]
}

const FormGroup: React.FC<FormGroupProps> = ({
  label,
  name,
  formik,
  type = 'text',
  as = 'input',
  helpText = '',
  isSelect = false,
  selectOptions = [],
}) => {
  return (
    <Form.Group>
      <FloatingLabel label={label} className="mt-3">
        {isSelect ? (
          <Form.Control
            as="select"
            name={name}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values[name]}
            isValid={formik.touched[name] && !formik.errors[name]}
            isInvalid={formik.touched[name] && !!formik.errors[name]}
          >
            <option key="placeholder" value="">
              -
            </option>
            {selectOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.text}
              </option>
            ))}
          </Form.Control>
        ) : (
          <Form.Control
            as={as}
            type={type}
            name={name}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values[name]}
            placeholder={label}
            isValid={formik.touched[name] && !formik.errors[name]}
            isInvalid={formik.touched[name] && !!formik.errors[name]}
          />
        )}
        {formik.touched[name] && (
          <Form.Control.Feedback type="invalid">{formik.errors[name]}</Form.Control.Feedback>
        )}
      </FloatingLabel>
      <Form.Text muted>{helpText}</Form.Text>
    </Form.Group>
  )
}

export default FormGroup
