import React, { useEffect } from 'react'
import { Container, Row, Col, Alert, Form, Card, Button } from 'react-bootstrap'
import { createHedge, fetchHedge, deleteHedge, fetchAllHedges } from '../store'
import { useFormik } from 'formik'
import * as yup from 'yup' // for everything
import { connect } from 'react-redux'

const schema = yup.object({
  rhodium: yup.number().required('Required').typeError('Must be a number'),
  palladium: yup.number().required('Required').typeError('Must be a number'),
  platinum: yup.number().required('Required').typeError('Must be a number'),
})

const Hedging = ({ createHedge, hedge, deleteHedge, fetchAllHedges, allHedges }) => {
  const formik = useFormik({
    initialValues: {
      rhodium: !hedge ? '' : hedge.rhodium,
      platinum: !hedge ? '' : hedge.platinum,
      palladium: !hedge ? '' : hedge.palladium,
    },
    validationSchema: schema,
    onSubmit: (values) => {
      createHedge(values)
    },
  })

  useEffect(() => {
    fetchAllHedges()
  }, [])

  return (
    <Container fluid>
      {!hedge && (
        <Row className="mt-3">
          <Col>
            <Alert>
              You do not have an active hedge. Setting a hedge will calculate converter prices based
              on your metal hedges, not the live market prices.
            </Alert>
          </Col>
        </Row>
      )}
      <Card className="mt-3">
        <Card.Header>
          <h3>Hedging</h3>
        </Card.Header>
        <Card.Body>
          <Form noValidate onSubmit={formik.handleSubmit}>
            <Row>
              <Col xs={12} sm={4} style={{ margin: 0 }}>
                <Form.Label style={{ fontWeight: '600' }}>Set Platinum</Form.Label>
                <Form.Control
                  type="text"
                  name="platinum"
                  value={formik.values.platinum}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  isValid={formik.touched.platinum && !formik.errors.platinum}
                  isInvalid={
                    (formik.submitCount || formik.touched.platinum) && !!formik.errors.platinum
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.platinum}
                </Form.Control.Feedback>
              </Col>
              <Col xs={12} sm={4} style={{ margin: 0 }}>
                <Form.Label style={{ fontWeight: '600' }}>Set Palladium</Form.Label>
                <Form.Control
                  type="text"
                  name="palladium"
                  value={formik.values.palladium}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  isValid={formik.touched.palladium && !formik.errors.palladium}
                  isInvalid={
                    (formik.submitCount || formik.touched.palladium) && !!formik.errors.palladium
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.palladium}
                </Form.Control.Feedback>
              </Col>
              <Col xs={12} sm={4} style={{ margin: 0 }}>
                <Form.Label style={{ fontWeight: '600' }}>Set Rhodium</Form.Label>
                <Form.Control
                  type="text"
                  name="rhodium"
                  value={formik.values.rhodium}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  isValid={formik.touched.rhodium && !formik.errors.rhodium}
                  isInvalid={
                    (formik.submitCount || formik.touched.rhodium) && !!formik.errors.rhodium
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.rhodium}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Form>
          <Row className="mt-3">
            <Col xs={6} sm={4}>
              <Button onClick={() => formik.submitForm()} style={{ width: `100%` }}>
                {hedge ? 'Update Live Hedge' : 'Create Hedge'}
              </Button>
            </Col>
            <Col xs={6} sm={4} style={{ justifyContent: 'flex-end', display: 'flex' }}>
              {hedge && (
                <Button onClick={() => deleteHedge()} variant="danger" style={{ width: `100%` }}>
                  Delete Hedge
                </Button>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="mt-3">
        <Card.Header>
          <h3>All Hedges</h3>
        </Card.Header>
        <Card.Body>
          {!allHedges.length && (
            <Row>
              <Col>You have no previous hedges.</Col>
            </Row>
          )}

          {Boolean(allHedges.length) && (
            <>
              <Row>
                <Col>
                  <strong>Platinum</strong>
                </Col>
                <Col>
                  <strong>Palladium</strong>
                </Col>
                <Col>
                  <strong>Rhodium</strong>
                </Col>
                <Col>
                  <strong>Status</strong>
                </Col>
              </Row>
              <hr />
            </>
          )}

          {Boolean(allHedges.length) &&
            allHedges.map((hedge) => {
              return (
                <div key={hedge._id}>
                  <Row>
                    <Col>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(hedge.platinum)}
                    </Col>
                    <Col>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(hedge.palladium)}
                    </Col>
                    <Col>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(hedge.rhodium)}
                    </Col>
                    <Col>
                      {hedge.isActive && !hedge.isPaused ? (
                        <strong>Live</strong>
                      ) : hedge.isPaused ? (
                        'Paused'
                      ) : (
                        'Not Active'
                      )}
                    </Col>
                  </Row>
                  <hr />
                </div>
              )
            })}
        </Card.Body>
      </Card>
    </Container>
  )
}

const mapState = (state) => {
  return {
    prices: state.prices.prices,
    hedge: state.hedge.data,
    allHedges: state.hedge.allHedges,
  }
}

const mapDispatch = (dispatch) => {
  return {
    fetchHedge() {
      dispatch(fetchHedge())
    },
    fetchAllHedges() {
      dispatch(fetchAllHedges())
    },
    createHedge(hedge) {
      dispatch(createHedge(hedge))
    },
    deleteHedge() {
      dispatch(deleteHedge())
    },
  }
}

export default connect(mapState, mapDispatch)(Hedging)
