import axios from 'axios'
import history from '../history'
import { apiCaller } from '../utils/apiCaller'

/**
 * ACTION TYPES
 */

const GET_USER_LOGINS = 'GET_USER_LOGINS'
const GET_USER_CONVERTERS = 'GET_USER_CONVERTERS'
const GET_USER_ERRORS = 'GET_USER_ERRORS'
const GET_USER_ADMINS = 'GET_USER_ADMINS'
const REMOVE_USER_LOGS = 'REMOVE_USER_LOGS'

//for analytics

const GET_ALL_ERRORS = 'GET_ALL_ERRORS'
const GET_ALL_CONVERTER_LOGS = 'GET_ALL_CONVERTER_LOGS'
const GET_ALL_SIGN_UPS = 'GET_ALL_SIGN_UPS'
const GET_ALL_AUTH = 'GET_ALL_AUTH'
const GET_ALL_ADMIN = 'GET_ALL_ADMIN'

// v2
const GET_ALL_LOGS = 'GET_ALL_LOGS'
const GET_ALL_USER_LOGS = 'GET_ALL_USER_LOGS'

/**
 * INITIAL STATE
 */
const defaultState = {
  //v2
  allUserLogs: [],
  allLogs: [],
}

/**
 * ACTION CREATORS
 */

const getAllUserLogs = (allUserLogs) => ({ type: GET_ALL_USER_LOGS, allUserLogs })
const getAllLogs = (allLogs) => ({ type: GET_ALL_LOGS, allLogs })

/**
 * THUNK CREATORS
 */

export const fetchAllUserLogs = (userId) => async (dispatch) => {
  try {
    let res = await apiCaller(`api/logs/${userId}`)
    dispatch(getAllUserLogs(res.data.logs))
  } catch (err) {}
}

export const fetchAllLogs = (id) => async (dispatch) => {
  try {
    let res = await apiCaller(`api/logs/admin/all`)
    dispatch(getAllLogs(res.data.logs))
  } catch (err) {}
}

//for analytics

/**
 * REDUCER
 */
export default function (state = defaultState, action) {
  switch (action.type) {
    case GET_ALL_USER_LOGS:
      return { ...state, allUserLogs: action.allUserLogs }
    case GET_ALL_LOGS:
      return { ...state, allLogs: action.allLogs }
    default:
      return state
  }
}
