import * as yup from 'yup'
import { buyerRoles } from '../../../types/user'
import { Form } from 'react-bootstrap'
import React from 'react'
import { differenceInYears } from 'date-fns'

const isProd = process.env.NODE_ENV === 'production'

export const states = [
  { text: 'Alabama', value: 'Alabama' },
  { text: 'Alaska', value: 'Alaska' },
  { text: 'American Samoa', value: 'American Samoa' },
  { text: 'Arizona', value: 'Arizona' },
  { text: 'Arkansas', value: 'Arkansas' },
  { text: 'California', value: 'California' },
  { text: 'Colorado', value: 'Colorado' },
  { text: 'Connecticut', value: 'Connecticut' },
  { text: 'Delaware', value: 'Delaware' },
  { text: 'District Of Columbia', value: 'District Of Columbia' },
  { text: 'Federated States Of Micronesia', value: 'Federated States Of Micronesia' },
  { text: 'Florida', value: 'Florida' },
  { text: 'Georgia', value: 'Georgia' },
  { text: 'Guam', value: 'Guam' },
  { text: 'Hawaii', value: 'Hawaii' },
  { text: 'Idaho', value: 'Idaho' },
  { text: 'Illinois', value: 'Illinois' },
  { text: 'Indiana', value: 'Indiana' },
  { text: 'Iowa', value: 'Iowa' },
  { text: 'Kansas', value: 'Kansas' },
  { text: 'Kentucky', value: 'Kentucky' },
  { text: 'Louisiana', value: 'Louisiana' },
  { text: 'Maine', value: 'Maine' },
  { text: 'Marshall Islands', value: 'Marshall Islands' },
  { text: 'Maryland', value: 'Maryland' },
  { text: 'Massachusetts', value: 'Massachusetts' },
  { text: 'Michigan', value: 'Michigan' },
  { text: 'Minnesota', value: 'Minnesota' },
  { text: 'Mississippi', value: 'Mississippi' },
  { text: 'Missouri', value: 'Missouri' },
  { text: 'Montana', value: 'Montana' },
  { text: 'Nebraska', value: 'Nebraska' },
  { text: 'Nevada', value: 'Nevada' },
  { text: 'New Hampshire', value: 'New Hampshire' },
  { text: 'New Jersey', value: 'New Jersey' },
  { text: 'New Mexico', value: 'New Mexico' },
  { text: 'New York', value: 'New York' },
  { text: 'North Carolina', value: 'North Carolina' },
  { text: 'North Dakota', value: 'North Dakota' },
  { text: 'Northern Mariana Islands', value: 'Northern Mariana Islands' },
  { text: 'Ohio', value: 'Ohio' },
  { text: 'Oklahoma', value: 'Oklahoma' },
  { text: 'Oregon', value: 'Oregon' },
  { text: 'Palau', value: 'Palau' },
  { text: 'Pennsylvania', value: 'Pennsylvania' },
  { text: 'Puerto Rico', value: 'Puerto Rico' },
  { text: 'Rhode Island', value: 'Rhode Island' },
  { text: 'South Carolina', value: 'South Carolina' },
  { text: 'South Dakota', value: 'South Dakota' },
  { text: 'Tennessee', value: 'Tennessee' },
  { text: 'Texas', value: 'Texas' },
  { text: 'Utah', value: 'Utah' },
  { text: 'Vermont', value: 'Vermont' },
  { text: 'Virgin Islands', value: 'Virgin Islands' },
  { text: 'Virginia', value: 'Virginia' },
  { text: 'Washington', value: 'Washington' },
  { text: 'West Virginia', value: 'West Virginia' },
  { text: 'Wisconsin', value: 'Wisconsin' },
  { text: 'Wyoming', value: 'Wyoming' },
]

export const licenseSchema = yup.object().shape({
  licenseNumber: yup.string().required('License number is required'),
  licenseExpirationDate: yup
    .date()
    .min(new Date(), 'Expiration date must be in the future')
    .required('Expiration date is required'),
  licenseIssueDate: yup
    .date()
    .max(new Date(), 'Issue date must be in the past')
    .required('Issue date is required'),
  dob: yup
    .date()
    .max(new Date(), 'Date of Birth must be in the past')
    .test('age', 'You must be at least 18 years olgc md', function (value) {
      if (!value) return false
      return isProd ? differenceInYears(new Date(), new Date(value)) >= 18 : true
    })
    .required('Date of Birth is required'),
  licenseState: yup.string().required('License state is required'),
  heightFeet: yup.string().required('Height feet is required'),
  heightInches: yup.string().required('Height inches is required'),
  eyeColor: yup.string().required('Eye color is required'),
  gender: yup.string().required('Gender is required'),
})

// Define the validation schema using yup
export const editUserSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup.string().email('Invalid email address').required('Email is required'),
  margin: yup
    .number()
    .required('Margin is required')
    .min(1, 'Minimum at least 1')
    .max(99, 'Allowed maximum is 99'),
  location: yup.string().required('Location required'),
  permissionLevel: yup.string().required('Permission level required'),
  streetAddress: yup
    .string()
    .required('Address is required')
    .test('streetAddress', 'Sorry, you must have a physical address, not a PO Box', (value) => {
      if (!value) return false
      const results = value.match(
        /(((p[\s.]?[o\s][.]?)\s?)|(post\s?office\s?))((box|bin|b\.?)?\s?(num|number|#)?\s?\d+)/i
      )
      return !(results && results.length)
    }),
  streetAddress2: yup
    .string()
    .nullable()
    .test('streetAddress', 'Sorry, you must have a physical address, not a PO Box', (value) => {
      if (!value) return true
      const results = value.match(
        /(((p[\s.]?[o\s][.]?)\s?)|(post\s?office\s?))((box|bin|b\.?)?\s?(num|number|#)?\s?\d+)/i
      )
      return !(results && results.length)
    }),
  city: yup.string().required('City is required'),
  state: yup
    .string()
    .required('State is required')
    .test('state', 'Must be a valid state', (value) => {
      if (!value) return true
      return value !== '-'
    }),
  postalCode: yup.string().required('Postal code is required'),
  // fingerprint: yup
  //   .mixed()
  //   .when(['location', 'permissionLevel'], {
  //     is: (location: string, permissionLevel: string) =>
  //       location === 'florida' && !buyerRoles.includes(permissionLevel),
  //     then: yup
  //       .string()
  //       .required('Fingerprint upload is required for Florida and specific permission levels'),
  //     otherwise: yup.string().notRequired(),
  //   })
  //   .test('FILE_SIZE', 'Uploaded file is too big.', (value) => {
  //     const base64 = value?.split(',')[1] || value
  //     if (!base64 || base64.startsWith('http')) return true
  //     return atob(base64).length <= 30000000
  //   })
  //   .test('FILE_FORMAT', 'Uploaded file has unsupported format.', (value) => {
  //     if (!value || value.startsWith('http')) return true

  //     const mimeType = value.match(/^data:(.*?);base64,/)?.[1]

  //     return ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'].includes(mimeType)
  //   }),
  // License-related fields with conditional validation
  licensePhoto: yup.string().when('permissionLevel', {
    is: 'customer',
    then: yup.string().required('License photo is required'),
    otherwise: yup.string().notRequired(),
  }),
  // licenseNumber: yup.string().when('permissionLevel', {
  //   is: 'customer',
  //   then: yup.string().required('License number is required'),
  //   otherwise: yup.string().notRequired(),
  // }),
  // licenseExpirationDate: yup.date().when('permissionLevel', {
  //   is: 'customer',
  //   then: yup
  //     .date()
  //     .min(new Date(), 'Expiration date must be in the future')
  //     .required('Expiration date is required'),
  //   otherwise: yup.date().notRequired(),
  // }),
  // licenseIssueDate: yup.date().when('permissionLevel', {
  //   is: 'customer',
  //   then: yup
  //     .date()
  //     .max(new Date(), 'Issue date must be in the past')
  //     .required('Issue date is required'),
  //   otherwise: yup.date().notRequired(),
  // }),
  // dob: yup.date().when('permissionLevel', {
  //   is: 'customer',
  //   then: yup
  //     .date()
  //     .max(new Date(), 'Date of Birth must be in the past')
  //     .test('age', 'You must be at least 18 years old', function (value) {
  //       if (!value) return false
  //       return process.env.NODE_ENV === 'production'
  //         ? differenceInYears(new Date(), new Date(value)) >= 18
  //         : true
  //     })
  //     .required('Date of Birth is required'),
  //   otherwise: yup.date().notRequired(),
  // }),
  // licenseState: yup.string().when('permissionLevel', {
  //   is: 'customer',
  //   then: yup.string().required('License state is required'),
  //   otherwise: yup.string().notRequired(),
  // }),
  // heightFeet: yup.string().when('permissionLevel', {
  //   is: 'customer',
  //   then: yup.string().required('Height (feet) is required'),
  //   otherwise: yup.string().notRequired(),
  // }),
  // heightInches: yup.string().when('permissionLevel', {
  //   is: 'customer',
  //   then: yup.string().required('Height (inches) is required'),
  //   otherwise: yup.string().notRequired(),
  // }),
  // eyeColor: yup.string().when('permissionLevel', {
  //   is: 'customer',
  //   then: yup.string().required('Eye color is required'),
  //   otherwise: yup.string().notRequired(),
  // }),
  // gender: yup.string().when('permissionLevel', {
  //   is: 'customer',
  //   then: yup.string().required('Gender is required'),
  //   otherwise: yup.string().notRequired(),
  // }),
})

export const passwordSchema = yup.object({
  password: yup
    .string()
    .required('Please enter a password')
    .matches(
      /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,32}/,
      'Password must contain at least 8 characters, one uppercase and one number '
    ),
})

export const newUserSchema = passwordSchema.concat(editUserSchema)

export interface EditUserBodyProps {
  firstName?: string
  lastName?: string
  email?: string
  margin?: number
  location?: string
  isBuyer?: boolean
  isAdmin?: boolean
  isGuest?: boolean
  isCustomer?: boolean
  isPartner?: boolean
  streetAddress?: string
  streetAddress2?: string
  city?: string
  state?: string
  postalCode?: string
  userId?: string
  userPermission?: boolean
  fingerprint?: string
  floridaForm?: string
  password?: string
  licenseDetails?: {
    licenseNumber?: string
    licenseExpirationDate?: string
    licenseIssueDate?: string
    licenseState?: string
    gender?: string
    dob?: string
    height?: string
    eyeColor?: string
    licensePhoto?: string
  }
}

export type AddUserBodyProps = EditUserBodyProps

export const HeightForm = ({ formik }) => {
  const feetOptions = [...Array(8).keys()].slice(1) // Feet options: 1 to 7
  const inchOptions = [...Array(12).keys()] // Inch options: 0 to 11

  return (
    <Form.Group>
      <div style={{ display: 'flex', gap: '10px' }}>
        <Form.Select
          name="heightFeet"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.heightFeet}
          isValid={formik.touched.heightFeet && !formik.errors.heightFeet}
        >
          <option value="">Feet</option>
          {feetOptions.map((ft) => (
            <option key={ft} value={ft}>
              {ft} ft
            </option>
          ))}
        </Form.Select>

        <Form.Select
          name="heightInches"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.heightInches}
          isValid={formik.touched.heightInches && !formik.errors.heightInches}
        >
          <option value="">Inches</option>
          {inchOptions.map((inch) => (
            <option key={inch} value={inch}>
              {inch} in
            </option>
          ))}
        </Form.Select>
      </div>
      {(formik.touched.heightFeet || formik.touched.heightInches) && (
        <Form.Control.Feedback type="invalid">
          {formik.errors.heightFeet || formik.errors.heightInches}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  )
}

export const formikLicenseTouched = (formik: any) =>
  (formik.touched.licenseNumber &&
    formik.values.licenseNumber !== formik.initialValues.licenseNumber) ||
  (formik.touched.licenseIssueDate &&
    formik.values.licenseIssueDate !== formik.initialValues.licenseIssueDate) ||
  (formik.touched.licenseExpirationDate &&
    formik.values.licenseExpirationDate !== formik.initialValues.licenseExpirationDate) ||
  (formik.touched.licenseState &&
    formik.values.licenseState !== formik.initialValues.licenseState) ||
  (formik.touched.eyeColor && formik.values.eyeColor !== formik.initialValues.eyeColor) ||
  (formik.touched.gender && formik.values.gender !== formik.initialValues.gender) ||
  (formik.touched.dob && formik.values.dob !== formik.initialValues.dob) ||
  (formik.touched.heightFeet && formik.values.heightFeet !== formik.initialValues.heightFeet) ||
  (formik.touched.heightInches && formik.values.heightInches !== formik.initialValues.heightInches)

export const formikLicenseErrors = (formik: any) =>
  formik.errors.licenseNumber ||
  formik.errors.licenseIssueDate ||
  formik.errors.licenseExpirationDate ||
  formik.errors.licenseState ||
  formik.errors.eyeColor ||
  formik.errors.gender ||
  formik.errors.dob ||
  formik.errors.heightFeet ||
  formik.errors.heightInches

export const handleFileUpload = (
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  event: React.ChangeEvent<HTMLInputElement>,
  type: 'licensePhoto' | 'fingerprint'
) => {
  const file = event.target.files?.[0]
  if (file) {
    const reader = new FileReader()
    reader.onloadend = () => {
      if (type === 'licensePhoto') {
        setFieldValue('licensePhoto', reader.result as string)
      } else if (type === 'fingerprint') {
        setFieldValue('fingerprint', reader.result as string)
      }
    }
    reader.readAsDataURL(file)
  }
}

export function extractId(url: string) {
  // /admin/edit-user-2
  const urlArr = url.split('/')
  const pathArr = urlArr[urlArr.length - 1].split('-')
  const id = pathArr[pathArr.length - 1]
  return id
}

export function validateEmail(email: string) {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export function convertToInteger(margin: string | number) {
  return (Number(margin) * 100).toFixed(0)
}

export function validateMargin(margin: string) {
  const marginNumber = Number(margin)
  if (marginNumber && marginNumber >= 1 && marginNumber < 99.49) {
    return true
  } else {
    return false
  }
}

export function validateName(name: string) {
  if (name.length === 0 || name.length > 20) {
    return false
  } else {
    return true
  }
}

export function capitalizeFirstLetter(name: string) {
  return name.charAt(0).toUpperCase()
}

export function validatePassword(password: string) {
  const mediumRegex = new RegExp('^(((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})))')

  const test = mediumRegex.test(password)
  return test
}
