import axios from 'axios'
import history from '../history'

/**
 * ACTION TYPES
 */
const GET_ALL_MAKES = 'GET_ALL_MAKES'

/**
 * INITIAL STATE
 */

const initialState = {
  allMakes: [
    {
      make: '',
    },
  ],
}

/**
 * ACTION CREATORS
 */
const getAllMakes = (allMakes) => ({
  type: GET_ALL_MAKES,
  allMakes,
})

/**
 * THUNK CREATORS
 */
export const fetchAllMakes = () => async (dispatch) => {
  try {
    let res = await axios.get('/api/makes/')
    let { makes } = res.data
    dispatch(getAllMakes(makes))
  } catch (err) {
    console.error(err)
  }
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_MAKES:
      return { ...state, allMakes: action.allMakes }
    default:
      return state
  }
}
