import React, { useState, useEffect } from 'react'
import { useFormik, FormikProps } from 'formik'
import { connect, ConnectedProps } from 'react-redux'
import {
  RootState,
  AppDispatch,
  updateUserThunk,
  fetchSelectedUserThunk,
  deleteSelectedUserThunk,
  reenableSelectedUserThunk,
  updatePasswordThunk,
} from '../../../store'
import { Container, Row, Col, Form, Card, Button } from 'react-bootstrap'
import { startCase } from 'lodash'
import { locations, permissions } from '../../../types/user'
import { editUserSchema, states, EditUserBodyProps, HeightForm, handleFileUpload } from './util'
import { ScaleLoader } from 'react-spinners'
import { IUser } from '../../../types/user'
import DeleteUserModal from './DeleteUserModal'
import ReenableUserModal from './ReenableUserModal'
import UpdatePasswordModal from './UpdatePasswordModal'
import sanitizeHtml from 'sanitize-html'
import { Link, useParams } from 'react-router-dom'

import FormGroup from './FormGroup'

interface EditUserProps {
  selectedUser: IUser
  newUserLoading: boolean
  isAdmin: boolean
  isBuyer: boolean
  isPartner: boolean
  isCustomer: boolean
  updateUser: (body: EditUserBodyProps) => void
  fetchSelectedUser: (body: { userId: string; userPermission: boolean }) => void
  deleteSelectedUser: (body: { userId: string; userPermission: boolean }) => void
  reenableSelectedUser: (body: { userId: string; userPermission: boolean }) => void
  updatePassword: (body: { userId: string; newPassword: string }) => void
}

export interface EditUserFormValues {
  firstName: string
  lastName: string
  email: string
  password: string
  margin: number
  platinumMargin?: number
  palladiumMargin?: number
  rhodiumMargin?: number
  permissionLevel: string
  streetAddress: string
  streetAddress2?: string
  city: string
  state: string
  postalCode: string
  location: string
  licensePhoto: string
  licenseNumber: string
  licenseExpirationDate: string
  licenseIssueDate: string
  licenseState: string
  gender: string
  dob: string
  height: string
  eyeColor: string
  fingerprint?: string
}

const EditUser: React.FC<PropsFromRedux> = ({
  selectedUser,
  newUserLoading,
  isAdmin,
  isBuyer,
  isPartner,
  updateUser,
  fetchSelectedUser,
  deleteSelectedUser,
  reenableSelectedUser,
  updatePassword,
}: EditUserProps) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showReeneableModal, setShowReenableModal] = useState(false)

  const showDeleteButton = isAdmin && selectedUser.killed === false
  const showReenableButton = isAdmin && selectedUser.killed === true

  const [showUpdatePasswordModal, setShowUpdatePasswordModal] = useState(false)
  const { id: selectedUserId } = useParams<{ id: string }>()

  useEffect(() => {
    const hasPermission = isAdmin || isBuyer || isPartner

    if (selectedUserId) {
      fetchSelectedUser({ userId: selectedUserId, userPermission: hasPermission })
    }
  }, [])

  const getInitialPermissionLevel = () => {
    if (selectedUser?.isAdmin) return 'admin'
    if (selectedUser?.isBuyer) return 'buyer'
    if (selectedUser?.isGuest) return 'guest'
    if (selectedUser?.isCustomer) return 'customer'
    if (selectedUser?.isPartner) return 'partner'
    return 'customer' //default to customer
  }

  const formik = useFormik({
    initialValues: {
      firstName: selectedUser?.firstName || '',
      lastName: selectedUser?.lastName || '',
      email: selectedUser?.email || '',
      password: 'A1placeholder',
      margin: selectedUser?.margin * 100 || '',
      location: selectedUser?.location || '',
      permissionLevel: getInitialPermissionLevel() || '',
      isBuyer: selectedUser?.isBuyer || false,
      isGuest: selectedUser?.isGuest || false,
      isAdmin: selectedUser?.isAdmin || false,
      isCustomer: selectedUser?.isCustomer || false,
      isPartner: selectedUser?.isPartner || false,
      streetAddress: selectedUser?.streetAddress || '',
      streetAddress2: selectedUser?.streetAddress2 || '',
      city: selectedUser?.city || '',
      state: selectedUser?.state || '',
      postalCode: selectedUser?.postalCode || '',
      fingerprint: selectedUser?.fingerprint || '',
      licenseNumber: selectedUser?.licenseDetails?.licenseNumber || '',
      licenseExpirationDate: selectedUser?.licenseDetails?.licenseExpirationDate || '',
      licenseIssueDate: selectedUser?.licenseDetails?.licenseIssueDate || '',
      licenseState: selectedUser?.licenseDetails?.licenseState || '',
      gender: selectedUser?.licenseDetails?.gender || '',
      dob: selectedUser?.licenseDetails?.dob || '',
      heightFeet: (selectedUser?.licenseDetails?.height?.match(/(\d+)'-(\d+)"/) || [])[1] || '',
      heightInches: (selectedUser?.licenseDetails?.height?.match(/(\d+)'-(\d+)"/) || [])[2] || '',
      eyeColor: selectedUser?.licenseDetails?.eyeColor || '',
      licensePhoto: selectedUser?.licenseDetails?.licensePhoto || '',
      platinumMargin: selectedUser?.platinumMargin * 100 || '',
      palladiumMargin: selectedUser?.palladiumMargin * 100 || '',
      rhodiumMargin: selectedUser?.rhodiumMargin * 100 || '',
    },
    validationSchema: editUserSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const { initialValues } = formik // Access initial values

      // Define the union type of all form field names
      type FormFields = keyof typeof formik.values

      // Updated isFieldChanged helper function
      const isFieldChanged = (field: FormFields) => values[field] !== initialValues[field]

      // Create the body object with only changed values
      const body: EditUserBodyProps = {
        userPermission: isAdmin || isBuyer || isPartner,
        userId: selectedUser._id,
        ...(isFieldChanged('firstName') && { firstName: startCase(values.firstName) }),
        ...(isFieldChanged('lastName') && { lastName: startCase(values.lastName) }),
        ...(isFieldChanged('email') && { email: sanitizeHtml(values.email.trim().toLowerCase()) }),
        ...(isFieldChanged('password') && { password: values.password }),
        ...(isFieldChanged('margin') && { margin: Number(values.margin) }),
        ...(isFieldChanged('location') && { location: values.location }),
        ...(isFieldChanged('fingerprint') && { fingerprint: values.fingerprint }),
        ...(isFieldChanged('permissionLevel') && {
          isBuyer: values.permissionLevel === 'buyer',
          isAdmin: values.permissionLevel === 'admin',
          isGuest: values.permissionLevel === 'guest',
          isCustomer: values.permissionLevel === 'customer',
          isPartner: values.permissionLevel === 'partner',
        }),
        ...(isFieldChanged('streetAddress') && {
          streetAddress: startCase(values.streetAddress.trim()),
        }),
        ...(isFieldChanged('streetAddress2') && {
          streetAddress2: startCase(values.streetAddress2.trim()),
        }),
        ...(isFieldChanged('city') && { city: startCase(values.city.trim()) }),
        ...(isFieldChanged('state') && { state: values.state }),
        ...(isFieldChanged('postalCode') && { postalCode: values.postalCode.trim() }),
        ...(isFieldChanged('fingerprint') && { fingerprint: values.fingerprint }),
        ...(isFieldChanged('platinumMargin') && { platinumMargin: values.platinumMargin }),
        ...(isFieldChanged('palladiumMargin') && { palladiumMargin: values.palladiumMargin }),
        ...(isFieldChanged('rhodiumMargin') && { rhodiumMargin: values.rhodiumMargin }),
        licenseDetails: {
          ...(isFieldChanged('licensePhoto') && { licensePhoto: values.licensePhoto }),
          ...(isFieldChanged('licenseNumber') && { licenseNumber: values.licenseNumber }),
          ...(isFieldChanged('licenseIssueDate') && {
            licenseIssueDate: values.licenseIssueDate,
          }),
          ...(isFieldChanged('licenseExpirationDate') && {
            licenseExpirationDate: values.licenseExpirationDate,
          }),
          ...(isFieldChanged('licenseState') && { licenseState: values.licenseState }),
          ...(isFieldChanged('gender') && { gender: values.gender }),
          ...(isFieldChanged('dob') && { dob: values.dob }),
          ...((isFieldChanged('heightFeet') || isFieldChanged('heightInches')) && {
            height: `${values.heightFeet}'-${values.heightInches}"`,
          }),
          ...(isFieldChanged('eyeColor') && { eyeColor: values.eyeColor }),
        },
      }

      updateUser(body)
    },
  })

  const renderContactInfoSection = ({ formik }: { formik: FormikProps<EditUserFormValues> }) => (
    <>
      <h4>Contact Info</h4>
      <Row>
        <Col xs={12} lg={6}>
          <FormGroup label="First name" name="firstName" formik={formik} />
          <FormGroup label="Last name" name="lastName" formik={formik} />
          <FormGroup label="City" name="city" formik={formik} />
        </Col>
        <Col xs={12} lg={6}>
          <FormGroup label="Street Address" name="streetAddress" formik={formik} />
          <FormGroup label="Street Address 2" name="streetAddress2" formik={formik} />
          <FormGroup label="State" name="state" formik={formik} isSelect selectOptions={states} />
          <FormGroup label="Postal Code" name="postalCode" formik={formik} />
        </Col>
      </Row>
    </>
  )

  const shouldShowMetalMargins = isAdmin || isPartner

  const renderAccountSettingsSection = ({
    formik,
  }: {
    formik: FormikProps<EditUserFormValues>
  }) => (
    <>
      <h4>Account Settings</h4>
      <Row>
        <Col xs={12} lg={6}>
          <FormGroup label="Email" name="email" formik={formik} />
          <FormGroup
            label="Password"
            name="password"
            type="password"
            formik={formik}
            helpText="Must be 8+ chars & contain 1 uppercase & 1 number"
          />
          {isAdmin && (
            <FormGroup
              label="Set Permission Level"
              name="permissionLevel"
              formik={formik}
              isSelect
              selectOptions={permissions}
            />
          )}
          {(isAdmin || isBuyer || isPartner) && (
            <FormGroup
              label="AC Location"
              name="location"
              formik={formik}
              isSelect
              selectOptions={locations}
            />
          )}

          {formik.values.location === 'florida' &&
            ['guest', 'customer'].includes(formik.values.permissionLevel) && (
              <Col
                style={{
                  marginTop: '12px',
                  border: '1px solid #ced4da',
                  borderRadius: '.25rem',
                  padding: '0.5rem .75rem',
                  flex: '1', // Ensures both columns are evenly spaced
                  marginRight: '8px', // Adds spacing between columns
                }}
              >
                <label
                  style={{ marginBottom: '4px', opacity: '.65', fontSize: '12px' }}
                  htmlFor="fingerprint"
                >
                  Upload Fingerprint:
                </label>
                <input
                  type="file"
                  id="fingerprint"
                  name="fingerprint"
                  onChange={(e) => handleFileUpload(formik.setFieldValue, e, 'fingerprint')}
                />
                <span style={{ fontSize: '12px', marginRight: '16px', marginTop: '12px' }}>
                  Existing fingerprint:
                </span>
                {formik.values.fingerprint && (
                  <div
                    className="mt-4"
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                  >
                    <img height="100" alt="fingerprint" src={String(formik.values.fingerprint)} />
                  </div>
                )}
                {formik.touched.fingerprint && (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.fingerprint}
                  </Form.Control.Feedback>
                )}
              </Col>
            )}
        </Col>
        <Col xs={12} lg={6}>
          <FormGroup
            label="Account margin"
            name="margin"
            formik={formik}
            helpText="Must be a number at least 1 and under 100"
          />
          {shouldShowMetalMargins && (
            <>
              <FormGroup
                label="Platinum margin"
                name="platinumMargin"
                formik={formik}
                helpText="Must be a number at least 1 and under 100"
              />
              <FormGroup
                label="Palladium margin"
                name="palladiumMargin"
                formik={formik}
                helpText="Must be a number at least 1 and under 100"
              />
              <FormGroup
                label="Rhodium margin"
                name="rhodiumMargin"
                formik={formik}
                helpText="Must be a number at least 1 and under 100"
              />
            </>
          )}
        </Col>
      </Row>
    </>
  )

  const renderDriversLicenseSection = ({ formik }: { formik: FormikProps<EditUserFormValues> }) => (
    <>
      <h4>Driver&apos;s License</h4>
      <Row>
        <Col xs={12} lg={3}>
          <FormGroup label="License Number" name="licenseNumber" formik={formik} />
          <FormGroup
            label="License State"
            name="licenseState"
            formik={formik}
            isSelect
            selectOptions={states}
          />
          <div className="mt-3">
            <HeightForm formik={formik} />
          </div>
          <FormGroup label="Eye Color" name="eyeColor" formik={formik} />
        </Col>
        <Col xs={12} lg={3}>
          <FormGroup label="Gender" name="gender" formik={formik} />
          <FormGroup label="Issue Date" name="licenseIssueDate" formik={formik} type="date" />
          <FormGroup
            label="Expiration Date"
            name="licenseExpirationDate"
            formik={formik}
            type="date"
          />
          <FormGroup label="Date of Birth" name="dob" formik={formik} type="date" />
        </Col>
        <Col xs={12} lg={6}>
          <Form.Group>
            <Col
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '12px',
                border: '1px solid #ced4da',
                borderRadius: '.25rem',
                padding: '0.5rem .75rem',
              }}
            >
              <div>
                <label
                  style={{ marginBottom: '4px', opacity: '.65', fontSize: '12px' }}
                  htmlFor="licensePhoto"
                >
                  Upload License:
                </label>
                <input
                  type="file"
                  name="licensePhoto"
                  id="licensePhoto"
                  accept="image/png, image/jpeg"
                  onChange={(e) => handleFileUpload(formik.setFieldValue, e, 'licensePhoto')}
                />
              </div>
              <div
                style={{
                  marginTop: '12px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <p
                  style={{
                    fontSize: '12px',
                  }}
                >
                  Existing license:
                </p>
              </div>
              <div>
                <div className="edit-img-container">
                  <img
                    className="edit-img"
                    alt="Existing license"
                    src={
                      formik.values.licensePhoto
                        ? String(formik.values.licensePhoto)
                        : '/placeholder.png'
                    }
                  />
                </div>
              </div>
              {formik.touched.licensePhoto && (
                <Form.Control.Feedback
                  type="invalid"
                  style={{
                    textAlign: 'center',
                  }}
                >
                  {formik.errors.licensePhoto}
                </Form.Control.Feedback>
              )}
            </Col>
          </Form.Group>
        </Col>
      </Row>
    </>
  )

  if (newUserLoading) {
    return (
      <Row className="mt-3">
        <Col className="d-flex justify-content-center">
          <ScaleLoader color="#36D7B7" height={50} width={10} radius={4} margin={4} />
        </Col>
      </Row>
    )
  }

  return (
    <Container fluid>
      <Row>
        <Col>
          <Link to="/admin/view/users" className="ui button dashboard-add-converter-btn">
            Back To All Users
          </Link>
        </Col>
      </Row>
      <Card className="mt-3">
        <Card.Header>
          <h3>Edit User</h3>
        </Card.Header>
        <Card.Body>
          <Form noValidate onSubmit={formik.handleSubmit}>
            <Row>
              <Col xs={12} sm={6} className="mt-3">
                {renderContactInfoSection({ formik })}
              </Col>
              <Col xs={12} sm={6} className="mt-3">
                {renderAccountSettingsSection({ formik })}
              </Col>
              <Col xs={12} className="mt-3">
                {renderDriversLicenseSection({ formik })}
              </Col>
            </Row>
            <Row className="mt-5 d-flex justify-content-around">
              <Col xs={3}>
                <Button
                  onClick={() => {
                    formik.submitForm()
                  }}
                  style={{ width: `100%` }}
                >
                  Update User
                </Button>
              </Col>
              {showReenableButton && (
                <Col xs={3}>
                  <Button
                    variant="secondary"
                    onClick={() => setShowReenableModal(true)}
                    style={{ width: `100%` }}
                  >
                    Re-enable User
                  </Button>
                </Col>
              )}
              <Col xs={3}>
                <Button
                  variant="secondary"
                  onClick={() => setShowUpdatePasswordModal(true)}
                  style={{ width: `100%` }}
                >
                  Update Password
                </Button>
              </Col>
              {showDeleteButton && (
                <Col xs={3}>
                  <Button
                    variant="danger"
                    onClick={() => setShowDeleteModal(true)}
                    style={{ width: `100%` }}
                  >
                    Delete User
                  </Button>
                </Col>
              )}
            </Row>
          </Form>
          {showReeneableModal && (
            <ReenableUserModal
              showReeneableUserModal={showReeneableModal}
              reenableSelectedUser={reenableSelectedUser}
              selectedUser={selectedUser}
              setShowReenableModal={setShowReenableModal}
              userPermission={isAdmin || isBuyer}
            />
          )}
          {showDeleteModal && (
            <DeleteUserModal
              showDeleteUserModal={showDeleteModal}
              deleteSelectedUser={deleteSelectedUser}
              selectedUser={selectedUser}
              setShowDeleteModal={setShowDeleteModal}
              userPermission={isAdmin || isBuyer}
            />
          )}
          {showUpdatePasswordModal && (
            <UpdatePasswordModal
              showUpdatePasswordModal={showUpdatePasswordModal}
              updatePassword={updatePassword}
              selectedUser={selectedUser}
              setShowUpdatePasswordModal={setShowUpdatePasswordModal}
            />
          )}
        </Card.Body>
      </Card>
    </Container>
  )
}

const mapStateToProps = (state: RootState) => ({
  isAdmin: state.user.isAdmin,
  isBuyer: state.user.isBuyer,
  isPartner: state.user.isPartner,
  newUserLoading: state.admin.newUserLoading,
  selectedUser: state.admin.selectedUser,
})

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    updateUser: (body: EditUserBodyProps) => dispatch(updateUserThunk(body)),
    fetchSelectedUser: (body: { userId: string; userPermission: boolean }) =>
      dispatch(fetchSelectedUserThunk(body)),
    deleteSelectedUser: (body: { userId: string; userPermission: boolean }) =>
      dispatch(deleteSelectedUserThunk(body)),
    reenableSelectedUser: (body: { userId: string; userPermission: boolean }) =>
      dispatch(reenableSelectedUserThunk(body)),
    updatePassword: (body: { userId: string; newPassword: string }) =>
      dispatch(updatePasswordThunk(body)),
  }
}

// Merge the Redux props
const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(EditUser)
