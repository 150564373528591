import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Row, Col } from 'react-bootstrap'
import { ScaleLoader } from 'react-spinners'
import { filterFunction, createMakeOptions } from './util/utils'
import {
  fetchAllConverters,
  fetchSingleConverter,
  fetchAllMakes,
  fetchCart,
  RootState,
  AppDispatch,
  fetchAllInvoicesSingleUser,
} from '../store'
import ConverterSearch from './authorized-user/ConverterSearch'
import ConverterList from './authorized-user/ConverterList'
import AddOutsideConverterToCartModal from './AddOutsideConverterToCartModal'
import { IConverter } from '../types/converter'
import { ICart } from '../types/cart'
import { IMake } from '../types/make'

interface UserHomeProps {
  allConverters: IConverter[]
  allMakes: IMake[]
  cart: ICart
  refetchCart: () => void
  isAdmin: boolean
  isBuyer: boolean
  isPartner: boolean
  loadConverterData: () => void
  convertersLoading: boolean
  fetchAllInvoices: () => void
}

const UserHome = ({
  allConverters,
  allMakes,
  cart,
  refetchCart,
  isAdmin,
  isBuyer,
  isPartner,
  loadConverterData,
  convertersLoading,
  fetchAllInvoices,
}: UserHomeProps) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [converters, setConverters] = useState<IConverter[]>([])
  const [options, setOptions] = useState<any[]>([])
  const [searchPosition, setSearchPosition] = useState<string>('')
  const [showAddToCartModal, setShowAddToCartModal] = useState<boolean>(false)

  const enableCart = isAdmin || isBuyer || isPartner

  useEffect(() => {
    loadConverterData()
    if (enableCart) {
      fetchAllInvoices()
    }
    if (enableCart && !cart?._id) {
      refetchCart()
    }
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    const makeOptions = createMakeOptions(allMakes)
    setOptions(makeOptions)

    const converters = allConverters.filter((cat) => cat.make == 'Common Grade')
    setConverters(converters)
    if (converters.length) {
      setLoading(false)
    }
  }, [allConverters])

  const filterConverters = (value: string, make: string) => {
    setLoading(true)

    const copy = allConverters.slice()

    // if no value and no make, show all common grade converters
    // if (!value.length && !make) {
    if (!value.length && make === 'all converters') {
      const converters = copy.filter((cat) => cat.make === 'Common Grade')
      setConverters(converters)
      if (converters.length) {
        setLoading(false)
      }
      return
    }

    copy.sort((a: any, b: any) => a.id - b.id)
    if (value !== undefined) {
      value = value.toLowerCase()
    }
    let converters

    // if there is a make, filter by make and serial number
    if (make && make !== 'all converters') {
      make = make.toLowerCase()

      converters = copy
        .filter((converter) => {
          const serialNumber = String(converter.serialNumber).toLowerCase()
          const currentMake = String(converter.make).toLowerCase()
          if (currentMake === make && serialNumber.includes(value)) {
            return converter
          }
        })
        .sort((a: any, b: any) => a.id - b.id)
    } else {
      // if there is no make, filter by serial number
      converters = filterFunction(copy, value).sort((a: any, b: any) => a.id - b.id)
    }

    if (converters.length >= 0 && converters.length <= 39) {
      setConverters(converters)
    } else if (converters.length > 39 && converters.length === allConverters.length) {
      const shortened = converters.splice(0, 39)
      setConverters(shortened)
    } else if (converters.length > 39 && converters.length !== allConverters.length) {
      setConverters(converters.splice(0, 50))
    } else if (value.length === 0) {
      setConverters(copy.splice(0, 50))
    }

    setLoading(false)
  }

  const handleScroll = () => {
    if (window.pageYOffset >= 133 && searchPosition !== 'fixed') {
      setSearchPosition('fixed')
    }

    if (window.pageYOffset < 133) {
      setSearchPosition('')
    }
  }

  return (
    <div className="dashboard-container">
      <div className="width">
        <ConverterSearch
          filterConverters={filterConverters}
          options={options}
          position={searchPosition}
        />
      </div>
      {/**
       * ONLY ALLOW ADMINS to add pieces and see PPMs
       */}
      {isAdmin && !cart && (
        <div className="width">
          <Link to="/admin/add/converter" className="ui button dashboard-add-converter-btn orange">
            Add Converter
          </Link>
        </div>
      )}

      <Row style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <Col xs="9">
          {enableCart && cart && (
            <div className="width" style={{ width: '100%' }}>
              <Link to="/admin/cart" className="ui button dashboard-add-converter-btn green">
                View Cart
              </Link>
            </div>
          )}
        </Col>
        <Col xs="3">
          {enableCart && cart && (
            <div
              className="width"
              style={{
                width: '100%',
              }}
            >
              <Button
                className="ui button dashboard-add-converter-btn"
                style={{
                  background: '#f2711c',
                  borderColor: '#f2711c',
                  color: 'white',
                }}
                onClick={() => {
                  setShowAddToCartModal(true)
                }}
              >
                Add
              </Button>
            </div>
          )}
        </Col>
      </Row>
      {loading || convertersLoading ? (
        <Row className="mt-3">
          <Col className="d-flex justify-content-center">
            <ScaleLoader color="#36D7B7" height={50} width={10} radius={4} margin={4} />
          </Col>
        </Row>
      ) : (
        <ConverterList converters={converters} />
      )}
      {!converters.length && !loading && (
        <div className="mt-3">
          No Results. Please try a different search or contact us for more information.
        </div>
      )}

      <AddOutsideConverterToCartModal
        show={showAddToCartModal}
        toggle={() => setShowAddToCartModal(false)}
      />
    </div>
  )
}

/**
 * CONTAINER
 */
const mapState = (state: RootState) => {
  return {
    isAdmin: state.user.isAdmin,
    isBuyer: state.user.isBuyer,
    isPartner: state.user.isPartner,
    cart: state.cart.data,
    allConverters: state.converter.allConverters,
    allMakes: state.makes.allMakes,
    convertersLoading: state.converter.convertersLoading,
  }
}

const mapDispatch = (dispatch: AppDispatch) => {
  return {
    loadConverterData() {
      dispatch(fetchAllConverters({ priceList: false }))
      dispatch(fetchAllMakes())
    },
    fetchSingleConverter: (serialNumber: string) => dispatch(fetchSingleConverter(serialNumber)),
    refetchCart() {
      dispatch(fetchCart())
    },
    fetchAllInvoices() {
      dispatch(fetchAllInvoicesSingleUser())
    },
  }
}

export default connect(mapState, mapDispatch)(UserHome)
